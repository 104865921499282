import React from 'react'
import { Grid, Paper } from '@mui/material'
import UrlCount from './UrlCount'

export default function Dashboard() {
  return (
    <React.Fragment>
      <Grid container spacing={4}>
        {/* URL Count */}
        <Grid item xs={12} md={4} lg={5}>
          <Paper>
            <UrlCount />
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
