import { NetworkStatus, gql, useQuery } from '@apollo/client'
import CountCard from '@components/ui/CountCard'
import Title from '@components/ui/Title'
import TableUtils from '@lib/utils/table-utils'
import { getUrlPath } from '@lib/utils/url'
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import MaterialReactTable from 'material-react-table'
import React, { useEffect, useMemo, useRef } from 'react'
import { Pie } from 'react-chartjs-2'
import RedirectReportProfileGraph from './RedirectReportProfileGraph'

const theme = createTheme()

const GET_DATA = gql`
  query RedirUrls(
    $options: PaginationOptionsInput
    $where: RedirectsUrlsFilterInput
  ) {
    redirUrlsCount(where: $where) {
      urlCount
    }
    redirUrls(options: $options, where: $where) {
      redirected_address
      ref_count
      hops
      final_destination
      redirected_status_code
      final_status_code
      redirected_anchor
      references {
        address
        status_code
        anchor
      }
      redirect_chain {
        address
        status_code
      }
    }
    unfilteredTotal: redirUrlsCount(where: null) {
      urlCount
    }
    ended200Total: redirUrlsCount(where: { final_status_code: 200 }) {
      urlCount
    }
    ended404Total: redirUrlsCount(where: { final_status_code: 404 }) {
      urlCount
    }
  }
`

export default function RedirectReport() {
  const pieRef = useRef()
  const [pagination, setPagination] = React.useState<any>({
    pageSize: 10,
    pageIndex: 0,
  })
  const [columnFilters, setColumnFilters] = React.useState<any>([])
  const { loading, error, data, networkStatus, refetch } = useQuery(GET_DATA, {
    variables: {
      options: {
        limit: pagination?.pageSize || 10,
        offset: (pagination?.pageIndex || 0) * (pagination?.pageSize || 10),
      },
      where: TableUtils.convertFiltersToObj(columnFilters),
    },
    notifyOnNetworkStatusChange: true,
  })
  const [selectedAddress, setSelectedAddress] = React.useState('')

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    address: string,
  ) => {
    setSelectedAddress(address)
  }
  useEffect(() => {
    setPagination(pagination)
    setColumnFilters(columnFilters)
    refetch({
      options: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      where: TableUtils.convertFiltersToObj(columnFilters),
    })
  }, [pagination, columnFilters, refetch])

  useEffect(() => {
    if (!loading && data?.redirUrls.length > 0) {
      setSelectedAddress(data.redirUrls[0].redirected_address)
    }
  }, [loading, data])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'redirected_address',
        header: 'Redirect Address',
      },
      {
        accessorKey: 'redirected_status_code',
        header: 'Status Code',
        size: 10,
      },
      {
        accessorKey: 'hops',
        header: 'Chain Count',
        size: 10,
      },
      {
        accessorKey: 'final_destination',
        header: 'Final Destination',
        size: 100,
      },
      {
        accessorKey: 'final_status_code',
        header: 'Final Status Code',
        size: 10,
      },
      {
        accessorKey: 'ref_count',
        header: 'Reference Count',
      },
    ],
    [],
  )

  const subColumns = useMemo(
    () => [
      {
        accessorKey: 'address',
        header: 'Source Address',
        size: 100,
      },
      {
        accessorKey: 'status_code',
        header: 'Status Code',
        size: 10,
      },
    ],
    [],
  )
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Paper
          sx={{
            maxWidth: 1400,
            width: '100%',
            overflow: 'hidden',
            marginTop: 10,
            margin: 'auto',
            padding: 10,
          }}
        >
          <div className="flex flex-col gap-2">
            <Title>3xx Status Codes</Title>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <Box>
                  <CountCard
                    title="Total Redirects"
                    value={data?.unfilteredTotal.urlCount}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                  }}
                >
                  <CountCard
                    title="Total Redirects ended with 200"
                    value={data?.ended200Total.urlCount}
                  />
                  <CountCard
                    title="Total Redirects ended with 404"
                    value={data?.ended404Total.urlCount}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexBasis: 'content',
                }}
              >
                <Pie
                  ref={pieRef}
                  data={{
                    labels: ['Redirects to 200', 'Redirects to 404'],
                    datasets: [
                      {
                        label: 'Ratio of redirects ended with 200 and 404',
                        data: [
                          data?.ended200Total.urlCount || 0,
                          data?.ended404Total.urlCount || 0,
                        ],
                        backgroundColor: [
                          'rgba(54, 162, 235, 0.4)',
                          'rgba(255, 99, 132, 0.4)',
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                maxWidth: 1400,
                width: '100%',
                overflow: 'hidden',
                margin: 'auto',
                padding: 2,
              }}
            >
              <MaterialReactTable
                columns={columns}
                data={data?.redirUrls || []}
                enableExpandAll={false}
                enableStickyHeader
                initialState={{
                  showColumnFilters: false,
                  pagination: { pageSize: 10, pageIndex: 0 },
                  density: 'compact',
                }}
                manualPagination
                manualFiltering
                muiTablePaginationProps={{
                  rowsPerPageOptions: [],
                  showFirstButton: true,
                  showLastButton: true,
                }}
                muiToolbarAlertBannerProps={
                  error
                    ? {
                        color: 'error',
                        children: 'Error loading data',
                      }
                    : undefined
                }
                onPaginationChange={setPagination}
                onColumnFiltersChange={setColumnFilters}
                rowCount={data?.redirUrlsCount.urlCount || 0}
                state={{
                  isLoading: loading,
                  pagination,
                  columnFilters,
                  showAlertBanner: !!error,
                  showProgressBars: networkStatus === NetworkStatus.refetch,
                }}
                renderDetailPanel={({ row }) => {
                  const n = row.index
                  return (
                    <Box>
                      <MaterialReactTable
                        columns={subColumns}
                        data={data?.redirUrls[row.index].references || []}
                        enableColumnOrdering
                        enableEditing
                        initialState={{
                          density: 'compact',
                        }}
                        renderRowActions={({ row }) => (
                          <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <a
                              href={`${
                                data?.redirUrls[n].references[row.index].address
                              }#:~:text=${encodeURIComponent(
                                data?.redirUrls[n].references[row.index].anchor,
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                marginLeft: '0.5em',
                                marginRight: '0.5em',
                              }}
                            >
                              <OpenInNewIcon fontSize="small" />
                            </a>
                          </Box>
                        )}
                      />
                    </Box>
                  )
                }}
              />
            </Box>
            <div className="border-b-2 border-gray-300 py-4">
              <h3>Node Visualisation</h3>
              <div className="grid grid-cols-1 md:grid-cols-3">
                <div
                  className="overflow-y-scroll"
                  style={{ maxHeight: '800px' }}
                >
                  <List className="py-0" style={{ padding: 0 }}>
                    {data?.redirUrls.map((a: any) => {
                      const icon =
                        selectedAddress === a.redirected_address ? (
                          <RadioButtonChecked />
                        ) : (
                          <RadioButtonUnchecked />
                        )
                      return (
                        <ListItem
                          key={a.redirected_address}
                          className="py-1"
                          button
                          selected={selectedAddress === a.redirected_address}
                          onClick={(event) =>
                            handleListItemClick(event, a.redirected_address)
                          }
                        >
                          <ListItemIcon>{icon}</ListItemIcon>
                          <ListItemText
                            primary={getUrlPath(
                              a.redirected_address,
                            ).toString()}
                          />
                        </ListItem>
                      )
                    })}
                  </List>
                </div>
                <div className="md:col-span-2" style={{ maxHeight: '800px' }}>
                  {!loading && (
                    <RedirectReportProfileGraph address={selectedAddress} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    </ThemeProvider>
  )
}
