import { NetworkStatus, gql, useQuery } from '@apollo/client'
import { getUrlPath } from '@lib/utils/url'
import { Box, Typography } from '@mui/material'
import MaterialReactTable from 'material-react-table'
import { useEffect, useMemo, useState } from 'react'
import { GscBySubTopics, GscInfo } from '../../../../../common/api-types'
import GSCByPageGraph from './GSCByPageGraph'
import { sub } from 'date-fns'
import Loading from '@components/ui/Loading'
import ErrorMessage from '@components/ui/ErrorMessage'

interface GSCByPageProps {
  old_start_date: string
  old_end_date: string
  new_start_date: string
  new_end_date: string
  sub_topic: string[]
  main_topic: string[]
  filterValue: string
  toggleValue: {
    redir: boolean
    reco: boolean
  }
}

type RowData = {
  address: string
  content: string
  status_code: number
  old_total_clicks: number
  new_total_clicks: number
  old_total_impressions: number
  new_total_impressions: number
  clicks_difference: number
  impressions_difference: number
  old_queries: GscInfo[]
  new_queries: GscInfo[]
}

const GET_DATA = gql`
  query GscBySubTopics($where: CategoryInput) {
    gscBySubTopics(where: $where) {
      address
      content
      status_code
      queries {
        clicks
        ctr
        end_date
        impressions
        position
        query
        start_date
      }
    }
    gscBySubTopicsCount(where: $where)
  }
`

const GSCByPage = ({
  old_start_date,
  old_end_date,
  new_start_date,
  new_end_date,
  sub_topic,
  main_topic,
  filterValue,
  toggleValue,
}: GSCByPageProps) => {
  const [pagination, setPagination] = useState<any>({
    pageSize: 10,
    pageIndex: 0,
  })
  const { loading, data, error, networkStatus, refetch } = useQuery(GET_DATA, {
    variables: {
      options: {
        limit: pagination?.pageSize || 10,
        offset: (pagination?.pageIndex || 0) * (pagination?.pageSize || 10),
      },
      where: {
        sub_topic: sub_topic,
        old_start_date: old_start_date,
        old_end_date: old_end_date,
        new_start_date: new_start_date,
        new_end_date: new_end_date,
        main_topic: main_topic,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    setPagination(pagination)
    refetch({
      options: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
    })
  }, [pagination, refetch])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'address',
        header: 'Page / URL',
        Cell: ({ row }: any) => {
          const url = getUrlPath(row.original.address)
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <a
                href={row.original.address}
                target="_blank"
                rel="noopener noreferrer"
              >
                {url}
              </a>
            </Box>
          )
        },
      },
      {
        accessorKey: 'clicks_difference',
        header: 'Click Difference',
        Cell: ({ row }: any) => {
          const change = row.original.clicks_difference
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {change > 0 ? (
                <Typography sx={{ color: 'green' }}>{`+${change}`}</Typography>
              ) : change < 0 ? (
                <Typography sx={{ color: 'red' }}>{change}</Typography>
              ) : (
                <Typography>{change}</Typography>
              )}
            </Box>
          )
        },
      },
      {
        accessorKey: 'impressions_difference',
        header: 'Impressions',
        Cell: ({ row }: any) => {
          const change = row.original.impressions_difference
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {change > 0 ? (
                <Typography sx={{ color: 'green' }}>{`+${change}`}</Typography>
              ) : change < 0 ? (
                <Typography sx={{ color: 'red' }}>{change}</Typography>
              ) : (
                <Typography>{change}</Typography>
              )}
            </Box>
          )
        },
      },
    ],
    [],
  )

  const subColumns = useMemo(
    () => [
      {
        id: 'old_query',
        header: `(${old_start_date} - ${old_end_date})`,
        columns: [
          {
            accessorKey: 'old_query',
            header: `Query`,
            size: 100,
          },
          {
            accessorKey: 'old_clicks',
            header: `Clicks`,
            size: 100,
          },
          {
            accessorKey: 'old_impressions',
            header: `Impressions`,
            size: 100,
          },
          {
            accessorKey: 'old_ctr',
            header: `CTR`,
            size: 100,
          },
          {
            accessorKey: 'old_position',
            header: `Position`,
            size: 100,
            Cell: ({ row }: any) => {
              const position = row.original.old_position.toFixed(2)
              return <>{position}</>
            },
          },
        ],
      },
      {
        id: 'new_query',
        header: `(${new_start_date} - ${new_end_date})`,
        columns: [
          {
            accessorKey: 'new_query',
            header: `Query`,
            size: 100,
          },
          {
            accessorKey: 'new_clicks',
            header: `Clicks`,
            size: 100,
          },
          {
            accessorKey: 'new_impressions',
            header: `Impressions`,
            size: 100,
          },
          {
            accessorKey: 'new_ctr',
            header: `CTR`,
            size: 100,
          },
          {
            accessorKey: 'new_position',
            header: `Position`,
            size: 100,
          },
        ],
      },
    ],
    [],
  )

  // if (loading) {
  //   return <Loading />
  // }
  // if (error) {
  //   return <ErrorMessage error={error.message} />
  // }

  let rows: any = []
  const queryData: any = []

  if (!loading && !error) {
    data.gscBySubTopics.map((group: GscBySubTopics, index: number) => {
      const existingIndex = rows.findIndex(
        (row: any) => row.address === group.address,
      )

      let rowData: RowData = {
        address: group.address,
        content: group.content || '',
        status_code: group.status_code || 0,
        old_total_clicks: 0,
        new_total_clicks: 0,
        old_total_impressions: 0,
        new_total_impressions: 0,
        clicks_difference: 0,
        impressions_difference: 0,
        old_queries: [],
        new_queries: [],
      }

      if (existingIndex > -1) {
        rowData = rows[existingIndex]
      }

      group.queries.forEach((query: GscInfo) => {
        if (query.start_date === old_start_date) {
          rowData.old_total_clicks += query.clicks
          rowData.old_total_impressions += query.impressions
          rowData.old_queries.push(query)
        } else if (query.start_date === new_start_date) {
          rowData.new_total_clicks += query.clicks
          rowData.new_total_impressions += query.impressions
          rowData.new_queries.push(query)
        }
      })

      rowData.clicks_difference =
        rowData.new_total_clicks - rowData.old_total_clicks
      rowData.impressions_difference =
        rowData.new_total_impressions - rowData.old_total_impressions

      if (existingIndex > -1) {
        rows[existingIndex] = rowData // Update existing row data
      } else {
        rows.push(rowData) // Add new row data
      }
    })

    rows.sort((a: any, b: any) => {
      return b.clicks_difference - a.clicks_difference
    })
  }

  if (rows.length > 0) {
    rows.forEach((row: RowData) => {
      const queries: any = []
      row.new_queries.forEach((new_query: GscInfo) => {
        const old_query = row.old_queries.find(
          (old_query: GscInfo) => old_query.query === new_query.query,
        )
        queries.push({
          new_query: new_query.query,
          old_query: old_query?.query || 'N/A',
          new_clicks: new_query.clicks,
          old_clicks: old_query?.clicks || 0,
          new_impressions: new_query.impressions,
          old_impressions: old_query?.impressions || 0,
          new_ctr: new_query.ctr,
          old_ctr: old_query?.ctr || 0,
          new_position: new_query.position,
          old_position: old_query?.position || 0,
        })
      })
      row.old_queries.forEach((old_query: GscInfo) => {
        const new_query = row.new_queries.find(
          (new_query: GscInfo) => new_query.query === old_query.query,
        )
        if (!new_query) {
          queries.push({
            new_query: 'N/A',
            old_query: old_query.query,
            new_clicks: 0,
            old_clicks: old_query.clicks,
            new_impressions: 0,
            old_impressions: old_query.impressions,
            new_ctr: 0,
            old_ctr: old_query.ctr,
            new_position: 0,
            old_position: old_query.position,
          })
        }
      })
      queries.sort((a: any, b: any) => {
        return b.new_clicks - a.new_clicks
      })
      queryData.push(queries)
    })
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          border: '1px solid #ccc',
          height: '600px',
        }}
      >
        <GSCByPageGraph
          sub_topic={sub_topic}
          urlData={rows}
          filterValue={filterValue}
          toggleValue={toggleValue}
          old_end_date={old_end_date}
          old_start_date={old_start_date}
          new_end_date={new_end_date}
          new_start_date={new_start_date}
        />
      </Box>

      <Box
        sx={{
          height: '600px',
          maxHeight: '600px',
          overflow: 'auto',
        }}
      >
        <MaterialReactTable
          columns={columns}
          data={rows || []}
          enableExpandAll={false}
          enableStickyHeader
          defaultColumn={{
            minSize: 20,
            maxSize: 300,
            size: 300,
          }}
          initialState={{
            // showColumnFilters: false,
            // pagination: { pageSize: 10, pageIndex: 0 },
            density: 'compact',
          }}
          // manualPagination
          // manualFiltering
          muiTablePaginationProps={{
            rowsPerPageOptions: [],
            showFirstButton: true,
            showLastButton: true,
          }}
          // onPaginationChange={setPagination}
          // rowCount={data?.gscBySubTopicsCount || 0}
          muiToolbarAlertBannerProps={
            error
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }
          state={{
            isLoading: loading,
            showAlertBanner: !!error,
            showProgressBars: networkStatus === NetworkStatus.refetch,
          }}
          renderDetailPanel={({ row }) => {
            const n = row.index
            return (
              <Box>
                <MaterialReactTable
                  columns={subColumns}
                  data={queryData[n] || []}
                  enableTopToolbar={false}
                  enableStickyHeader
                  initialState={{
                    density: 'compact',
                  }}
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [],
                    showFirstButton: true,
                    showLastButton: true,
                  }}
                />
              </Box>
            )
          }}
        />
      </Box>
    </>
  )
}
export default GSCByPage
