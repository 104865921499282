import markerSDK, { MarkerSdk } from '@marker.io/browser'
import { useEffect, useState } from 'react'

export default function useMarkerio() {
  const [widget, setWidget] = useState<MarkerSdk | null>(null)

  useEffect(() => {
    const project = process.env?.REACT_APP_MARKER_IO_PROJECT_ID
    if (project) {
      markerSDK
        .loadWidget({
          project
        })
        .then(setWidget)
    } else {
      setWidget(null)
      console.warn('Marker.io project ID not found')  
    }
  }, [])

  return widget
}
