import React, { useEffect, useMemo, useRef } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { NetworkStatus, gql, useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import Title from '@components/ui/Title'
import TableUtils from '@lib/utils/table-utils'
import CountCard from '@components/ui/CountCard'
import { Doughnut, getElementAtEvent } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { RedirectsHealthData } from '../../../../common/api-types'
import BarChart from './BarChart'
import URLInfoModal from './URLInfoModal'

ChartJS.register(ArcElement, Tooltip, Legend);

const theme = createTheme()

const GET_REDIRECTS_HEALTH = gql`
  query GetRedirectsHealth(
    $options: PaginationOptionsInput
    $where: RedirectsHealthFilterInput
  ) {
    redirectsHealthData(options: $options, where: $where) {
      final_destination
      final_status_code
      redirected_address
      redirected_status_code
      redirected_anchor
      redirected_link_path
      hops
      redirect_chains {
        address
        status_code
      }
      sources {
        address
        anchor
        link_path
      }
    }
  }
`

const GET_REDIRECTS_HEALTH_STATS = gql`
  query GetRedirectsHealthStats(
    $where: RedirectsHealthFilterInput
  ) {
    redirectsHealthDataStats(where: $where) {
      totalCount
      okCount
      healthyCount
      notFoundCount
      filteredTotalCount
      hopsMetrics
      loopingRedirectsCount
      tooManyRedirectsCount
    }
  }
`

function RedirectsHealth() {
  const doughnutRef = useRef<any>()

  const onDoughnutClick = (event: any) => {
    console.log(getElementAtEvent(doughnutRef.current, event));
    const element = getElementAtEvent(doughnutRef.current, event)
    const currentElementIndex = element[0].index

    if (currentElementIndex === 0) {
      setColumnFilters([{
        id: 'hops',
        value: 1,
      }, {
        id: 'final_status_code',
        value: 200,
      }])
    } else if (currentElementIndex === 1) {
      setColumnFilters([{
        id: 'final_status_code',
        value: 404,
      }])
    }
  }

  const [pagination, setPagination] = React.useState<any>({
    pageSize: 10,
    pageIndex: 0,
  })
  const [columnFilters, setColumnFilters] = React.useState<any>([])
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [modalData, setModalData] = React.useState<RedirectsHealthData | null>(null)

  const { loading, error, data, networkStatus, refetch } = useQuery(
    GET_REDIRECTS_HEALTH,
    {
      variables: {
        options: {
          limit: pagination?.pageSize || 10,
          offset: (pagination?.pageIndex || 0) * (pagination?.pageSize || 10),
        },
        where: TableUtils.convertFiltersToObj(columnFilters),
      },
      notifyOnNetworkStatusChange: true,
    },
  )

  const { data: statsData } = useQuery(
    GET_REDIRECTS_HEALTH_STATS,
    {
      variables: {
        where: TableUtils.convertFiltersToObj(columnFilters),
      },
    },
  )

  useEffect(() => {
    setPagination(pagination)
    setColumnFilters(columnFilters)
    refetch({
      options: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      where: TableUtils.convertFiltersToObj(columnFilters),
    })
  }, [pagination, columnFilters, refetch])

  const columns = useMemo<MRT_ColumnDef<RedirectsHealthData>[]>(
    () => [
      {
        accessorKey: 'redirected_address',
        header: 'Redirected Address',
      },
      {
        accessorKey: 'redirected_status_code',
        header: 'Redirected Status Code',
      },
      {
        accessorKey: 'final_destination',
        header: 'Final Destination',
      },
      {
        accessorKey: 'final_status_code',
        header: 'Final Status Code',
      },
      {
        accessorKey: 'hops',
        header: 'Hops',
      },
    ],
    [],
  )

  const handleRowClick = (row: RedirectsHealthData) => {
    setModalData(row)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setModalData(null)
  }

  const getRedirectsHealthPercentage = (): number[] => {
    const healthyCount = statsData?.redirectsHealthDataStats?.healthyCount || 0
    const totalCount = statsData?.redirectsHealthDataStats?.totalCount || 0

    const goodPercentage = Math.round((healthyCount / totalCount) * 100)
    const badPercentage = Math.round(100 - goodPercentage)

    return [
      goodPercentage,
      badPercentage,
    ]
  }

  const plugins = [{
    id: 'custom_doughnut_center_text',
    beforeDraw: function(chart: any) {
     var width = chart.width,
         height = chart.height,
         ctx = chart.ctx;
         ctx.restore();
         var fontSize = (height / 160).toFixed(2);
         ctx.font = "bold " + fontSize + "em sans-serif";
         ctx.textBaseline = "top";
         const value = chart.data.datasets[0].data[0]
         var text = value? value + "%" : "",
         textX = Math.round((width - ctx.measureText(text).width) / 2),
         textY = (height / 2) + 15;
         ctx.fillText(text, textX, textY);
         ctx.save();
    } 
  }]

  return (
    <ThemeProvider theme={theme}>
      <div className="flex flex-col gap-2">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Box>
              <CountCard
                title="Total Redirects"
                value={statsData?.redirectsHealthDataStats?.totalCount}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
              }}
            >
              <CountCard
                title="Total Redirects ended with 200"
                value={statsData?.redirectsHealthDataStats?.okCount}
              />
              <CountCard
                title="Total Redirects ended with 404"
                value={statsData?.redirectsHealthDataStats?.notFoundCount}
              />
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexBasis: 'content',
          }}>
            <Doughnut
              plugins={plugins}
              ref={doughnutRef}
              onClick={onDoughnutClick}
              data={{
                labels: ['Healthy Redirects', 'Problematic Redirects'],
                datasets: [
                  {
                    label: 'Redirects Health Percentage',
                    data: getRedirectsHealthPercentage(),
                    backgroundColor: [
                      'rgba(54, 162, 235, 0.7)',
                      'rgba(255, 99, 132, 0.7)',
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
          }}
        >
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 0.5,
          }}>
            <BarChart 
              title='Problematic Redirects Breakdown'
              labels={['Ended with 404','Too Many Redirects', 'Looping Redirects']}
              data={[
                statsData?.redirectsHealthDataStats?.notFoundCount,
                statsData?.redirectsHealthDataStats?.tooManyRedirectsCount,
                statsData?.redirectsHealthDataStats?.loopingRedirectsCount,
              ]}
              backgroundColor={[
                'rgba(255, 99, 132, 0.7)',
                'rgba(255, 159, 64, 0.7)',
                'rgba(255, 205, 86, 0.7)',
              ]}
              yAxisMax={statsData?.redirectsHealthDataStats?.totalCount || 0}
            />
          </Box>

          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 0.5,
          }}>
            <BarChart
              title='Reddirect Hops Breakdown'
              labels={['1 Hop', '2 Hops', '3 Hops', '4 Hops', '5+ Hops']}
              data={statsData?.redirectsHealthDataStats?.hopsMetrics || []}
              backgroundColor='rgba(54, 162, 235, 0.7)'
              yAxisMax={statsData?.redirectsHealthDataStats?.totalCount || 0}
            />
          </Box>
        </Box>
        <Box
          sx={{
            maxWidth: 1400,
            width: '100%',
            overflow: 'hidden',
            margin: 'auto',
            padding: 2,
          }}
        >
          <Title>Redirected URLs</Title>

          <MaterialReactTable
            columns={columns}
            data={data?.redirectsHealthData || []}
            enableGlobalFilter={false}
            enableStickyHeader
            enableRowNumbers
            rowNumberMode="static"
            initialState={{
              showColumnFilters: false,
              pagination: { pageSize: 10, pageIndex: 0 },
              density: 'compact',
            }}
            manualPagination
            manualFiltering
            muiTablePaginationProps={{
              rowsPerPageOptions: [],
              showFirstButton: true,
              showLastButton: true,
            }}
            muiToolbarAlertBannerProps={
              error
                ? {
                    color: 'error',
                    children: 'Error loading data',
                  }
                : undefined
            }
            muiTableBodyRowProps={({ row }) => ({
              onClick: (event) => {
                handleRowClick(row.original)
              },
              sx: {
                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
              },
            })}
            onPaginationChange={setPagination}
            onColumnFiltersChange={setColumnFilters}
            rowCount={statsData?.redirectsHealthDataStats?.filteredTotalCount || 0}
            state={{
              isLoading: loading,
              pagination,
              columnFilters,
              showAlertBanner: !!error,
              showProgressBars: networkStatus === NetworkStatus.refetch,
            }}
          />
          <URLInfoModal
            open={isModalOpen}
            onClose={handleCloseModal}
            data={modalData}
          />
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default RedirectsHealth
