import { gql, useQuery } from '@apollo/client'
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import { set, sub } from 'date-fns'
import { useEffect, useState } from 'react'

type Category = {
  main_topic: string[]
  sub_topic: string[]
}
interface CategoryFilterProps {
  old_start_date: string
  old_end_date: string
  new_start_date: string
  new_end_date: string
  setSelectedCat: (setSelectedCat: Category) => void
  selectedCat: Category
  selected_report: string
  setIsCatLoaded: (isCatLoading: boolean) => void
}

const GET_MAINCAT = gql`
  query MainCategories($where: CategoryInput) {
    mainCategories(where: $where) {
      main_topics
    }
  }
`
const GET_SUBCAT = gql`
  query SubCategories($where: CategoryInput) {
    subCategories(where: $where) {
      sub_topics
    }
  }
`

const CategoryFilter = ({
  old_start_date,
  old_end_date,
  new_start_date,
  new_end_date,
  setSelectedCat,
  selectedCat,
  selected_report,
  setIsCatLoaded,
}: CategoryFilterProps) => {
  const { loading, error, data } = useQuery(GET_MAINCAT, {
    variables: {
      where: {
        old_start_date,
        old_end_date,
        new_start_date,
        new_end_date,
      },
    },
  })
  const [selectedMainTopic, setSelectedMainTopic] = useState<string[]>([])

  useEffect(() => {
    setSelectedMainTopic([data?.mainCategories.main_topics[0]])
  }, [data, selected_report])

  const handleMainChange = (newSelectedMainTopic: string) => {
    const isAlreadySelected = selectedMainTopic.includes(newSelectedMainTopic)

    if (selected_report !== 'Pages') {
      if (isAlreadySelected && selectedMainTopic.length > 1) {
        setSelectedMainTopic((prevSelectedMainTopics) =>
          prevSelectedMainTopics.filter(
            (topic) => topic !== newSelectedMainTopic,
          ),
        )
      } else if (!isAlreadySelected) {
        setSelectedMainTopic((prevSelectedMainTopics) => [
          ...prevSelectedMainTopics,
          newSelectedMainTopic,
        ])
      }
      return
    }

    setSelectedMainTopic([newSelectedMainTopic])
    
  }

  const {
    loading: sub_loading,
    error: sub_error,
    data: sub_data,
  } = useQuery(GET_SUBCAT, {
    variables: {
      where: {
        old_start_date,
        old_end_date,
        new_start_date,
        new_end_date,
        main_topic: selectedMainTopic,
      },
    },
    skip: !data,
  })

  const [selectedSubTopics, setSelectedSubTopics] = useState<string[]>([])

  useEffect(() => {
    if (selectedMainTopic) {
      setSelectedCat({
        main_topic: selectedMainTopic,
        sub_topic: selectedSubTopics,
      })
    }
  }, [selectedMainTopic, selectedSubTopics])

  useEffect(() => {
    setSelectedSubTopics([sub_data?.subCategories.sub_topics[0]])
    if (data || (selectedMainTopic && selectedSubTopics)) {
      setIsCatLoaded(true)
    }
  }, [sub_data, data])

  

  const handleSubChange = (newSelectedSubTopic: string) => {
    const isAlreadySelected = selectedSubTopics.includes(newSelectedSubTopic)

    if (isAlreadySelected) {
      setSelectedSubTopics((prevSelectedSubTopics) =>
        prevSelectedSubTopics.filter((topic) => topic !== newSelectedSubTopic),
      )
    } else {
      setSelectedSubTopics((prevSelectedSubTopics) => [
        ...prevSelectedSubTopics,
        newSelectedSubTopic,
      ])
    }
  }

  if (data && sub_data) {
    setIsCatLoaded(true)
  }

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          '& > *': {
            pr: 2,
          },
        }}
      >
        <Typography variant="body1"> Main Category</Typography>
        {data?.mainCategories.main_topics && selected_report !== 'Pages' ? (
          <>
            {' '}
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setSelectedMainTopic(data?.mainCategories.main_topics)
              }}
              sx={{ mr: 2 }}
            >
              Select All
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setSelectedMainTopic([data?.mainCategories.main_topics[0]])
              }}
            >
              Clear
            </Button>
          </>
        ) : null}
      </Box>
      <Box
        sx={{
          minHeight: '20px',
          maxHeight: '100px',
          overflow: 'auto',
          m: 1,
        }}
      >
        {loading ? (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        ) : (
          <>
            {data?.mainCategories.main_topics.map(
              (topic: string, index: number) => {
                return (
                  <Button
                    key={index}
                    variant={
                      selectedMainTopic.includes(topic)
                        ? 'contained'
                        : 'outlined'
                    }
                    color="primary"
                    size="small"
                    onClick={() => handleMainChange(topic)}
                    style={{ marginRight: '8px' }}
                  >
                    {topic}
                  </Button>
                )
              },
            )}
          </>
        )}
      </Box>

      {selected_report === 'Pages' ? (
        <Box
          sx={{
            pt: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              '& > *': {
                pr: 2,
              },
            }}
          >
            <Typography variant="body1"> Sub Category</Typography>
            {selectedMainTopic && sub_data ? (
              <>
                {' '}
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    setSelectedSubTopics(sub_data?.subCategories.sub_topics)
                  }}
                  sx={{ mr: 2 }}
                >
                  Select All
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    setSelectedSubTopics([
                      sub_data?.subCategories.sub_topics[0],
                    ])
                  }}
                >
                  Clear
                </Button>
              </>
            ) : null}
          </Box>
          <Box
            sx={{
              minHeight: '20px',
              maxHeight: '100px',
              overflow: 'auto',
              m: 1,
            }}
          >
            {sub_loading ? (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            ) : (
              <>
                {selectedMainTopic && sub_data ? (
                  <>
                    {sub_data?.subCategories.sub_topics.map(
                      (topic: string, index: number) => {
                        return (
                          <Button
                            key={index}
                            variant={
                              selectedSubTopics.includes(topic)
                                ? 'contained'
                                : 'outlined'
                            }
                            color="primary"
                            size="small"
                            onClick={() => handleSubChange(topic)}
                            style={{ marginRight: '8px', marginBottom: 1 }}
                          >
                            {topic}
                          </Button>
                        )
                      },
                    )}
                  </>
                ) : null}
              </>
            )}
          </Box>
        </Box>
      ) : null}
    </div>
  )
}
export default CategoryFilter
