import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import FormGroup from '@mui/material/FormGroup'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import * as React from 'react'
import { colours } from '@pages/redirected-url-health/Colour'

const style: { [key: string]: React.CSSProperties } = {
  arrowKeys: {
    width: '100%',
    maxWidth: '100px',
    height: '20px',
    border: 'none',

    position: 'relative',
  },
  nodeKeys: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    marginTop: '-2px',
  },
  guideStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 1,
    marginLeft: 1,
  },
}

type ToggleValueType = {
  redir: boolean
  reco: boolean
}

interface GSCDashboardProps {
  selectedReport: string
  setSelectedReport: (selectedReport: string) => void
  filterValue: string
  setFilterValue: (filterValue: string) => void
  toggleValue: ToggleValueType
  setToggleValue: (toggleValue: ToggleValueType) => void
}

const GSCDashboard = ({
  selectedReport,
  setSelectedReport,
  filterValue,
  setFilterValue,
  toggleValue,
  setToggleValue,
}: GSCDashboardProps) => {
  const reports = ['Pages', 'Topics']
  const [selectedIndex, setSelectedIndex] = React.useState(
    reports.indexOf(selectedReport),
  )

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedReport(reports[index])
    setSelectedIndex(index)
  }
  const sizeOptions = ['Clicks', 'Impressions']

  const arrowKeys = [
    {
      text: 'Recommended Links',
      color: colours.yellow,
      style: 'dashed',
    },
    {
      text: 'Redirects',
      color: colours.charcoal_saturated,
      style: 'dashed',
    },
    {
      text: 'Links',
      color: colours.grey,
      style: 'solid',
    },
  ]

  const nodeKeys = [
    {
      text: 'Good',
      color: colours.dundee_green,
    },
    {
      text: 'Bad',
      color: colours.red,
    },
    {
      text: toggleValue.redir ? 'Page With Redirect' : 'Redirect URL',
      border: `2px dashed ${colours.charcoal_saturated}`,
    },
  ]

  const filteredNodeKeys =
    selectedReport !== 'Pages'
      ? nodeKeys.filter(
          (nodeKey) =>
            nodeKey.text !== 'Redirect URL' &&
            nodeKey.text !== 'Page With Redirect',
        )
      : nodeKeys
  const toggleKeys = [
    {
      label: 'Separate Old Redirects with URLs into Distinct Nodes',
      value: toggleValue.redir,
    },
    {
      label: 'Toggle Recommended Links (and hide redirects/regular links)',
      value: toggleValue.reco,
    },
  ]

  return (
    <>
      <List component="nav" aria-label="gsc report group">
        {reports.map((report, index) => (
          <ListItemButton
            key={index}
            selected={selectedIndex === index}
            onClick={(event) => handleListItemClick(event, index)}
          >
            <ListItemText primary={report} />
          </ListItemButton>
        ))}
      </List>
      <FormControl
        sx={{
          p: 2,
        }}
      >
        <FormLabel id="filter-group">Size Up</FormLabel>
        <RadioGroup
          aria-labelledby="filter-group"
          value={filterValue}
          onChange={(event) => {
            setFilterValue(event.target.value)
          }}
          name="filter-group"
          sx={{ pl: 2 }}
        >
          {sizeOptions.map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </FormControl>

      {selectedReport === 'Pages' && (
        <>
          <Box
            sx={{
              p: 2,
              '& > :not(style)': {
                mb: 1,
              },
            }}
          >
            {toggleKeys.map((toggleKey, index) => (
              <Box
                key={toggleKey.label}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Switch
                  key={toggleKey.label}
                  checked={toggleKey.value}
                  onChange={(event) => {
                    if (index === 0) {
                      setToggleValue({
                        ...toggleValue,
                        redir: event.target.checked,
                      })
                    } else {
                      setToggleValue({
                        ...toggleValue,
                        reco: event.target.checked,
                      })
                    }
                  }}
                />
                <Typography variant="body2">{toggleKey.label}</Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              p: 2,
              '& > :not(style)': {
                mr: 2,
              },
            }}
          >
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Arrow Keys
            </Typography>
            {arrowKeys.map((arrowKey) => (
              <Box key={arrowKey.text} sx={style.guideStyle}>
                <Typography variant="body2">{arrowKey.text}</Typography>
                <Box
                  sx={{
                    ...style.arrowKeys,
                    borderBottom: `7px ${arrowKey.style} ${arrowKey.color}`,
                    mt: '-6px',
                  }}
                ></Box>
              </Box>
            ))}
          </Box>
        </>
      )}
      <Box
        sx={{
          p: 2,
          '& > :not(style)': {
            mr: 1,
          },
        }}
      >
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Node Keys
        </Typography>

        {filteredNodeKeys.map((nodeKey) => (
          <Box key={nodeKey.text} sx={style.guideStyle}>
            <Typography variant="body2">{nodeKey.text}</Typography>
            <Box
              sx={{
                ...style.nodeKeys,
                backgroundColor: nodeKey.color,
                border: nodeKey.border,
              }}
            ></Box>
          </Box>
        ))}
      </Box>
    </>
  )
}

export default GSCDashboard
