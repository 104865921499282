import { NetworkStatus, gql, useQuery } from '@apollo/client'
import ErrorMessage from '@components/ui/ErrorMessage'
import Loading from '@components/ui/Loading'
import { getUrlPath } from '@lib/utils/url'
import { Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { MaterialReactTable } from 'material-react-table'
import React, { useMemo, useState } from 'react'
import { GscInfo } from '../../../../../common/api-types'
import ModalGraph from './ModalGraph'
const style = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  gap: 2,
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  width: '50vw',
  transform: 'translate(-50%, -50%)',
  maxwidth: '50vh',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  outline: 'none',
  overflow: 'auto',
}

interface URLInfoModalProps {
  open: boolean
  onClose: () => void
  modalData: RowData | null
  sub_topic: string[]
  filterValue: string
  old_start_date: string
  old_end_date: string
  new_start_date: string
  new_end_date: string
}

type RowData = {
  address: string
  content: string
  status_code: number
  old_total_clicks: number
  new_total_clicks: number
  old_total_impressions: number
  new_total_impressions: number
  clicks_difference: number
  impressions_difference: number
  old_queries: GscInfo[]
  new_queries: GscInfo[]
}

const GET_DATA = gql`
  query GetOldData($where: OldGSCDataInput) {
    oldGSCData(where: $where) {
      address
      inlinks_count
      old_inlinks {
        address
        queries {
          clicks
          ctr
          end_date
          impressions
          position
          query
          start_date
        }
        status_code
      }
    }
  }
`

const InfoModal: React.FC<URLInfoModalProps> = ({
  open,
  onClose,
  modalData,
  sub_topic,
  filterValue,
  old_start_date,
  old_end_date,
  new_start_date,
  new_end_date,
}) => {
  const [pagination, setPagination] = useState<any>({
    pageSize: 10,
    pageIndex: 0,
  })
  const { data, loading, error, networkStatus, refetch } = useQuery(GET_DATA, {
    variables: {
      options: {
        limit: pagination?.pageSize || 10,
        offset: (pagination?.pageIndex || 0) * (pagination?.pageSize || 10),
      },
      where: {
        address: modalData?.address,
        sub_topic: sub_topic,
        start_date: old_start_date,
        end_date: old_end_date,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const [selectedIndex, setSelectedIndex] = React.useState(1)

  const columns = useMemo(
    () => [
      {
        accessorKey: 'address',
        header: 'Page / URL',
        Cell: ({ row }: any) => {
          const url = getUrlPath(row.original.address)
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <a
                href={row.original.address}
                target="_blank"
                rel="noopener noreferrer"
              >
                {url}
              </a>
            </Box>
          )
        },
      },
      {
        accessorKey: 'clicks_difference',
        header: 'Click Difference',
        Cell: ({ row }: any) => {
          const change = row.original.clicks_difference
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {change > 0 ? (
                <Typography sx={{ color: 'green' }}>{`+${change}`}</Typography>
              ) : change < 0 ? (
                <Typography sx={{ color: 'red' }}>{change}</Typography>
              ) : (
                <Typography>{change}</Typography>
              )}
            </Box>
          )
        },
      },
      {
        accessorKey: 'impressions_difference',
        header: 'Impressions',
        Cell: ({ row }: any) => {
          const change = row.original.impressions_difference
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {change > 0 ? (
                <Typography sx={{ color: 'green' }}>{`+${change}`}</Typography>
              ) : change < 0 ? (
                <Typography sx={{ color: 'red' }}>{change}</Typography>
              ) : (
                <Typography>{change}</Typography>
              )}
            </Box>
          )
        },
      },
    ],
    [],
  )

  const subColumns = useMemo(
    () => [
      {
        id: 'old_query',
        header: `(${old_start_date} - ${old_end_date})`,
        columns: [
          {
            accessorKey: 'old_query',
            header: `Query`,
            size: 100,
          },
          {
            accessorKey: 'old_clicks',
            header: `Clicks`,
            size: 100,
          },
          {
            accessorKey: 'old_impressions',
            header: `Impressions`,
            size: 100,
          },
          {
            accessorKey: 'old_ctr',
            header: `CTR`,
            size: 100,
          },
          {
            accessorKey: 'old_position',
            header: `Position`,
            size: 100,
            Cell: ({ row }: any) => {
              const position = row.original.old_position.toFixed(2)
              return <>{position}</>
            },
          },
        ],
      },
      {
        id: 'new_query',
        header: `(${new_start_date} - ${new_end_date})`,
        columns: [
          {
            accessorKey: 'new_query',
            header: `Query`,
            size: 100,
          },
          {
            accessorKey: 'new_clicks',
            header: `Clicks`,
            size: 100,
          },
          {
            accessorKey: 'new_impressions',
            header: `Impressions`,
            size: 100,
          },
          {
            accessorKey: 'new_ctr',
            header: `CTR`,
            size: 100,
          },
          {
            accessorKey: 'new_position',
            header: `Position`,
            size: 100,
          },
        ],
      },
    ],
    [],
  )

  if (loading) {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Loading />
        </Box>
      </>
    )
  }
  if (error) {
    return <ErrorMessage error={error.message} />
  }

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index)
  }

  let rows: any = []
  const queryData: any = []

  data.oldGSCData.old_inlinks.forEach((group: any) => {
    const existingIndex = rows.findIndex(
      (row: any) => row.address === group.address,
    )

    let rowData: RowData = {
      address: group.address,
      content: group.content || '',
      status_code: group.status_code || 0,
      old_total_clicks: 0,
      new_total_clicks: modalData?.new_total_clicks || 0,
      old_total_impressions: 0,
      new_total_impressions: modalData?.new_total_impressions || 0,
      clicks_difference: 0,
      impressions_difference: 0,
      old_queries: [],
      new_queries: modalData?.new_queries || [],
    }

    if (existingIndex > -1) {
      rowData = rows[existingIndex]
    }

    group.queries.forEach((query: GscInfo) => {
      rowData.old_total_clicks += query.clicks
      rowData.old_total_impressions += query.impressions
      rowData.old_queries.push(query)
    })

    rowData.clicks_difference =
      rowData.new_total_clicks - rowData.old_total_clicks
    rowData.impressions_difference =
      rowData.new_total_impressions - rowData.old_total_impressions

    if (existingIndex > -1) {
      rows[existingIndex] = rowData
    } else {
      rows.push(rowData)
    }
  })

  rows.sort((a: any, b: any) => {
    return b.clicks_difference - a.clicks_difference
  })

  if (rows.length > 0) {
    rows.forEach((row: RowData) => {
      const queries: any = []
      row.new_queries.forEach((new_query: GscInfo) => {
        const old_query = row.old_queries.find(
          (old_query: GscInfo) => old_query.query === new_query.query,
        )
        queries.push({
          new_query: new_query.query,
          old_query: old_query?.query || 'N/A',
          new_clicks: new_query.clicks,
          old_clicks: old_query?.clicks || 0,
          new_impressions: new_query.impressions,
          old_impressions: old_query?.impressions || 0,
          new_ctr: new_query.ctr,
          old_ctr: old_query?.ctr || 0,
          new_position: new_query.position,
          old_position: old_query?.position || 0,
        })
      })
      row.old_queries.forEach((old_query: GscInfo) => {
        const new_query = row.new_queries.find(
          (new_query: GscInfo) => new_query.query === old_query.query,
        )
        if (!new_query) {
          queries.push({
            new_query: 'N/A',
            old_query: old_query.query,
            new_clicks: 0,
            old_clicks: old_query.clicks,
            new_impressions: 0,
            old_impressions: old_query.impressions,
            new_ctr: 0,
            old_ctr: old_query.ctr,
            new_position: 0,
            old_position: old_query.position,
          })
        }
      })
      queries.sort((a: any, b: any) => {
        return b.new_clicks - a.new_clicks
      })
      queryData.push(queries)
    })
  }

  return (
    <div>
      <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title">
        <Box sx={style}>
          <Typography
            sx={{
              textTransform: 'uppercase',
            }}
            id="modal-modal-title"
            variant="h5"
            component="h2"
          ></Typography>
          <Paper sx={{ p: 2, height: '500px', width: '100%' }}>
            <ModalGraph
              sub_topic={sub_topic}
              data={rows}
              modalData={modalData}
              filterValue={filterValue}
            />
          </Paper>

          <Paper
            sx={{
              display: 'flex',

              p: 2,
              '& > :not(style)': {
                mr: 2,
              },
              flexDirection: 'row',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 2,
                width: '30%',
                height: '100%',
                '& > :not(style)': {
                  m: 0.5,
                },
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {' '}
                {getUrlPath(modalData?.address || '')}
              </Typography>
              <URLInfo
                label="Status"
                value={`Live (${modalData?.status_code || 0})`}
              />
              <URLInfo
                label="All Inlinks"
                value={data.oldGSCData.inlinks_count}
              />
              <URLInfo label="Content Inlinks" value={rows.length || 0} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '100%',
                width: '70%',
                '& > :not(style)': {
                  m: 1,
                },
              }}
            >
              <MaterialReactTable
                columns={columns}
                data={rows || []}
                enableExpandAll={false}
                enableStickyHeader
                defaultColumn={{
                  minSize: 20,
                  maxSize: 300,
                }}
                initialState={{
                  // showColumnFilters: false,
                  // pagination: { pageSize: 10, pageIndex: 0 },
                  density: 'compact',
                }}
                // manualPagination
                // manualFiltering
                muiTablePaginationProps={{
                  rowsPerPageOptions: [],
                  showFirstButton: true,
                  showLastButton: true,
                }}
                // onPaginationChange={setPagination}
                // rowCount={data?.gscBySubTopicsCount || 0}
                muiToolbarAlertBannerProps={
                  error
                    ? {
                        color: 'error',
                        children: 'Error loading data',
                      }
                    : undefined
                }
                state={{
                  isLoading: loading,
                  showAlertBanner: !!error,
                  showProgressBars: networkStatus === NetworkStatus.refetch,
                }}
                renderDetailPanel={({ row }) => {
                  const n = row.index
                  return (
                    <Box>
                      <MaterialReactTable
                        columns={subColumns}
                        data={queryData[n] || []}
                        enableTopToolbar={false}
                        enableStickyHeader
                        initialState={{
                          density: 'compact',
                        }}
                        muiTablePaginationProps={{
                          rowsPerPageOptions: [],
                          showFirstButton: true,
                          showLastButton: true,
                        }}
                      />
                    </Box>
                  )
                }}
              />
            </Box>
          </Paper>
        </Box>
      </Modal>
    </div>
  )
}

interface URLInfoProps {
  label: string
  value: string | number
}

const URLInfo: React.FC<URLInfoProps> = ({ label, value }) => {
  return (
    <Typography variant="body1" component="div">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <Box sx={{ fontWeight: 'bold' }}>{label}: </Box>
        <Box>{value}</Box>
      </Box>
    </Typography>
  )
}

export default InfoModal
