import Title from '@components/ui/Title'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, ListItem, ListItemText, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1400,
    width: '100%',
    overflow: 'hidden',
    marginTop: 10,
    margin: 'auto',
    padding: 10,
  },
  table: {
    minWidth: 700,
  },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    minWidth: 300,
  },
}))

type CrawlComparison = {
  changeType: string
  changedCount: number
  changed: CrawlChanges[]
}

type CrawlChanges = {
  address: string
  p_status_code: number
  c_status_code: number
  date: string
  inboundLinks: InboundLinks[]
}
type InboundLinks = {
  address: string
  anchor: string
}

export interface GenericTableProps {
  data: CrawlComparison[]
  columns: MRT_ColumnDef[]
  subDataColumns: MRT_ColumnDef[]
}

const MultiCrawlTable: React.FC<GenericTableProps> = ({
  data,
  columns,
  subDataColumns,
}) => {
  const classes = useStyles()
  const handleItemClick = (item: any) => {
    if (selectedItem?.changeType === item.changeType) {
      setSelectedItem(null) // Hide the table if the same item is clicked again
    } else {
      setSelectedItem(item)
    }
  }

  const [selectedItem, setSelectedItem] = React.useState<{
    changeType: string
  } | null>(null)

  return (
    <React.Fragment>
      <Title>Multi Crawl Report</Title>
      <Paper className={classes.root}>
        {data?.map((item: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <ListItem button onClick={() => handleItemClick(item)}>
                <ListItemText
                  primary={`${item.changeType} - (${item.changedCount})`}
                />
              </ListItem>

              {selectedItem && selectedItem.changeType === item.changeType && (
                <MaterialReactTable
                  columns={columns}
                  data={item.changed}
                  renderDetailPanel={({ row }) => {
                    const n = row.index
                    return (
                      <Box>
                        <MaterialReactTable
                          key={row.id}
                          columns={subDataColumns}
                          data={item.changed[n].inboundLinks}
                          enableColumnOrdering
                          enableEditing
                          renderRowActions={({ row }) => (
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                              <a
                                href={`${
                                  item.changed[n].inboundLinks[row.index]
                                    .address
                                }#:~:text=${encodeURIComponent(
                                  item.changed[n].inboundLinks[row.index]
                                    .anchor,
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  marginLeft: '0.5em',
                                  marginRight: '0.5em',
                                }}
                              >
                                <OpenInNewIcon fontSize="small" />
                              </a>
                            </Box>
                          )}
                        />
                      </Box>
                    )
                  }}
                />
              )}
            </React.Fragment>
          )
        })}
      </Paper>
    </React.Fragment>
  )
}

export default MultiCrawlTable
