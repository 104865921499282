import { useAuth0 } from '@auth0/auth0-react'
import LoginButton from '@components/auth/LoginButton';
import LogoutButton from '@components/auth/LogoutButton'

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0()

  if (isLoading) {
    return <div>Loading ...</div>
  }
  if (isAuthenticated) {
    return (
      <div className="p-2 text-right">
        {/* <img src={user.picture} alt={user.name} /> */}
        {/* <h2>{user.name}</h2> */}
        <p className="font-bold">{user?.email}</p>
        <LogoutButton/>
      </div>
    )
  } else {
    return (
      <LoginButton />
    )
  }
}

export default Profile
