import { NetworkStatus, gql, useQuery } from '@apollo/client'
import { getUrlPath } from '@lib/utils/url'
import { Box, Typography } from '@mui/material'
import MaterialReactTable from 'material-react-table'
import { useMemo } from 'react'
import {
  GscByPageGroup,
  GscInfo,
  GscSubData,
  GscurlData,
} from '../../../../../common/api-types'
import GSCByPageGroupGraph from './GSCByPageGroupGraph'

interface GSCByPageGroupProps {
  old_start_date: string
  old_end_date: string
  new_start_date: string
  new_end_date: string
  main_topic: string[]
  filterValue: string
}

const GET_DATA = gql`
  query GscByPageGroup($where: CategoryInput) {
    gscByPageGroup(where: $where) {
      main_topic
      sub_data {
        subtopic
        urls {
          address
          queries {
            clicks
            ctr
            end_date
            impressions
            position
            query
            start_date
          }
        }
      }
    }
  }
`

type RowData = {
  subtopic: string
  clicks_difference: number
  impressions_difference: number
  urls: {
    address: string
    old_total_clicks: number
    new_total_clicks: number
    old_total_impressions: number
    new_total_impressions: number
    clicks_difference: number
    impressions_difference: number
    old_queries: GscInfo[]
    new_queries: GscInfo[]
  }[]
}

const GSCByPageGroup = ({
  old_start_date,
  old_end_date,
  new_start_date,
  new_end_date,
  main_topic,
  filterValue,
}: GSCByPageGroupProps) => {
  const { loading, data, error, networkStatus, refetch } = useQuery(GET_DATA, {
    variables: {
      where: {
        old_start_date: old_start_date,
        old_end_date: old_end_date,
        new_start_date: new_start_date,
        new_end_date: new_end_date,
        main_topic: main_topic,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const columns = useMemo(
    () => [
      {
        accessorKey: 'subtopic',
        header: 'Topic',
      },
      {
        accessorKey: 'clicks_difference',
        header: 'Clicks',
        Cell: ({ row }: any) => {
          const change = row.original.clicks_difference
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {change > 0 ? (
                <Typography sx={{ color: 'green' }}>{`+${change}`}</Typography>
              ) : change < 0 ? (
                <Typography sx={{ color: 'red' }}>{change}</Typography>
              ) : (
                <Typography>{change}</Typography>
              )}
            </Box>
          )
        },
      },
      {
        accessorKey: 'impressions_difference',
        header: 'Impressions',
        Cell: ({ row }: any) => {
          const change = row.original.impressions_difference
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {change > 0 ? (
                <Typography sx={{ color: 'green' }}>{`+${change}`}</Typography>
              ) : change < 0 ? (
                <Typography sx={{ color: 'red' }}>{change}</Typography>
              ) : (
                <Typography>{change}</Typography>
              )}
            </Box>
          )
        },
      },
    ],
    [],
  )

  const urlColumns = useMemo(
    () => [
      {
        accessorKey: 'address',
        header: 'URL',
        Cell: ({ row }: any) => {
          const url = getUrlPath(row.original.address)
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <a
                href={row.original.address}
                target="_blank"
                rel="noopener noreferrer"
              >
                {url}
              </a>
            </Box>
          )
        },
      },
      {
        accessorKey: 'clicks_difference',
        header: `Clicks`,
      },
      {
        accessorKey: 'impressions_difference',
        header: `Impressions`,
      },
    ],
    [],
  )

  const queryColumns = useMemo(
    () => [
      {
        id: 'old_query',
        header: `(${old_start_date} - ${old_end_date})`,
        columns: [
          {
            accessorKey: 'old_query',
            header: `Query`,
            size: 100,
          },
          {
            accessorKey: 'old_clicks',
            header: `Clicks`,
            size: 100,
          },
          {
            accessorKey: 'old_impressions',
            header: `Impressions`,
            size: 100,
          },
          {
            accessorKey: 'old_ctr',
            header: `CTR`,
            size: 100,
          },
          {
            accessorKey: 'old_position',
            header: `Position`,
            size: 100,
            Cell: ({ row }: any) => {
              const position = row.original.old_position.toFixed(2)
              return <>{position}</>
            },
          },
        ],
      },
      {
        id: 'new_query',
        header: `(${new_start_date} - ${new_end_date})`,
        columns: [
          {
            accessorKey: 'new_query',
            header: `Query`,
            size: 100,
          },
          {
            accessorKey: 'new_clicks',
            header: `Clicks`,
            size: 100,
          },
          {
            accessorKey: 'new_impressions',
            header: `Impressions`,
            size: 100,
          },
          {
            accessorKey: 'new_ctr',
            header: `CTR`,
            size: 100,
          },
          {
            accessorKey: 'new_position',
            header: `Position`,
            size: 100,
          },
        ],
      },
    ],
    [],
  )

  const mainData: any = []
  const queryData: any = []

  if (!loading && !error) {
    data.gscByPageGroup.map((mainCluster: GscByPageGroup) => {
      const rows: any = mainCluster.sub_data.map((group: GscSubData) => {
        const rowData: RowData = {
          subtopic: group.subtopic,
          clicks_difference: 0,
          impressions_difference: 0,
          urls: [],
        }
        group.urls.forEach((url: GscurlData) => {
          let oldTotalClicks = 0
          let newTotalClicks = 0
          let oldTotalImpressions = 0
          let newTotalImpressions = 0
          const oldQueries: GscInfo[] = []
          const newQueries: GscInfo[] = []

          url.queries.forEach((query: GscInfo) => {
            // Aggregate totals for each URL
            if (query.start_date && query.end_date) {
              if (
                query.start_date === old_start_date &&
                query.end_date === old_end_date
              ) {
                oldTotalClicks += query.clicks
                oldTotalImpressions += query.impressions
                oldQueries.push(query)
              } else {
                newTotalClicks += query.clicks
                newTotalImpressions += query.impressions
                newQueries.push(query)
              }
            }
          })

          rowData.urls.push({
            address: url.address,
            old_total_clicks: oldTotalClicks,
            new_total_clicks: newTotalClicks,
            clicks_difference: oldTotalClicks + newTotalClicks,
            impressions_difference: oldTotalImpressions + newTotalImpressions,
            old_total_impressions: oldTotalImpressions,
            new_total_impressions: newTotalImpressions,
            old_queries: oldQueries,
            new_queries: newQueries,
          })

          // Update total clicks and impressions for the main row
          rowData.clicks_difference += newTotalClicks - oldTotalClicks
          rowData.impressions_difference +=
            newTotalImpressions - oldTotalImpressions
        })
        return rowData
      })

      rows.sort((a: RowData, b: RowData) => {
        return b.clicks_difference - a.clicks_difference
      })
      mainData.push({
        main_topic: mainCluster.main_topic,
        sub_data: rows,
      })
    })
  }

  if (mainData.length > 0) {
    mainData.map((rows: any) => {
      rows.sub_data.forEach((row: RowData) => {
        const data: any = []
        row.urls.forEach((url: any) => {
          const queries: any = []
          url.new_queries.forEach((new_query: GscInfo) => {
            const old_query = url.old_queries.find(
              (old_query: GscInfo) => old_query.query === new_query.query,
            )
            queries.push({
              new_query: new_query.query,
              old_query: old_query?.query || 'N/A',
              new_clicks: new_query.clicks,
              old_clicks: old_query?.clicks || 0,
              new_impressions: new_query.impressions,
              old_impressions: old_query?.impressions || 0,
              new_ctr: new_query.ctr,
              old_ctr: old_query?.ctr || 0,
              new_position: new_query.position,
              old_position: old_query?.position || 0,
            })
          })
          url.old_queries.forEach((old_query: GscInfo) => {
            const new_query = url.new_queries.find(
              (new_query: GscInfo) => new_query.query === old_query.query,
            )
            if (!new_query) {
              queries.push({
                new_query: 'N/A',
                old_query: old_query.query,
                new_clicks: 0,
                old_clicks: old_query.clicks,
                new_impressions: 0,
                old_impressions: old_query.impressions,
                new_ctr: 0,
                old_ctr: old_query.ctr,
                new_position: 0,
                old_position: old_query.position,
              })
            }
          })
          data.push(queries)
        })
        queryData.push(data)
      })
    })
  }

  const rows: any = []
  if (mainData.length > 0) {
    mainData.map((row: any) => {
      row.sub_data.forEach((sub_data: any) => {
        rows.push({
          subtopic: sub_data.subtopic,
          clicks_difference: sub_data.clicks_difference,
          impressions_difference: sub_data.impressions_difference,
          urls: sub_data.urls,
        })
      })
    })
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          border: '1px solid #ccc',
          height: '600px',
        }}
      >
        {mainData.length > 0 && (
          <GSCByPageGroupGraph rows={mainData} filterValue={filterValue} />
        )}
      </Box>

      <Box
        sx={{
          height: '600px',
          maxHeight: '600px',
          overflow: 'auto',
        }}
      >
        <MaterialReactTable
          columns={columns}
          data={rows || []}
          enableExpandAll={false}
          enableStickyHeader
          defaultColumn={{
            minSize: 20,
            maxSize: 300,
            size: 300,
          }}
          initialState={{
            density: 'compact',
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [],
            showFirstButton: true,
            showLastButton: true,
          }}
          muiToolbarAlertBannerProps={
            error
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }
          state={{
            isLoading: loading,
            showAlertBanner: !!error,
            showProgressBars: networkStatus === NetworkStatus.refetch,
          }}
          renderDetailPanel={({ row }) => {
            const n = row.index
            return (
              <Box>
                <MaterialReactTable
                  columns={urlColumns}
                  data={rows[n].urls || []}
                  enableTopToolbar={false}
                  enableStickyHeader
                  enableExpandAll={false}
                  muiTableBodyRowProps={{ hover: false }}
                  initialState={{
                    density: 'compact',
                  }}
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [],
                    showFirstButton: true,
                    showLastButton: true,
                  }}
                  renderDetailPanel={({ row }) => {
                    const m = row.index
                    return (
                      <Box>
                        <MaterialReactTable
                          columns={queryColumns}
                          data={queryData[n][m] || []}
                          enableTopToolbar={false}
                          enableStickyHeader
                          initialState={{
                            density: 'compact',
                          }}
                          muiTablePaginationProps={{
                            rowsPerPageOptions: [],
                            showFirstButton: true,
                            showLastButton: true,
                          }}
                        />
                      </Box>
                    )
                  }}
                />
              </Box>
            )
          }}
        />
      </Box>
    </>
  )
}
export default GSCByPageGroup
