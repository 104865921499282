import { GscInfo } from '../../../../../common/api-types'
import { colours } from '@pages/redirected-url-health/Colour'

type RowData = {
  clicks_difference: number
  impressions_difference: number
}

export const getMaxRowByField = (
  data: RowData[],
  field: 'clicks_difference' | 'impressions_difference',
): number | null => {
  if (data.length === 0) {
    return null
  }

  let maxRow = data[0] || null

  if (data.length > 0) {
    for (let i = 1; i < data.length; i++) {
      if (Math.abs(data[i][field]) > Math.abs(maxRow[field])) { 
        maxRow = data[i]
      }
    }
  }

  return Math.abs(maxRow[field]) || null
}
export const getNodeSize = (difference: number, highestVal: number) => {
  const minSize = 20
  let scaledValue = minSize // default value

  if (highestVal !== 0) {
    scaledValue = (Math.abs(difference) / highestVal) * 100 + minSize
  }
  return {
    height: Math.round(scaledValue),
    width: Math.round(scaledValue),
  }
}


export const getColor = (difference: number) => {
  if (difference > 0) {
    return colours.dundee_green
  } else if (difference < 0) {
    return colours.red
  } else {
    return colours.grey
  }
}
