import { Autocomplete, Box, Button, TextField } from '@mui/material'
import { Ga4DatesRanges } from '../../../../../common/api-types'
import { get } from 'http'



interface FixedDateRangeProps {
  dateRanges: Ga4DatesRanges[]
  oldDR: string
  newDR: string
  setOldDR: (oldDR: string) => void
  setNewDR: (newDR: string) => void
}

const FixedDateRange = ({
  dateRanges,
  setOldDR,
  setNewDR,
  oldDR,
  newDR,
}: FixedDateRangeProps) => {
  const dates = dateRanges.map(
    (date: Ga4DatesRanges) => date.start_date + ' - ' + date.end_date,
  )

  const getDates = (dateString: string) => {
    const dates = dateString.split(' - ')
    const startDate = new Date(dates[0])
    const endDate = new Date(dates[1])

    return {
      startDate,
      endDate,
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        p: 2,
      }}
    >
      
      <Autocomplete
        disablePortal
        id="oldDR"
        value={oldDR}
        onChange={(e, n) => {
          setOldDR(n ? n : '')
        }}
        size="small"
        options={dates}
        getOptionDisabled={(option) =>
          option === newDR ||
          getDates(option).endDate > getDates(newDR).startDate
        }
        sx={{ width: 300, pr: 2 }}
        renderInput={(params) => <TextField {...params} label="Date Range" />}
      />
      <Autocomplete
        disablePortal
        id="newDR"
        value={newDR}
        onChange={(e, n) => {
          setNewDR(n ? n : '')
        }}
        size="small"
        options={dates}
        getOptionDisabled={(option) =>
          option === oldDR ||
          getDates(option).startDate < getDates(oldDR).endDate
        }
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Date Range" />}
      />
    </Box>
  )
}
export default FixedDateRange
