import React, { useState } from 'react'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import { useForm, SubmitHandler } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client'
import axios from 'axios'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {
  InitiateNewCrawlResult,
  StartCrawlResult,
} from '../../../../common/api-types'
import Loading from '@components/ui/Loading'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  IconButton,
  Input,
  InputAdornment,
  Link as MUILink,
  TextField,
  Typography,
} from '@mui/material'
import CrawlHistoryTable from './CrawlHistoryTable'

const theme = createTheme()


interface CrawlFormInput {
  startingUrl: string
  files: FileList
}

const INITIATE_CRAWL_MUTATION = gql`
  mutation InitiateNewCrawl($startingUrl: String!) {
    initiateNewCrawl(crawlOptions: { startingUrl: $startingUrl }) {
      crawlId
      seoConfigUploadUrl
    }
  }
`

const START_CRAWL_MUTATION = gql`
  mutation StartCrawl($crawlId: String!, $startingUrl: String!) {
    startCrawl(crawlOptions: { crawlId: $crawlId, startingUrl: $startingUrl }) {
      crawlId
    }
  }
`

const FORM_INITIAL_STATE: Partial<CrawlFormInput> = {
  startingUrl: '',
}

enum CrawlStartStatus {
  NOT_STARTED = 'NOT_STARTED',
  INITIATING = 'INITIATING',
  ERROR = 'ERROR',
  STARTED = 'STARTED',
}

const CrawlForm = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null); 

  
  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]; 
    if (file) {
      setSelectedFile(file); 
      
    } else {
      setSelectedFile(null); 
    }
  };

  const [crawlStartStatus, setCrawlStartStatus] = useState<CrawlStartStatus>(
    CrawlStartStatus.NOT_STARTED,
  )
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<CrawlFormInput>({ defaultValues: FORM_INITIAL_STATE })
  const [crawlConfiguration, setCrawlConfiguration] =
    useState<InitiateNewCrawlResult>()
  const [initiateCrawl] = useMutation(INITIATE_CRAWL_MUTATION)
  const [startCrawl] = useMutation(START_CRAWL_MUTATION)

  const onSubmit: SubmitHandler<CrawlFormInput> = async (data) => {
    try {
      setCrawlStartStatus(CrawlStartStatus.INITIATING)
      console.log('Initiating crawl...', data)
      const seoConfigFile = data.files[0]
      // 1) Generate the S3 upload URL and crawlId
      const initiateCrawlResult: InitiateNewCrawlResult = (
        await initiateCrawl({
          variables: { startingUrl: data.startingUrl },
        })
      ).data.initiateNewCrawl
      setCrawlConfiguration(initiateCrawlResult)

      console.log('Uploading config file to S3...', crawlConfiguration)
      // 2) Upload config file to S3
      const s3UploadResult = await axios.put(
        initiateCrawlResult.seoConfigUploadUrl,
        seoConfigFile,
        {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        },
      )
      console.log(
        'Config file uploaded to S3. Starting crawl now...',
        s3UploadResult,
      )

      // 3) Call the startCrawl GQL mutation
      const startCrawlResult: StartCrawlResult = (
        await startCrawl({
          variables: {
            startingUrl: data.startingUrl,
            crawlId: initiateCrawlResult.crawlId,
          },
        })
      ).data.initiateNewCrawl
      setCrawlStartStatus(CrawlStartStatus.STARTED)
      console.log('Crawl started!', startCrawlResult)
    } catch (error) {
      setCrawlStartStatus(CrawlStartStatus.ERROR)
      console.error('Error starting crawl', error)
    }
  }
  if (crawlStartStatus === CrawlStartStatus.ERROR) {
    return (
      <div className="p-4">
        <h2 className="text-xl">❌ An error occurred starting the crawl.</h2>
        <p className="my-2">
          Check the console in the browser devtools for more information
        </p>
      </div>
    )
  }
  if (isSubmitSuccessful) {
    return (
      <div className="p-4">
        <h2 className="text-xl">✅ Crawl started ok.</h2>
        <p className="my-2">
          <MUILink
            href="https://console.aws.amazon.com/states/home?region=us-east-1#/statemachines"
            target="_blank"
            rel="noreferrer"
          >
            Open AWS StepFunctions console to monitor progress of crawl
          </MUILink>{' '}
          <br />
          <MUILink
            href="https://s3.console.aws.amazon.com/s3/home?region=us-east-1&region=us-east-1"
            target="_blank"
            rel="noreferrer"
          >
            Open AWS S3 Console to view crawl output files
          </MUILink>
        </p>

        <p className="my-2"> CrawlID: {crawlConfiguration?.crawlId}</p>
        <p className="my-2">
          Upload URL for seospider config file:{' '}
          {crawlConfiguration?.seoConfigUploadUrl}
        </p>
        <p>
          <Link
            to="/crawls"
            className="mt-8 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Start another crawl
          </Link>
        </p>
      </div>
    )
  }

  

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          '& > :not(style)': {
            mr: 2,
          },
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            width: '30%',
            height: '100%',
            border: '1px solid #ccc',
            '& > :not(style)': {
              m: 1,
            },
          }}
        >
          <Typography variant="h6"> Run a Crawl</Typography>

          <TextField
            id="startingUrl"
            label="Starting URL"
            variant="outlined"
            error={!!errors.startingUrl} 
            helperText={errors.startingUrl ? 'Field is required' : ''}
            {...register('startingUrl', { required: true })}
          ></TextField>

          <TextField
            variant="outlined"
            label="Configuration File"
            value={selectedFile ? selectedFile.name : ''}
            error={!!errors.files}
            helperText={errors.files ? 'Field is required' : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton component="label" htmlFor="seoConfigFile">
                    <CloudUploadIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            disabled
          />
          <input
            id="seoConfigFile"
            {...register('files', { required: true })}
            type="file"
            className="sr-only"
            onChange={handleFileSelect}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              disabled={isSubmitting}
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {isSubmitting ? 'Starting Crawl...' : 'Start Crawl' }
              
            </Button>
            
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            width: '70%',
            height: '600px',
            border: '1px solid #ccc',
            '& > :not(style)': {
              m: 1,
            },
          }}
        >
          <Typography variant="h6"> Crawl History</Typography>

          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 2,
            
            '& > :not(style)': {
              m: 1,
            },
          }}
          >
            <CrawlHistoryTable/>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>

    // <form
    //   className="space-y-8 divide-y divide-gray-200"
    //   onSubmit={handleSubmit(onSubmit)}
    // >
    //   <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
    //     <div>
    //       <div>
    //         <h3 className="text-lg leading-6 font-medium text-gray-900">
    //           New Crawl
    //         </h3>
    //       </div>

    //       <div
    //         className={`mt-6 sm:mt-5 space-y-6 sm:space-y-5 ${
    //           isSubmitting ? 'opacity-10' : ''
    //         }`}
    //       >
    //         <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
    //           <label
    //             htmlFor="url"
    //             className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
    //           >
    //             Starting URL
    //           </label>
    //           <div className="mt-1 sm:mt-0 sm:col-span-2">
    //             <input
    //               type="text"
    //               {...register('startingUrl', { required: true })}
    //               className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
    //             />
    //             {errors.startingUrl && (
    //               <div className="text-red-700">Field is required</div>
    //             )}
    //           </div>
    //         </div>

    //         <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
    //           <label
    //             htmlFor="screamingFrogConfigUpload"
    //             className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
    //           >
    //             Screaming Frog configuration file
    //           </label>
    //           <div className="mt-1 sm:mt-0 sm:col-span-2">
    //             <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
    //               <div className="space-y-1 text-center">
    //                 <svg
    //                   className="mx-auto h-12 w-12 text-gray-400"
    //                   stroke="currentColor"
    //                   fill="none"
    //                   viewBox="0 0 48 48"
    //                   aria-hidden="true"
    //                 >
    //                   <path
    //                     d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
    //                     strokeWidth={2}
    //                     strokeLinecap="round"
    //                     strokeLinejoin="round"
    //                   />
    //                 </svg>
    //                 <div className="flex text-sm text-gray-600">
    //                   <label
    //                     htmlFor="seoConfigFile"
    //                     className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
    //                   >
    //                     <span>Upload a file</span>
    //                     <input
    //                       id="seoConfigFile"
    //                       {...register('files', { required: true })}
    //                       type="file"
    //                       className="sr-only"
    //                     />
    //                   </label>
    //                 </div>
    //                 <p className="text-xs text-gray-500">
    //                   .seospiderconfig file
    //                 </p>
    //                 {errors.files && (
    //                   <div className="text-red-700">File is required</div>
    //                 )}
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div className="pt-5">
    //     <div className="flex justify-end">
    //       <button
    //         type="submit"
    //         disabled={isSubmitting}
    //         className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    //       >
    //         Start Crawl
    //       </button>
    //     </div>
    //     {isSubmitting && <Loading></Loading>}
    //   </div>
    // </form>
  )
}

const CrawlPage = () => {
  return (
    <div className="p-2">
      <CrawlForm />
    </div>
  )
}

export default CrawlPage
