export const colours = {
    //first palette
    red: "#DA070F",
    orange: "#FB9906",
    blue: "#09C4F8",
    black: "#0E0F11",
    purple: "#3626A7",
    green: "#4CAF50",
    burgundy: "#A10203",
    grey: "#B4B4B4",
    yellow: "#F8E71C",
  
    //#second palette
    pale_robin_egg_blue: "#9BD0D2",
    old_burgundy: "#402E2A",
    deep_tuscan_red: "#684551",
    laurel_green: "#A5C28B",
    maximum_yellow_red: "#EDC152",
    charcoal: "#394053",
    prussian: "#0B3954",
  
    //third palette - second palette but more saturated
    dark_sky_blue: "#85C9D1",
    wine_dregs: "#683548",
    bistre: "#3F2D24",
    olivine: "#9BC178",
    meat_brown: "#EDBA3B",
    charcoal_saturated: "#2F3854",
    prussian_blue_saturated: "#003154",
  
    //third palette - second palette but more saturated
    dundee_blue: "#006ab5",
    dundee_pink: "#c6316a",
    dundee_black: "#3F2D24",
    dundee_green: "#64bd29",
    dundee_orange: "#ff8429",
    dundee_grey: "#c3c3c3"
    //prussian_blue_saturated: "#003154"
  };
  