import CrawlStatusTable from '../../components/common/Tables/CrawlStatusTable'
import { useParams } from 'react-router-dom'

interface RouteParams extends Record<string, string | undefined> {
  code: string
}

function CrawlReportTable() {
  const { code } = useParams<RouteParams>()

  const availableSlug: Array<string> = [
    '404',
    '301',
    'h1',
    'server-errors',
    'http-references',
  ]

  if (!code || !availableSlug.includes(code)) {
    return <h3>Status not available!</h3>
  }

  switch (code) {
    case '404':
      return (
        <div>
          <CrawlStatusTable
            tableTitle="Status 404 List"
            statusQuery={{ status_code: 404 }}
          />
        </div>
      )
    case '301':
      return (
        <div>
          <CrawlStatusTable
            tableTitle="Status 301 List"
            statusQuery={{ status_code: 301 }}
          />
        </div>
      )
    case 'server-errors':
    case 'h1':
      return (
        <div>
          <CrawlStatusTable
            tableTitle="Pages With Missing H1 Tags"
            statusQuery={{ h1_1: null, status_code: 200 }}
          />
        </div>
      )
    case 'http-references':
      return (
        <div>
          <CrawlStatusTable
            tableTitle="HTTP References"
            statusQuery={{ status_code_not: 200 }}
          />
        </div>
      )
    default:
      return null
  }
}

export default CrawlReportTable
