import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  TableSortLabel,
  TextField,
  Button,
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { Typography, Box } from '@mui/material'

import Title from '@components/ui/Title'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

type SortDirection = 'asc' | 'desc'

const theme = createTheme()

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1400,
    marginTop: 10,
    overflowX: 'auto',
    margin: 'auto',
    padding: 10,
  },
  table: {
    minWidth: 700,
  },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    minWidth: 300,
  },
}))

const GET_DATA = gql`
  query GetData {
    invalidRedirect {
      source
      redirect
      destination
      anchor
      status_code
    }
  }
`

const InvalidRedir: React.FC = () => {
  const [order, setOrder] = useState<SortDirection>('desc')
  const [orderBy, setOrderBy] = useState('brokenURLsCount')
  const [page, setPage] = useState(0) // Updated state variable for page
  const [rowsPerPage] = useState(20) // Updated state variable for rows per page
  const [filterState, setFilterState] = useState({ urlFilter: '' })
  const [expandedRow, setExpandedRow] = useState<number | null>(null)
  const classes = useStyles()

  const getFilter = () => {
    return filterState.urlFilter.length > 0
      ? { address_CONTAINS: filterState.urlFilter.toLowerCase() }
      : {}
  }

  const { loading, data, error } = useQuery(GET_DATA, {
    variables: {
      options: {
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        ...(orderBy && {
          sort: { [orderBy]: order ? order.toUpperCase() : 'ASC' },
        }),
      },
      where: getFilter(),
    },
  })

  if (loading) {
    // Loading state...
  } else if (error) {
    console.log(error) // Log the error to the console for inspection
    // Error state...
  } else if (data) {
    // Data rendering...
  }

  const handleSortRequest = (property: any) => {
    const isAsc = orderBy === property && order === 'asc'
    const newOrder = isAsc ? 'desc' : 'asc'

    setOrder(newOrder)
    setOrderBy(property)
  }

  const handleFilterChange = (filterName: string) => (event: any) => {
    const val = event.target.value
    setFilterState((oldFilterState) => ({
      ...oldFilterState,
      [filterName]: val,
    }))
  }

  const handleRowClick = (index: number) => {
    if (expandedRow === index) {
      setExpandedRow(null)
    } else {
      setExpandedRow(index)
    }
  }

  const pageCount = Math.ceil(data?.invalidRedirect.length / rowsPerPage)

  return (
    <ThemeProvider theme={theme}>
      <Paper className={classes.root}>
        <Title>Redirect to Invalid Page</Title>
        <TextField
          id="search"
          label="URL Contains"
          className={classes.textField}
          value={filterState.urlFilter}
          onChange={handleFilterChange('urlFilter')}
          margin="normal"
          variant="outlined"
          type="text"
        />
        {loading && !error && <p>Loading...</p>}
        {error && !loading && <p>Error</p>}
        {data && !loading && !error && (
          <React.Fragment>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell
                    key="source"
                    sortDirection={orderBy === 'source' ? order : false}
                  >
                    <Tooltip
                      title="Sort"
                      placement="bottom-start"
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={orderBy === 'source'}
                        direction={order}
                        onClick={() => handleSortRequest('source')}
                      >
                        Source Address
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>

                  <TableCell
                    key="redirect"
                    sortDirection={orderBy === 'redirect' ? order : false}
                  >
                    <Tooltip
                      title="Sort"
                      placement="bottom-start"
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={orderBy === 'redirect'}
                        direction={order}
                        onClick={() => handleSortRequest('redirect')}
                      >
                        Redirect
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    key="destination"
                    sortDirection={orderBy === 'destination' ? order : false}
                  >
                    <Tooltip
                      title="Sort"
                      placement="bottom-start"
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={orderBy === 'destination'}
                        direction={order}
                        onClick={() => handleSortRequest('destination')}
                      >
                        Destination
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    key="status_code"
                    sortDirection={orderBy === 'status_code' ? order : false}
                  >
                    <Tooltip
                      title="Sort"
                      placement="bottom-start"
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={orderBy === 'status_code'}
                        direction={order}
                        onClick={() => handleSortRequest('status_code')}
                      >
                        Status Code
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.invalidRedirect
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((n: any, index: number) => (
                    <React.Fragment key={n.hash || n.source}>
                      <TableRow onClick={() => handleRowClick(index)}>
                        <TableCell component="th" scope="row">
                          {n.source}
                          <a
                            href={n.source}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              marginLeft: '0.5em',
                              marginRight: '0.5em',
                            }}
                          >
                            <OpenInNewIcon fontSize="small" />
                          </a>
                        </TableCell>
                        <TableCell>{n.redirect}</TableCell>
                        <TableCell>{n.destination}</TableCell>
                        <TableCell>{n.status_code}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={2}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => setPage(page - 1)}
                disabled={page === 0}
                sx={{ marginRight: '10px' }}
              >
                Previous Page
              </Button>
              <Typography variant="body1">
                Page {page + 1} of {pageCount}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setPage(page + 1)}
                disabled={page === pageCount - 1}
                sx={{ marginLeft: '10px' }}
              >
                Next Page
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Paper>
    </ThemeProvider>
  )
}

export default InvalidRedir
