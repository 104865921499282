import { gql, useQuery } from '@apollo/client'
import { Box, Button } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'

import FixedDateRange from '@components/common/DateRangeFilter/FixedDateRange'
import Title from '@components/ui/Title'
import CategoryFilter from './CategoryFilter'
import GSCDashboard from './GSCDashboard'
import GSCByPage from './report-page/GSCByPage'
import GSCByPageGroup from './report-page/GSCByPageGroup'
import Loading from '@components/ui/Loading'
import ErrorMessage from '@components/ui/ErrorMessage'

type Category = {
  main_topic: string[]
  sub_topic: string[]
}

const theme = createTheme()

const GET_DATA = gql`
  query GscData {
    gscDateRanges {
      end_date
      start_date
    }
  }
`

const TrafficDiff: React.FC = () => {
  const [oldDR, setOldDR] = useState('')
  const [newDR, setNewDR] = useState('')

  const [searchState, setSearchState] = useState(false)
  const [isCatLoaded, setIsCatLoaded] = useState(false)

  const [filterValue, setFilterValue] = useState('Clicks')

  const [toggleValue, setToggleValue] = useState({
    redir: false,
    reco: false,
  })

  const { loading, data, error, refetch } = useQuery(GET_DATA)
  const [selectedCategory, setSelectedCategory] = useState<Category>({
    main_topic: [],
    sub_topic: [],
  })

  const [selectedReport, setSelectedReport] = useState('Pages')

  useEffect(() => {
    setSearchState(false)
  }, [oldDR, newDR])

  useEffect(() => {
    if (selectedCategory.sub_topic.length > 0) {
      setIsCatLoaded(false)
    }
  }, [selectedCategory.main_topic])

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <ErrorMessage error={error.message} />
  }

  const [oldSD, oldED] = oldDR.split(' - ')
  const [newSD, newED] = newDR.split(' - ')

  const handleSearch = () => {
    setSearchState(true)
    refetch()
  }

  return (
    <ThemeProvider theme={theme}>
      <Title>GSC Data</Title>

      {/* <Typography variant="h6" sx={{ pt: 3 }}>
        Custom Dates
      </Typography> */}
      <Box
        sx={{
          '& > *': {
            m: 2,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            direction: 'row',
          }}
        >
          <FixedDateRange
            dateRanges={data?.gscDateRanges}
            oldDR={oldDR}
            newDR={newDR}
            setOldDR={setOldDR}
            setNewDR={setNewDR}
          />
          {/* <DateRangePicker setDateR={setFDateRange} label="Date Range" />
        <DateRangePicker setDateR={setSDateRangeC} label="Date Range" /> */}
          {oldDR && newDR ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              size="small"
              sx={{ m: 'auto', height: '40px', width: '100px' }}
            >
              Search
            </Button>
          ) : null}
        </Box>

        {searchState && oldDR && newDR ? (
          <CategoryFilter
            old_start_date={oldSD}
            old_end_date={oldED}
            new_start_date={newSD}
            new_end_date={newED}
            setSelectedCat={setSelectedCategory}
            selectedCat={selectedCategory}
            selected_report={selectedReport}
            setIsCatLoaded={setIsCatLoaded}
          />
        ) : null}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            '& > *': {
              mr: 2,
            },
          }}
        >
          {selectedCategory.main_topic && oldDR && newDR && searchState ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '20%',
                  height: '100%',
                  border: '1px solid #ccc',
                }}
              >
                <GSCDashboard
                  selectedReport={selectedReport}
                  setSelectedReport={setSelectedReport}
                  filterValue={filterValue}
                  setFilterValue={setFilterValue}
                  toggleValue={toggleValue}
                  setToggleValue={setToggleValue}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '80%',
                  height: '1200px',
                  '& > *': {
                    m: 2,
                  },
                  overflow: 'auto',
                }}
              >
                {selectedReport === 'Pages' &&
                selectedCategory.main_topic &&
                searchState ? (
                  <>
                    {isCatLoaded ? (
                      <GSCByPage
                        old_start_date={oldSD}
                        old_end_date={oldED}
                        new_start_date={newSD}
                        new_end_date={newED}
                        sub_topic={selectedCategory.sub_topic}
                        main_topic={selectedCategory.main_topic}
                        filterValue={filterValue}
                        toggleValue={toggleValue}
                      />
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                          }}
                        >
                          <Loading />
                        </Box>
                      </>
                    )}
                  </>
                ) : null}
                {selectedReport === 'Topics' && searchState ? (
                  <GSCByPageGroup
                    old_start_date={oldSD}
                    old_end_date={oldED}
                    new_start_date={newSD}
                    new_end_date={newED}
                    main_topic={selectedCategory.main_topic}
                    filterValue={filterValue}
                  />
                ) : null}
              </Box>
            </>
          ) : null}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default TrafficDiff
