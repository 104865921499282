import React from 'react'
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'
import UrlList from '@pages/url-list/UrlList'
import CrawlForm from '@pages/crawl-form/CrawlForm'
import { AuthenticationGuard } from '@components/auth/AuthenticationGuard'
import { styled, useTheme } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'

import {
  CssBaseline,
  Drawer,
  Box,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Container,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material'
import { Link } from 'react-router-dom'
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  Http as HttpIcon,
  Link as LinkIcon,
  Language as LanguageIcon,
  InsertChart as InsertChartIcon,
} from '@mui/icons-material'
import SmartToyRoundedIcon from '@mui/icons-material/SmartToyRounded'
import BugReportIcon from '@mui/icons-material/BugReport'
import Dashboard from '@pages/dashboard/Dashboard'
import UserProfile from '@pages/user-profile/UserProfile'
import LinkAnalysisPage from '@pages/link-analysis/LinkAnalysisPage'
import CrawlReport from '@pages/crawl-report-pages/CrawlReport'
import RedirectReport from './pages/crawl-report-pages/RedirectReport/RedirectReport'
import Url404 from '@pages/crawl-report-pages/Url404test'
import CrawlReportTable from '@pages/crawl-report-pages/CrawlReportTable'
import GscapiCompare from '@pages/crawl-report-pages/ClicksComparison'
import InvalidRedir from '@pages/crawl-report-pages/InvalidRedirect'
import TrafficDiff from '@pages/GSCTopics/PerformanceReport'
import RedirectsHealth from '@pages/redirects-health/RedirectsHealth'
import MultiCrawlReport from '@pages/crawl-report-pages/MultiCrawlReport'
import PerformanceReport from '@pages/GSCTopics/PerformanceReport'
import useMarkerio from '@lib/hooks/markerioHook'
import RedirectsVisualisation from '@pages/redirected-url-health/RedirectsVisualisation'
import TopUrlCommunities from '@pages/link-analysis/link-analysis-pages/TopUrlCommunities'
import LeakyPages from '@pages/link-analysis/link-analysis-pages/LeakyPages'
import InfluentialPage from '@pages/link-analysis/link-analysis-pages/InfluentialPage'
import GraphBot from '@pages/graphbot/GraphBot'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      Content Graph {new Date().getFullYear()}
    </Typography>
  )
}

const drawerWidth = 240

// This component makes use of the Persistent Drawer pattern from Material UI: https://mui.com/material-ui/react-drawer/#persistent-drawer

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  height: '100%',
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

const openDrawerByDefault = true

export default function App() {
  useMarkerio()
  const theme = useTheme()
  const [open, setOpen] = React.useState(openDrawerByDefault)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Router>
      <Box sx={{ display: 'flex', minHeight: 'inherit' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              sx={{ ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <img
              className="max-h-16 px-5"
              src="img/grandstack.png"
              alt="GRANDstack logo"
            />
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className="w-64"
            >
              Content Graph
            </Typography>
            <div className="w-full text-right">
              <UserProfile />
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <Link to="/">
              <ListItem>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
            </Link>
            <Link to="/urls">
              <ListItem>
                <ListItemIcon>
                  <HttpIcon />
                </ListItemIcon>
                <ListItemText primary="URL List" />
              </ListItem>
            </Link>
            <Link to="/link-analysis">
              <ListItem>
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText primary="Link Analysis" />
              </ListItem>
            </Link>
            <Link to="/crawls">
              <ListItem>
                <ListItemIcon>
                  <LanguageIcon />
                </ListItemIcon>
                <ListItemText primary="Crawls" />
              </ListItem>
            </Link>
          </List>
          <Link to="/gsc-topics">
            <ListItem>
              <ListItemIcon>
                <BugReportIcon />
              </ListItemIcon>
              <ListItemText primary="GSC Topics" />
            </ListItem>
          </Link>
          <Link to="/redirects-health">
            <ListItem>
              <ListItemIcon>
                <BugReportIcon />
              </ListItemIcon>
              <ListItemText primary="Redirects Health" />
            </ListItem>
          </Link>

          <Link to="/crawl-report">
            <ListItem>
              <ListItemIcon>
                <InsertChartIcon />
              </ListItemIcon>
              <ListItemText primary="Crawl Report" />
            </ListItem>
          </Link>

          <Link to="/graphbot">
            <ListItem>
              <ListItemIcon>
                <SmartToyRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="GraphBot" />
            </ListItem>
          </Link>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <Container maxWidth="xl" sx={{ height: '100%' }}>
            <Routes>
              <Route
                path="/"
                element={<AuthenticationGuard component={Dashboard} />}
              />
              <Route
                path="/urls"
                element={<AuthenticationGuard component={UrlList} />}
              />
              <Route
                path="/crawls"
                element={<AuthenticationGuard component={CrawlForm} />}
              />
              <Route
                path="/link-analysis"
                element={<AuthenticationGuard component={LinkAnalysisPage} />}
              />
              <Route
                path="/crawl-report"
                element={<AuthenticationGuard component={CrawlReport} />}
              />
              <Route
                path="/crawl-report/:code"
                element={<AuthenticationGuard component={CrawlReportTable} />}
              />
              <Route
                path="/crawl-report/4xx"
                element={<AuthenticationGuard component={Url404} />}
              />
              <Route
                path="/crawl-report/3xx"
                element={<AuthenticationGuard component={RedirectReport} />}
              />

              <Route
                path="/gsc-topics"
                element={<AuthenticationGuard component={PerformanceReport} />}
              />
              <Route
                path="/redirects-health"
                element={<AuthenticationGuard component={RedirectsHealth} />}
              />
              <Route
                path="/link-analysis/redirects-analysis"
                element={
                  <AuthenticationGuard component={RedirectsVisualisation} />
                }
              />
              <Route
                path="/crawl-report/multi-crawl-report"
                element={<AuthenticationGuard component={MultiCrawlReport} />}
              />
              <Route
                path="/crawl-report/traffic-difference"
                element={<AuthenticationGuard component={TrafficDiff} />}
              />

              <Route
                path="/crawl-report/invalid-redirect"
                element={<AuthenticationGuard component={InvalidRedir} />}
              />
              <Route
                path="/crawl-report/clicks-comparison"
                element={<AuthenticationGuard component={GscapiCompare} />}
              />
              <Route
                path="/link-analysis/similarly-linked-pages"
                element={<AuthenticationGuard component={TopUrlCommunities} />}
              />
              <Route
                path="/link-analysis/leaky-pages"
                element={<AuthenticationGuard component={LeakyPages} />}
              />
              <Route
                path="/link-analysis/influential-pages"
                element={<AuthenticationGuard component={InfluentialPage} />}
              />
              <Route
                path="/graphbot"
                element={<AuthenticationGuard component={GraphBot} />}
              />
            </Routes>
          </Container>
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              pt: 2,
              pb: 2,
            }}
            mt={4}
          >
            <Copyright />
          </Box>
        </Main>
      </Box>
    </Router>
  )
}
