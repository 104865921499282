import { colours } from '@pages/redirected-url-health/Colour'
import cytoscape, { ElementDefinition } from 'cytoscape'
import cola from 'cytoscape-cola'
import coseBilkent from 'cytoscape-cose-bilkent'
import { useEffect, useState } from 'react'
import CytoscapeComponent from 'react-cytoscapejs'
import { GscInfo } from '../../../../../common/api-types'
import { sub } from 'date-fns'

import {
  getMaxRowByField,
  getNodeSize,
} from '../report-functions/GraphFunctions'

cytoscape.use(coseBilkent)

interface GSCByPageGraphProps {
  rows: MainData[]
  filterValue: string
}

type MainData = {
  main_topic: string[]
  sub_data: RowData[]
}

type RowData = {
  subtopic: string
  clicks_difference: number
  impressions_difference: number
  urls: {
    address: string
    old_total_clicks: number
    new_total_clicks: number
    old_total_impressions: number
    new_total_impressions: number
    clicks_difference: number
    impressions_difference: number
    old_queries: GscInfo[]
    new_queries: GscInfo[]
  }[]
}

const generateGraphElements = (
  rows: MainData[],
  head_topic: any,
  filterValue: string,
) => {
  const color = (difference: number) => {
    if (difference > 0) {
      return colours.dundee_green
    } else if (difference < 0) {
      return colours.red
    } else {
      return colours.grey
    }
  }
  const SizeUp = (difference: number) => {
    const baseSize = 30
    const scaleFactor = 5 // Adjust this scaling factor for better visualization

    const adjustedSize = baseSize + scaleFactor * Math.abs(difference)
    const finalSize = Math.min(Math.max(adjustedSize, baseSize), 100)

    return { height: finalSize, width: finalSize }
  }

  const rowData: RowData[] = rows.flatMap((row) => {
    return row.sub_data.map((sub_data) => {
      return { ...sub_data }
    })
  })

  const metric =
    filterValue === 'Clicks' ? 'clicks_difference' : 'impressions_difference'

  const maxRow = getMaxRowByField(rowData, metric) || 0


  let nodes: any = []
  head_topic.map((h: any) => {
    nodes.push({
      data: {
        id: `root-node ${h.main_topic}`,
        label: h.main_topic,
        bgColor: color(
          filterValue === 'Clicks' ? h.clicksChange : h.impressionsChange,
        ),
        ...getNodeSize(
          filterValue === 'Clicks' ? h.clicksChange : h.impressionsChange,
          maxRow,
        ),
      },
    })
  })
  rows.map((row, index) => {
    const sub_topic_nodes: any = []
    row.sub_data.forEach((sub_data) => {
      let new_total_clicks = 0
      let old_total_clicks = 0

      sub_data.urls.forEach((url) => {
        old_total_clicks += url.old_total_clicks
        new_total_clicks += url.new_total_clicks
      })

      sub_topic_nodes.push({
        data: {
          id: `node-${sub_data.subtopic}`,
          label: sub_data.subtopic,
          ...getNodeSize(
            filterValue === 'Clicks'
              ? sub_data.clicks_difference
              : sub_data.impressions_difference,
            maxRow,
          ),
          bgColor: color(
            filterValue === 'Clicks'
              ? sub_data.clicks_difference
              : sub_data.impressions_difference,
          ),
        },
      })
      sub_topic_nodes.push({
        data: {
          id: `edge-${sub_data.subtopic}`,
          source: `node-${sub_data.subtopic}`,
          target: `root-node ${head_topic[index].main_topic}`,
        },
      })
    })

    sub_topic_nodes.map((n: any) => {
      nodes.push(n)
    })
  })

  return nodes as ElementDefinition[]
}

const GSCByPageGroupGraph = ({ rows, filterValue }: GSCByPageGraphProps) => {
  const [cytoKey, setCytoKey] = useState(0)
  useEffect(() => {
    setCytoKey(cytoKey + 1)
  }, [rows])

  const head_topic: any = []

  rows.forEach((sub) => {
    let clicksChange = 0
    let impressionsChange = 0
    sub.sub_data.forEach((sub_data) => {
      clicksChange += sub_data.clicks_difference
      impressionsChange += sub_data.impressions_difference
    })
    head_topic.push({
      main_topic: sub.main_topic,
      clicksChange: clicksChange,
      impressionsChange: impressionsChange,
    })
  })
  const elements = generateGraphElements(rows, head_topic, filterValue)

  const layout = {
    name: 'cose-bilkent',
    animate: 'end',
    animationDuration: 10,
    nodeSpacing: 20,
    padding: 2,
    nodeOverlap: 'auto', // Increase this value to reduce overlap
  }

  return (
    // <>HEy</>
    <CytoscapeComponent
      key={cytoKey}
      elements={elements}
      style={{ width: '100%', height: '100%' }}
      layout={layout}
      stylesheet={[
        {
          selector: 'node[label]',
          style: {
            label: 'data(label)',
            'background-color': 'data(bgColor)',
            'text-overflow-wrap': 'anywhere',
            'text-wrap': 'wrap',
            'text-max-width': '80%',
            'font-size': '5px',
            'text-valign': 'center',
            'text-halign': 'center',
            height: 'data(height)',
            width: 'data(width)',
          },
        },
        {
          selector: 'edge',
          style: {
            width: 1,
            color: '#aaa',
            'font-size': '3rem',
            'font-style': 'italic',
            'target-arrow-shape': 'triangle-backcurve',
            'target-arrow-color': '#666',
            'curve-style': 'bezier',
            'border-color': 'black',
          },
        },
        {
          selector: 'edge[lineStyle]',
          style: {
            width: 1,
            color: colours.yellow,
            'font-size': '3rem',
            'font-style': 'italic',
            'target-arrow-shape': 'triangle-backcurve',
            'target-arrow-color': colours.yellow,
            'curve-style': 'bezier',
            'line-style': 'dashed',
            'line-color': 'data(lineColor)',
            'border-width': 1, // set the border width for outline effect
            'border-color': 'black',
          },
        },
      ]}
    />
  )
}
export default GSCByPageGroupGraph
