import React from 'react'
import { CardHeader, Paper, Box, Grid } from '@mui/material'
import { useQuery, gql } from '@apollo/client'
import ErrorMessage from '@components/ui/ErrorMessage'
import CrawlReportCard from '@components/common/Cards/CrawlReportCard'
import { styled } from '@mui/material/styles'

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
}))

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
}))

const StyledCardSubHeader = styled(CardHeader)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  backgroundColor: theme.palette.primary.main,
  color: '#ffffff',
}))

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: '0px',
}))

const StyledCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  marginTop: '12px',
}))

const GET_COUNT_QUERY = gql`
  {
    url404Count
    redirUrlsCount(where: null) {
      urlCount
    }
    missingH1TagsCount
    serverErrorsCount
    duplicateTagsCount
    httpLinkReferencesCount
    redirectsToInvalidUrls
    redirectsToNonValidUrlsCount
    changes
  }
`

export default function CrawlReport() {
  const { loading, error, data } = useQuery(GET_COUNT_QUERY)

  if (error) return <ErrorMessage error={error} />

  return (
    <React.Fragment>
      <StyledPaper>
        <StyledCardHeader
          title="Crawl Overview Report"
          titleTypographyProps={{ variant: 'h4' }}
        />
        <StyledCardSubHeader
          title="Summary"
          titleTypographyProps={{ variant: 'h6' }}
        />

        <Box sx={{ flexGrow: 1 }}>
          <Grid container direction="row" spacing={2}>
            <CrawlReportCard
              title={'Status 404'}
              loading={loading}
              rerult={data?.url404Count}
              link={'404'}
            />

            <CrawlReportCard
              title={'Status 3xx'}
              loading={loading}
              rerult={data?.redirUrlsCount?.urlCount}
              link={'3xx'}
            />

            <CrawlReportCard
              title={'Missing H1 Tags'}
              loading={loading}
              rerult={data?.missingH1TagsCount}
              link={'h1'}
            />

            <CrawlReportCard
              title={'Server Errors'}
              loading={loading}
              rerult={data?.serverErrorsCount}
              link={'server-errors'}
            />

            <CrawlReportCard
              title={'Duplicate Tags'}
              loading={loading}
              rerult={data?.duplicateTagsCount}
              link={'duplicate-tags'}
            />

            <CrawlReportCard
              title={'Duplicate Pages'}
              loading={loading}
              rerult={data?.duplicateTagsCount}
              link={'duplicate-pages'}
            />
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container direction="row" spacing={2}>
            <CrawlReportCard
              title={'HTTP References'}
              loading={loading}
              rerult={data?.httpLinkReferencesCount}
              link={'http-references'}
            />

            <CrawlReportCard
              title={'Redirects to Invalid Urls'}
              loading={loading}
              rerult={data?.redirectsToInvalidUrls}
              link={'invalid-redirect'}
            />

            <CrawlReportCard
              title={'Page with broken links'}
              loading={loading}
              rerult={data?.redirectsToNonValidUrlsCount}
              link={'4xx'}
            />

            <CrawlReportCard
              title={'Updates'}
              loading={loading}
              rerult={data?.changes}
              link={'multi-crawl-report'}
            />
            <CrawlReportCard
              title={'Traffic Difference'}
              loading={loading}
              rerult={data?.test}
              link={'traffic-difference'}
            />
            <CrawlReportCard
              title={'Clicks Difference'}
              loading={loading}
              rerult={data?.test}
              link={'clicks-comparison'}
            />
          </Grid>
        </Box>
      </StyledPaper>
    </React.Fragment>
  )
}
