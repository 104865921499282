import { colours } from './Colour'

interface Metrics {
  bounce_rate: number
  engaged_sessions: number
  engagement_duration: number
  engagement_rate: number
  page_views: number
  [key: string]: number
}
interface InternalLinks {
  inlinksCount: number
  [key: string]: number
}

interface NodeSize {
  height: number
  width: number
}
interface Weights {
  [key: string]: number
}

function tierSize(tier: number): NodeSize {
  let size: NodeSize

  switch (tier) {
    case 1:
      size = { height: 90, width: 90 }
      break
    case 2:
      size = { height: 60, width: 60 }
      break
    case 3:
      size = { height: 30, width: 30 }
      break
    default:
      size = { height: 30, width: 30 }
      break
  }

  return size
}

export function calculateCosineSimilarity(
  text1: string,
  text2: string,
): number {
  const tokenize = (text: string): string[] => {
    return text
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]/g, ' ')
      .split(' ')
      .filter((token) => token.length > 0)
  }

  const getWordFrequency = (tokens: string[]): Record<string, number> => {
    const frequency: Record<string, number> = {}
    tokens.forEach((token) => {
      frequency[token] = (frequency[token] || 0) + 1
    })
    return frequency
  }

  const dotProduct = (vector1: number[], vector2: number[]): number => {
    return vector1.reduce((acc, val, i) => acc + val * vector2[i], 0)
  }

  const magnitude = (vector: number[]): number => {
    return Math.sqrt(vector.reduce((acc, val) => acc + val * val, 0))
  }

  const cosineSimilarity = (vector1: number[], vector2: number[]): number => {
    const dotProd = dotProduct(vector1, vector2)
    const mag1 = magnitude(vector1)
    const mag2 = magnitude(vector2)

    if (mag1 === 0 || mag2 === 0) {
      return 0
    }

    return dotProd / (mag1 * mag2)
  }

  const tokens1 = tokenize(text1)
  const tokens2 = tokenize(text2)

  const frequency1 = getWordFrequency(tokens1)
  const frequency2 = getWordFrequency(tokens2)

  const allTokens = Array.from(new Set([...tokens1, ...tokens2]))

  const vector1 = allTokens.map((token) => frequency1[token] || 0)
  const vector2 = allTokens.map((token) => frequency2[token] || 0)

  return cosineSimilarity(vector1, vector2)
}

export function colorCode(cosineSimilarity: number, status_code: number) {
  if (cosineSimilarity >= 0.8 && (status_code <= 300 || status_code >= 399)) {
    return colours.dundee_green
  }
  if (cosineSimilarity < 0.8) {
    return colours.yellow
  }
}

export function new_colorCode(inbound: number) {
  if (inbound > 1) {
    return colours.green
  }
  return colours.grey
}

export function getTotalPerformance(metrics: Metrics): number {
  // Define weights for each metric based on their importance
  const weights: Weights = {
    bounce_rate: 0.1,
    engaged_sessions: 0.2,
    engagement_duration: 0.3,
    engagement_rate: 0.1,
    page_views: 0.3,
  }

  // Calculate the weighted sum
  const totalPerformance = Object.keys(weights).reduce(
    (sum, metric) => sum + metrics[metric] * weights[metric],
    0,
  )

  return totalPerformance
}

export function getNodeSize(metrics: Metrics): NodeSize {
  const totalPerformance = getTotalPerformance(metrics)
  let tier: number

  // Determine the tier based on the total performance
  if (totalPerformance >= 60) {
    tier = 1
  } else if (totalPerformance >= 30) {
    tier = 2
  } else {
    tier = 3
  }

  tierSize(tier)

  return tierSize(tier)
}

export function inlinkSizeup(inlink: InternalLinks): NodeSize {
  let tier: number
  if (inlink.inlinksCount >= 30) {
    tier = 1
  } else if (inlink.inlinksCount >= 15) {
    tier = 2
  } else {
    tier = 3
  }

  return tierSize(tier)
}
export function pageViewsSizeup(data: any): NodeSize {
  let tier: number
  if (data.page_views >= 100) {
    tier = 1
  } else if (data.page_views >= 50) {
    tier = 2
  } else {
    tier = 3
  }

  return tierSize(tier)
}

export function statusCodeColor(status_code: number) {
  switch (Math.floor(status_code / 100)) {
    case 2:
      return colours.dundee_green
    case 3:
      return colours.yellow
    case 4:
      return colours.red
    default:
      return colours.grey
  }
}
