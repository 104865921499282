import React from 'react'
import Box from '@mui/material/Box'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  TextField,
} from '@mui/material'
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined'
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined'
import { TransitionProps } from '@mui/material/transitions'
import { set } from 'react-hook-form'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export type ResponseRate = 'good' | 'bad' | 'none'

interface GraphbotNotesProps {
  noteText: string
  rating: ResponseRate
  messageId: string
  // onRatingChange: (rating: ResponseRate) => void
  onNoteSave: (noteText: string, rating: string) => void
}

const GraphbotNotes: React.FC<GraphbotNotesProps> = ({
  noteText,
  rating,
  onNoteSave,
}) => {
  const [open, setOpen] = React.useState(false)
  const [_noteText, setNoteText] = React.useState(noteText)
  const [_rating, setRating] = React.useState(rating || 'none')

  const handleGoodRate = () => {
    setRating('good')
    setOpen(true)
  }

  const handleBadRate = () => {
    setRating('bad')
    setOpen(true)
  }

  const handleNoteSave = () => {
    onNoteSave(_noteText, _rating)
    setOpen(false)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          // gap: 2,
        }}
      >
        <IconButton aria-label="note" onClick={() => setOpen(true)}>
          {_noteText ? <NoteAltIcon /> : <NoteAltOutlinedIcon />}
        </IconButton>
        <IconButton aria-label="good" onClick={handleGoodRate}>
          {_rating === 'good' ? (
            <ThumbUpIcon color="success" />
          ) : (
            <ThumbUpOutlinedIcon />
          )}
        </IconButton>
        <IconButton aria-label="bad" onClick={handleBadRate}>
          {_rating === 'bad' ? (
            <ThumbDownIcon color="error" />
          ) : (
            <ThumbDownOutlinedIcon />
          )}
        </IconButton>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Notes'}</DialogTitle>
        <DialogContent>
          <TextField
            id="filled-multiline-flexible"
            label="Notes"
            placeholder="Enter notes here..."
            value={_noteText}
            multiline={true}
            rows={5}
            sx={{ width: '100%' }}
            variant="filled"
            onChange={(e) => setNoteText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleNoteSave}>
            Save
          </Button>
          <Button
            onClick={() => {
              setOpen(false)
              setNoteText(noteText)
              setRating(rating)
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default GraphbotNotes
