import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import {
  RedirectChain,
  RedirectSource,
  RedirectsHealthData,
} from '../../../../common/api-types'
import { Paper } from '@mui/material'
import Title from '@components/ui/Title'
import { MaterialReactTable } from 'material-react-table'
import RedirectChainStepper from './RedirectChainStepper'

const style = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  gap: 2,
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'content-fit',
  maxWidth: '90vw',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  outline: 'none',
  overflow: 'auto',
}

interface URLInfoModalProps {
  open: boolean
  onClose: () => void
  data: RedirectsHealthData | null
}

const URLInfoModal: React.FC<URLInfoModalProps> = ({ open, onClose, data }) => {
  return (
    <div>
      <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title">
        <Box sx={style}>
          <Typography
            sx={{
              textTransform: 'uppercase',
            }}
            id="modal-modal-title"
            variant="h5"
            component="h2"
          >
            {data && getURLPath(data.redirected_address as string)}
          </Typography>
          <Paper sx={{ p: 2 }}>
            <Title>Details</Title>
            <Typography variant="body1" component="div">
              <URLInfo
                label={'Redirected Address'}
                value={data?.redirected_address as string}
              />
              <URLInfo
                label="Status code"
                value={data?.redirected_status_code as number}
              />
              <URLInfo
                label="Redirects to"
                value={data?.final_destination as string}
              />
              <URLInfo
                label="Redirects to status code"
                value={data?.final_status_code as number}
              />
            </Typography>
          </Paper>
          <Paper sx={{ p: 2 }}>
            <Title>Redirect Chain</Title>
            <RedirectChainStepper
              chains={data?.redirect_chains as RedirectChain[]}
            />
          </Paper>
          <Paper sx={{ p: 2 }}>
            <Title>Sources</Title>

            <MaterialReactTable
              columns={[
                {
                  header: 'Address',
                  accessorKey: 'address',
                },
                {
                  header: 'Anchor',
                  accessorKey: 'anchor',
                },
              ]}
              data={(data?.sources as RedirectSource[]) || []}
              enableGlobalFilter={false}
              enableStickyHeader
              enableRowNumbers
              rowNumberMode="static"
              initialState={{
                showColumnFilters: false,
                pagination: { pageSize: 10, pageIndex: 0 },
                density: 'compact',
              }}
              muiTablePaginationProps={{
                rowsPerPageOptions: [],
                showFirstButton: true,
                showLastButton: true,
              }}
              rowCount={data?.sources?.length || 0}
              muiTableBodyRowProps={({ row }) => ({
                onClick: (event) => {
                  window.open(
                    `${row.original.address}#:~:text=${encodeURIComponent(
                      row.original.anchor as string,
                    )}`,
                    '_blank',
                    'noopener noreferrer',
                  )
                },
                sx: {
                  cursor: 'pointer',
                },
              })}
            />
          </Paper>
        </Box>
      </Modal>
    </div>
  )
}

interface URLInfoProps {
  label: string
  value: string | number
}

const URLInfo: React.FC<URLInfoProps> = ({ label, value }) => {
  return (
    <Typography variant="body1" component="div">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <Box sx={{ fontWeight: 'bold' }}>{label}: </Box>
        <Box>{value}</Box>
      </Box>
    </Typography>
  )
}

const getURLPath = (url: string): string => {
  const urlObj = new URL(url)
  return urlObj.pathname
}

export default URLInfoModal
