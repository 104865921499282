import React from 'react'

export interface ErrorMessageProps {
  error: any
}

export default function ErrorMessage(props: ErrorMessageProps) {
  const { error } = props;
  if (error) {
    console.error('Error loading component', error)
  }
  return (
    <p className="text-red-600 text-lg">
      Error loading data
    </p>
  )
}
