import { gql, useQuery } from '@apollo/client'
import ErrorMessage from '@components/ui/ErrorMessage'
import { getUrlPath } from '@lib/utils/url'
import { OpenInNew } from '@mui/icons-material'
import Cytoscape from 'cytoscape'
import klay from 'cytoscape-klay'
import CytoscapeComponent from 'react-cytoscapejs'

Cytoscape.use(klay)

const layoutOptions = {
  name: 'klay',
}

export interface RedirectReportProfileGraphProps {
  address: string
}

export interface urlData {
  address: string
  anchor: string
  status_code: number
}

const GET_DATA = gql`
  query RedirUrls(
    $options: PaginationOptionsInput
    $where: RedirectsUrlsFilterInput
  ) {
    redirUrls(options: $options, where: $where) {
      redirected_address
      redirected_status_code
      redirected_anchor
      references {
        address
        status_code
        anchor
      }
      redirect_chain {
        address
        status_code
        anchor
      }
    }
  }
`

const generateGraphElements = (data: any) => {
  const color = (status_code: number) => {
    switch (Math.floor(status_code / 100)) {
      case 2:
        return '#4CAF50'
      case 3:
        return '#FFC107'
      case 4:
        return '#F44336'
      default:
        return '#9E9E9E'
    }
  }

  const elements: any = []
  const dataArray = Array.isArray(data) ? data : [data]

  dataArray.forEach((item) => {
    let currentChainId = 'chain-0'

    item.redirect_chain.forEach((chain: any, index: any) => {
      const chainId = `chain-${index}`
      if (chainId == 'chain-0') {
        elements.push({
          data: {
            id: chainId,
            label: getUrlPath(chain.address),
            bgColor: color(chain.status_code),
          },
        })
      } else {
        elements.push(
          {
            data: {
              id: chainId,
              label: getUrlPath(chain.address),
              bgColor: color(chain.status_code),
            },
          },
          {
            data: {
              id: `${chainId}-edge`,
              source: currentChainId,
              target: chainId,
              label: chain.anchor + '(' + chain.status_code.toString() + ')',
            },
          },
        )
        currentChainId = chainId
      }
    })
    item.references.forEach((refItem: any, index: any) => {
      const sourceId = `source-${index}`
      elements.push({
        data: {
          id: sourceId,
          label: getUrlPath(refItem.address),
          bgColor: color(refItem.status_code),
        },
      })

      elements.push({
        data: {
          id: `${sourceId}-edge`,
          source: sourceId,
          target: 'chain-0',
          label:
            item.redirect_chain[0].anchor +
            ' (' +
            item.redirect_chain[0].status_code.toString() +
            ')',
        },
      })
    })
  })
  return elements
}

const ProfileGraph = (props: RedirectReportProfileGraphProps) => {
  const { address } = props
  const { loading, data, error } = useQuery(GET_DATA, {
    variables: {
      options: {
        limit: 1,
        offset: 0,
      },
      where: { redirected_address: address },
    },
  })

  if (error) return <ErrorMessage error={error} />
  if (loading) return <p>Loading</p>

  const elements = generateGraphElements(data?.redirUrls[0])

  console.log('visual:' + data?.redirUrls[0].redirect_chain[0].address)
  return (
    <div className="flex flex-col h-full">
      <div className="py-3 px-4 flex-none bg-gray-300 text-right">
        <div className="m-0">
          <a
            href={address}
            target="_blank"
            rel="noreferrer"
            title="Open URL in new window"
          >
            <OpenInNew className="pr-2" />
            <span className="font-bold">{address}</span>
          </a>
        </div>
        <div>Chain Count: {data?.redirUrls[0].redirect_chain.length - 1} </div>
      </div>
      <div className="flex-grow">
        <CytoscapeComponent
          elements={elements}
          style={{
            minWidth: '500px',
            minHeight: '200px',
            width: '100%',
            height: '100%',
            padding: '10px',
            border: '2px solid #e3e3e3',
          }}
          stylesheet={[
            {
              selector: 'node',
              style: {
                width: 20,
                height: 20,
                label: 'data(label)',
                'font-size': '5rem',
                'background-color': 'data(bgColor)',
              },
            },
            {
              selector: 'edge',
              style: {
                width: 1,
                label: 'data(label)',
                'font-size': '3rem',
                'font-style': 'italic',
                'target-arrow-shape': 'triangle-backcurve',
                'target-arrow-color': '#666',
                'curve-style': 'bezier',
                'text-rotation': 'autorotate',
                'text-margin-y': -2,
                'text-margin-x': 0,
              },
            },
          ]}
          layout={layoutOptions}
        />
      </div>
    </div>
  )
}

export default ProfileGraph
