import { gql, useQuery } from '@apollo/client'
import Title from '@components/ui/Title'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, Paper } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import MaterialReactTable from 'material-react-table'
import { useMemo } from 'react'


const theme = createTheme()

const GET_URL = gql`
  query GetUrlList {
    urllist {
      address
      status_code
      title_1
      outlinks
      inlinks
      hash
    }
    urlCount
  }
`

function UrlList() {
  const { loading, error, data,  } = useQuery(GET_URL)

  const columns = useMemo(
    () => [
      {
        accessorKey: 'address',
        header: 'Address',
        size: 100,

      },
      {
        accessorKey: 'status_code',
        header: 'Status Code',
        size: 10,
      },
      {
        accessorKey: 'title_1',
        header: 'Title',
        size: 100,
      },
      {
        accessorKey: 'outlinks',
        header: 'Outlinks',
        size: 10,
      },
      {
        accessorKey: 'inlinks',
        header: 'Inlinks',
        size: 10,
      },
    ],
    [],
  )
  return (
    <ThemeProvider theme={theme}>
      <Paper
        sx={{
          maxWidth: 1400,
          width: '100%',
          overflow: 'hidden',
          marginTop: 10,
          margin: 'auto',
          padding: 10,
        }}
      >
        <Title>URL List</Title>
        
        <MaterialReactTable
          columns={columns}
          data={data?.urllist || []}
          enableEditing
          renderRowActions={({ row }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <a
                href={`${
                  data?.urllist[row.index].address
                }`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  marginLeft: '0.5em',
                  marginRight: '0.5em',
                }}
              >
                <OpenInNewIcon fontSize="small" />
              </a>
            </Box>
          )}
          muiToolbarAlertBannerProps={
            error
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }
          state={{
            isLoading: loading,
          }}
          
        />
      </Paper>
    </ThemeProvider>
  )
}
export default UrlList
