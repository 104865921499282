import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
);

export interface BarChartProps {
    data: number[];
    labels: string[];
    title: string;
    yAxisMax?: number;
    backgroundColor: string | string[];
}

const BarChart: React.FC<BarChartProps> = ({ data, labels, title, yAxisMax, backgroundColor }) => {
  const [options, setOptions] = React.useState<any>({})

  useEffect(() => {
    setOptions({
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: title,
        },
      },
      scales: {
        y: {
            suggestedMin: 0,
            suggestedMax: yAxisMax || Math.max(...data) * 10,
        }
      }
    })
  }, [data, labels, title, yAxisMax, backgroundColor])

  return <Bar 
    options={options} 
    data={{
      labels,
      datasets: [
        {
          data,
          backgroundColor,
        }
      ],
    }}
  />;
}

export default BarChart;
