import { gql, useQuery } from '@apollo/client'
import ErrorMessage from '@components/ui/ErrorMessage'
import Loading from '@components/ui/Loading'
import { getUrlPath } from '@lib/utils/url'
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import React, { useEffect, useState } from 'react'
import {
  BetweennessCentrality,
  LinkAnalysisFilterInput,
} from '../../../../../common/api-types'
import LeakyPagesProfileGraph from './LeakyPagesProfileGraph'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'

const GET_DATA = gql`
  query BetweennessCentrality($where: LinkAnalysisFilterInput) {
    betweennessCentrality(where: $where) {
      address
      betweenness_score
      outbound_count
      links {
        address
        anchor
        status_code
        link_position
      }
    }
  }
`

const InfluentialPage = () => {
  const [cytokey, setCytokey] = useState(0)
  const [rValue, setOValue] = useState('Betweenness')
  const [filterValue, setFilterValue] =
    useState<LinkAnalysisFilterInput>({
      score_type: 'betweenness_score_content',
      link_position: 'Content',
    })
  const reports = ['Betweenness', 'PageRank']
  const nodeFilter = [
    {
      value: 'Content',
      label: 'Content',
    },
    {
      value: '',
      label: 'All',
    },
  ]
  const handleRValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOValue((event.target as HTMLInputElement).value)
  }
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const link_position = (event.target as HTMLInputElement).value
    console.log(link_position)

    switch (rValue) {
      case 'Betweenness':
        setFilterValue({
          score_type: link_position ? 'betweenness_score_content' : 'betweenness_score_all',
          link_position: link_position,
        })
        break
      case 'PageRank':
        setFilterValue({
          score_type: link_position ? 'pagerank_score_content' : 'pagerank_score_all',
          link_position: link_position,
        })
        break
    }
  }
  useEffect(() => {
    switch (rValue) {
      case 'Betweenness':
        setFilterValue({
          score_type: filterValue.link_position
            ? 'betweenness_score_content'
            : 'betweenness_score_all',
          link_position: filterValue.link_position,
        })
        break
      case 'PageRank':
        setFilterValue({
          score_type: filterValue.link_position
            ? 'pagerank_score_content'
            : 'pagerank_score_all',
          link_position: filterValue.link_position,
        })
        break
    }
  }, [rValue])
  useEffect(() => {
    setCytokey(cytokey + 1)

    console.log(filterValue)
  }, [filterValue, rValue])
  const { loading, error, data } = useQuery(GET_DATA, {
    variables: {
      where: {
        score_type: filterValue.score_type,
        link_position: filterValue.link_position,
      },
    },
  })
  const rankingData: BetweennessCentrality[] = data?.betweennessCentrality || []

  useEffect(() => {
    setSelectedAddress(data?.betweennessCentrality[0]?.address)
    setSelectedData(data?.betweennessCentrality[0])
  }, [data])
  const [selectedData, setSelectedData] =
    useState<BetweennessCentrality | null>(null)
  const [selectedAddress, setSelectedAddress] = useState(
    rankingData.length > 0 ? rankingData[0].address : '',
  )
  if (error) return <ErrorMessage error={error} />
  if (loading) return <Loading />

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    address: string,
    data: BetweennessCentrality,
  ) => {
    setSelectedAddress(address)
    setSelectedData(data)
  }

  return (
    <div>
      <h2>Influential Pages</h2>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          m: 3,
        }}
      >
        <FormControl>
          <FormLabel id="orientation-radio-buttons-group">Report</FormLabel>
          <RadioGroup
            aria-labelledby="orientation-radio-buttons-group"
            name="orientation-buttons-group"
            value={rValue}
            onChange={handleRValueChange}
          >
            {reports.map((report) => (
              <FormControlLabel
                key={report}
                value={report}
                control={<Radio />}
                label={report}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel id="filter-radio-buttons-group">Filter Position</FormLabel>
          <RadioGroup
            aria-labelledby="filter-radio-buttons-group"
            name="filter-buttons-group"
            value={filterValue.link_position}
            onChange={handleFilterChange}
          >
            {nodeFilter.map((node, index) => (
              <FormControlLabel
                key={index}
                value={node.value}
                control={<Radio />}
                label={node.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <div className="border-b-2 border-gray-300 py-4">
        <div className="grid grid-cols-1 md:grid-cols-3">
          <div className="overflow-y-scroll" style={{ maxHeight: '800px' }}>
            <List className="py-0" style={{ padding: 0 }}>
              {rankingData?.map((data) => {
                const icon =
                  selectedAddress === data.address ? (
                    <RadioButtonChecked />
                  ) : (
                    <RadioButtonUnchecked />
                  )
                return (
                  <ListItem
                    key={data.address}
                    className="py-1"
                    button
                    selected={selectedAddress === data.address}
                    onClick={(event) =>
                      handleListItemClick(event, data.address, data)
                    }
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={`${getUrlPath(data.address)}`} />
                  </ListItem>
                )
              })}
            </List>
          </div>

          <div className="md:col-span-2" style={{ maxHeight: '800px' }}>
            {selectedData && (
              <LeakyPagesProfileGraph
                key={cytokey}
                address={selectedAddress}
                data={selectedData.links}
                cheiScore={selectedData.betweenness_score}
                outboundCount={selectedData.outbound_count}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default InfluentialPage
