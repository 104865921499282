import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/link-context'
import { Auth0Provider, useAuth0, AppState } from '@auth0/auth0-react'
import Loading from '@components/ui/Loading'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

const onRedirectCallback: any = (appState: AppState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname)
}

const AuthorizedApolloProvider = ({ children }: { children: any }) => {
  const { getIdTokenClaims, isLoading, error } = useAuth0()

  console.log('REACT_APP_AUTH0_DOMAIN', process.env.REACT_APP_AUTH0_DOMAIN)
  console.log('REACT_APP_MARKER_IO_PROJECT_ID', process.env.REACT_APP_MARKER_IO_PROJECT_ID)

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI || '/graphql',
  })

  const authLink = setContext(async () => {
    const idToken: any = await getIdTokenClaims()
    console.log('Got idToken', idToken)
    return {
      headers: {
        Authorization: `Bearer ${idToken.__raw}`,
      },
    }
  })

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  })

  if (isLoading) {
    return <Loading></Loading>
  }
  if (error) {
    return <div>Oops... {error.message}</div>
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

const Main = () => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN!}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
      scope:
        'openid email tenant read:current_user update:current_user_metadata',
    }}
    onRedirectCallback={onRedirectCallback}
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    <AuthorizedApolloProvider>
      <App />
    </AuthorizedApolloProvider>
  </Auth0Provider>
)

ReactDOM.render(<Main />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
