import { Card, Typography } from '@mui/material'
import React from 'react'

export interface CountCardProps {
  value: number
  title: string
  color?: string
}

const CountCard: React.FC<CountCardProps> = ({ title, value, color = '' }) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: 2,
        flex: 1,
        backgroundColor: color,
      }}
      variant="elevation"
    >
      <Typography variant="h5" component="h2">
        {title}
      </Typography>
      <Typography variant="h3" component="h2">
        {value || 0}
      </Typography>
    </Card>
  )
}

export default CountCard