import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Button } from '@mui/material'
import ChatbotGraph from './ChatbotGraph'

const style = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  gap: 2,
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'content-fit',
  maxWidth: '90vw',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  outline: 'none',
  overflow: 'auto',
}

interface GraphbotVisualizationModalProps {
  data: any
}

const GraphbotVisualizationModal: React.FC<GraphbotVisualizationModalProps> = ({ data }) => {
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Button fullWidth={true} onClick={handleOpen} variant='contained' color='primary'>
        View Graph Visualization
      </Button>
      <Modal sx={{ zIndex: 999999 }} open={open} onClose={handleClose} aria-labelledby="modal-modal-title">
        <Box sx={style}>
          <Typography
            sx={{
              textTransform: 'uppercase',
            }}
            id="modal-modal-title"
            variant="h5"
            component="h2"
          >
            Graph Visualization
          </Typography>
          <ChatbotGraph data={data} />
        </Box>
      </Modal>
    </div>
  )
}

export default GraphbotVisualizationModal
