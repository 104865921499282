import { getUrlPath } from '@lib/utils/url'
import Cytoscape from 'cytoscape'
import cola from 'cytoscape-cola'
import CytoscapeComponent from 'react-cytoscapejs'
import { UrlData } from '../../../../common/api-types'
import { colours } from './Colour'
import { statusCodeColor } from './Redirects-functions'
Cytoscape.use(cola)

interface ChainGraphProps {
  data: UrlData[]
}

function generateGraphElements(data: UrlData[]) {
  const elements: any = []

  elements.push({
    data: {
      id: 'source',
      label: 'Source',
      bgColor: colours.green,
    },
  })
  let chainId = 'source'
  data.forEach((urlData) => {
    elements.push({
      data: {
        id: urlData.address,
        label: getUrlPath(urlData.address),
        bgColor: statusCodeColor(urlData.status_code),
      },
    })
    elements.push({
      data: {
        id: `${urlData.address}-edge`,
        source: chainId,
        target: urlData.address,
        label: `(${urlData.status_code.toString()})`,
      },
    })
    chainId = urlData.address
  })
  return elements
}
export default function ChainGraph(props: ChainGraphProps) {
  const elements = generateGraphElements(props.data)

  const layout = {
    name: 'grid',
    rows: 1,
    spacingFactor: 0.5,
    padding: 2,
  }
  return (
    <CytoscapeComponent
      elements={elements}
      style={{
        minWidth: '500px',
        minHeight: '200px',
        width: '100%',
        height: '100%',
        padding: '10px',
        border: '2px solid #e3e3e3',
      }}
      stylesheet={[
        {
          selector: 'node',
          style: {
            width: 20,
            height: 20,
            label: 'data(label)',
            'font-size': '5rem',
            'background-color': 'data(bgColor)',
          },
        },
        {
          selector: 'edge',
          style: {
            width: 1,
            label: 'data(label)',
            'font-size': '3rem',
            'font-style': 'italic',
            'target-arrow-shape': 'triangle-backcurve',
            'target-arrow-color': '#666',
            'curve-style': 'bezier',
            'text-rotation': 'autorotate',
            'text-margin-y': -2,
            'text-margin-x': 0,
          },
        },
      ]}
      layout={layout}
      userZoomingEnabled={false}
    />
  )
}
