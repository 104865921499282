import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import LinkProfileGraph from '../LinkProfileGraph'
import { UrlCommunitySummary } from '../../../../../common/api-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material'
import ErrorMessage from '@components/ui/ErrorMessage'
import { getUrlPath } from '@lib/utils/url'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'

const GET_TOP_COMMUNITIES_QUERY = gql`
  query TopURLCommunities(
    $first: Int
    $offset: Int
    $where: SimilarityDirection
  ) {
    topURLCommunities(first: $first, offset: $offset, where: $where) {
      addresses
      count
      lpa
    }
  }
`

const CommunityReport = ({ community, direction, filter }: { community: UrlCommunitySummary, direction: string, filter: string } ) => {
  const { addresses } = community
  const [selectedAddress, setSelectedAddress] = useState(addresses[0])

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    address: string,
  ) => {
    setSelectedAddress(address)
    console.log(address)
  }
  return (
    <div className="border-b-2 border-gray-300 py-4">
      <h3>
        Community {community.lpa}
        <span className="text-base text-gray-500 pl-2">
          ({community.count} URLs)
        </span>
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-3">
        <div className="overflow-y-scroll" style={{ maxHeight: '800px' }}>
          <List className="py-0" style={{ padding: 0 }}>
            {addresses.map((a) => {
              const icon =
                selectedAddress === a ? (
                  <RadioButtonChecked />
                ) : (
                  <RadioButtonUnchecked />
                )
              return (
                <ListItem
                  key={a}
                  className="py-1"
                  button
                  selected={selectedAddress === a}
                  onClick={(event) => handleListItemClick(event, a)}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={getUrlPath(a)} />
                </ListItem>
              )
            })}
          </List>
        </div>
        <div className="md:col-span-2" style={{ maxHeight: '800px' }}>
          <LinkProfileGraph key={selectedAddress} address={selectedAddress} direction={direction} filter={filter}/>
        </div>
      </div>
    </div>
  )
}

const TopUrlCommunities = () => {
  const [oValue, setOValue] = useState('undirected')
  const [filterValue, setFilterValue] = useState('all')
  const orientations = ['undirected', 'reverse']
  const nodeFilter = ['all','inbound', 'outbound']
  const handleOrientationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOValue((event.target as HTMLInputElement).value)
  }
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue((event.target as HTMLInputElement).value)
  }
  const { loading, error, data } = useQuery(GET_TOP_COMMUNITIES_QUERY, {
    variables: {
      first: 5,
      offset: 0,
      where: {
        direction: oValue,
      },
    },
  })
  if (error) return <ErrorMessage error={error} />
  if (loading) return <p>Loading</p>
  const communities: UrlCommunitySummary[] = data.topURLCommunities

  console.log(communities)
  return (
    <div>
      <h2>Similarly Linked Pages</h2>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        m: 3,
    
      }}>
        <FormControl>
        <FormLabel id="orientation-radio-buttons-group">Orientation</FormLabel>
          <RadioGroup
            aria-labelledby="orientation-radio-buttons-group"
            name="orientation-buttons-group"
            value={oValue}
            onChange={handleOrientationChange}
          >
            {orientations.map((orientation) => (
              <FormControlLabel
                key={orientation}
                value={orientation}
                control={<Radio />}
                label={orientation === 'reverse' ? `Similarity (directed)` : `Similarity (${orientation}})`}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <FormControl>
        <FormLabel id="filter-radio-buttons-group">Filter</FormLabel>
          <RadioGroup
            aria-labelledby="filter-radio-buttons-group"
            name="filter-buttons-group"
            value={filterValue}
            onChange={handleFilterChange}
          >
            {nodeFilter.map((node) => (
              <FormControlLabel
                key={node}
                value={node}
                control={<Radio />}
                label={node}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      {communities.map((community) => (
        <CommunityReport key={community.lpa} community={community} direction={oValue} filter={filterValue}/>
      ))}
    </div>
  )
}

export default TopUrlCommunities
