export default class TableUtils {
  static convertFiltersToObj = (filters: Array<any>) => {
    // convert filters to object
    const filtersObj = filters.reduce((acc, filter) => {
      // Convert filter value to correct type
      const filterValue =
        filter.value === 'true'
          ? true
          : filter.value === 'false'
          ? false
          : filter.value === 'null'
          ? null
          : isNaN(filter.value)
          ? filter.value
          : Number(filter.value)
  
      acc[filter.id] = filterValue;
      return acc
    }, {})
  
    return filtersObj
  }
}