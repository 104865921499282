import { gql, useQuery } from '@apollo/client'
import ErrorMessage from '@components/ui/ErrorMessage'
import Loading from '@components/ui/Loading'
import {
  Autocomplete,
  Box,
  Button,
  Paper,
  TextField,
  ThemeProvider
} from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import RedirectsProfileGraph from './RedirectsProfileGraph'

const theme = createTheme()

const GET_RANGES = gql`
  query GetRanges {
    ga4DatesRanges {
      start_date
      end_date
    }
    latestCrawlNumber
  }
`

export default function RedirectsVisualisation() {
  const { loading, error, data } = useQuery(GET_RANGES)

  const [oldValue, setOldValue] = useState('')
  const [apply, setApply] = useState(false)
  const [newValue, setNewValue] = useState('')
  const [oldDR, setOldDR] = useState('')
  const [newDR, setNewDR] = useState('')

  const [oldSD, oldED] = oldDR.split(' - ')
  const [newSD, newED] = newDR.split(' - ')

  const handleApply = () => {
    setApply(true)
  }
  useEffect(() => {
    if (!(oldDR && newDR && oldValue && newValue)) {
      setApply(false)
    }
  }, [oldDR, newDR, oldValue, newValue])

  if (error) return <ErrorMessage error={error} />
  if (loading) return <Loading />

  const crawls = Array.from({ length: data?.latestCrawlNumber }, (_, index) =>
    (index + 1).toString(),
  )
  const dateRanges: any = []

  data?.ga4DatesRanges.map((date: any) => {
    dateRanges.push(date.start_date + ' - ' + date.end_date)
  })

  return (
    <ThemeProvider theme={theme}>
      <Paper>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            p: 2,
            width: '100%',
          }}
        >
          <Autocomplete
            disablePortal
            id="oldCrawl"
            value={oldValue?.toString()}
            onChange={(e, n) => {
              setOldValue(n ? n : '')
            }}
            options={crawls}
            getOptionDisabled={(option) => option === newValue?.toString()}
            sx={{ width: 300, pr: 2 }}
            renderInput={(params) => <TextField {...params} label="Crawl" />}
          />

          <Autocomplete
            disablePortal
            id="newCrawl"
            value={newValue?.toString()}
            onChange={(e, n) => {
              setNewValue(n ? n : '')
            }}
            options={crawls}
            getOptionDisabled={(option) => option === oldValue?.toString()}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Crawl" />}
          />
          {oldDR && newDR && oldValue && newValue ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleApply}
              sx={{ marginLeft: 'auto' }}
            >
              Apply
            </Button>
          ) : null}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            p: 2,
          }}
        >
          <Autocomplete
            disablePortal
            id="oldDR"
            value={oldDR}
            onChange={(e, n) => {
              setOldDR(n ? n : '')
            }}
            options={dateRanges}
            getOptionDisabled={(option) => option === newDR}
            sx={{ width: 300, pr: 2 }}
            renderInput={(params) => (
              <TextField {...params} label="Date Range" />
            )}
          />
          <Autocomplete
            disablePortal
            id="newDR"
            value={newDR}
            onChange={(e, n) => {
              setNewDR(n ? n : '')
            }}
            options={dateRanges}
            getOptionDisabled={(option) => option === oldDR}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Date Range" />
            )}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            height: '850px',
            p: 2,
          }}
        >
          {apply ? (
            <RedirectsProfileGraph
              oldCrawl={parseInt(oldValue)}
              oldStartDate={oldSD}
              oldEndDate={oldED}
              newCrawl={parseInt(newValue)}
              newStartDate={newSD}
              newEndDate={newED}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Select Data to Compare
            </Box>
          )}
        </Box>
      </Paper>
    </ThemeProvider>
  )
}
