import React from 'react'
import { Link } from 'react-router-dom'
import { Typography, Paper, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

const CardLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
}))

const CardTitle = styled('div')(({ theme }) => ({
  fontSize: '0.92rem',
  whiteSpace: 'nowrap',
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  marginTop: '12px',
}))

interface CardProps {
  loading: boolean
  title: string
  rerult: string
  link: string
}


export default function CrawlReport(props: CardProps) {
  const link = `/crawl-report/${props.link}`

  return (
    <React.Fragment>
      <Grid item md={2} className="grid-elements">
        <StyledPaper>
          <CardLink to={link}>
            <CardTitle>{props.title}</CardTitle>
            {props.loading ? (
              <Typography variant="h6">Loading...</Typography>
            ) : (
              <Typography variant="h4">{props.rerult}</Typography>
            )}
          </CardLink>
        </StyledPaper>
      </Grid>
    </React.Fragment>
  )
}
