import {decode as decodeHTML} from 'html-entities';

export function decodeHtml(html: string): string {
    const decoded = decodeHTML(html);
    const replaced = decoded.replace(/<br\s*\/?>/gi, '\n')
                             .replace(/<hr\s*\/?>/gi, '\n\n')
                             .replace(/<p\s*\/?>/gi, '\n\n')
                             .replace(/<[^>]+>/gi, '');
    return replaced;
}
