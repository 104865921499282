import { gql, useQuery } from '@apollo/client'
import ErrorMessage from '@components/ui/ErrorMessage'
import Loading from '@components/ui/Loading'
import { getUrlPath } from '@lib/utils/url'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Popover,
  Radio,
  RadioGroup,
} from '@mui/material'
import cytoscape from 'cytoscape'
import cola from 'cytoscape-cola'
import { useEffect, useState } from 'react'
import CytoscapeComponent from 'react-cytoscapejs'
import { RedirUrls } from '../../../../common/api-types'
import { colours } from './Colour'
import FilterComponent from './FilterComponent'
import {
  calculateCosineSimilarity,
  colorCode,
  getNodeSize,
  pageViewsSizeup,
  inlinkSizeup,
  new_colorCode,
} from './Redirects-functions'
import URLInfoModal from './URLInfoModal'

cytoscape.use(cola)

type PopOverContent = {
  url: string
  engaged_sessions: number
  engagement_duration: number
  engagement_rate: number
  page_views: number
  inlinksCount: number
  old_status_code?: number
  new_status_code?: number
  type?: string
}

export interface RedirectsProfileGraphProps {
  oldCrawl: number
  newCrawl: number
  oldStartDate: string
  newStartDate: string
  oldEndDate: string
  newEndDate: string
}

const GET_DATA = gql`
  query GetData($where: RedirectedURLsDataInput) {
    redirectedURLsData(where: $where) {
      bounce_rate
      end_date
      engaged_sessions
      engagement_duration
      engagement_rate
      new_address
      inlinksCount
      new_status_code
      content
      page_views
      old {
        bounce_rate
        engaged_sessions
        end_date
        engagement_duration
        engagement_rate
        new_status_code
        old
        inlinksCount
        old_status_code
        content
        page_views
        sessions
        start_date
      }
      sessions
      start_date
    }
    pageNotFound(where: $where) {
      address
      bounce_rate
      end_date
      engaged_sessions
      engagement_duration
      engagement_rate
      page_views
      sessions
      start_date
      status_code
      type
    }
  }
`

const GET_URL_DATA = gql`
  query RedirUrls(
    $where: RedirectsUrlsFilterInput
    $options: PaginationOptionsInput
  ) {
    redirUrls(where: $where, options: $options) {
      final_destination
      final_status_code
      redirected_address
      redirected_status_code
      redirect_chain {
        address
        status_code
        anchor
      }
      ref_count
      references {
        address
        status_code
        anchor
      }
    }
  }
`
const handleFilter = (filter: string, data: any) => {
  switch (filter) {
    case 'traffic':
      return getNodeSize(data)
    case 'internalLinks':
      return inlinkSizeup(data)
    case 'pageViews':
      return  pageViewsSizeup(data)
    default:
      return getNodeSize(data)
  }
}

let nodeCount = {
  greenCount: 0,
  yellowCount: 0,
  redCount: 0,
  allCount: 0,
}
const parents = [
  {
    data: { id: 'parent-1', label: 'New URLs' },
  },
  {
    data: { id: 'parent-2', label: 'Old URLs' },
  },
]
const generateGraphElements = (data: any, filter: string) => {
  nodeCount = {
    greenCount: 0,
    yellowCount: 0,
    redCount: 0,
    allCount: 0,
  }

  const elements: any = []

  parents.flatMap((parent) => elements.push(parent))
  data?.pageNotFound.forEach((url: any) => {
    const { height, width } = handleFilter(filter, url)
    elements.push({
      data: {
        id: url.address,
        label: getUrlPath(url.address),
        parent: 'parent-2',
        bgColor: colours.red,
        height: height,
        width: width,
        additionalData: {
          engaged_sessions: url.engaged_sessions,
          engagement_duration: url.engagement_duration,
          engagement_rate: url.engagement_rate,
          page_views: url.page_views,
          type: url.type,
          status_code: url.status_code,
        },
      },
    })
    nodeCount.redCount++
    nodeCount.allCount++
  })

  data?.redirectedURLsData.forEach((url: any) => {
    const { height, width } = handleFilter(filter, url)

    elements.push({
      data: {
        id: url.new_address,
        label: getUrlPath(url.new_address),
        parent: 'parent-1',
        bgColor: new_colorCode(url.old.length),
        height: height,
        width: width,
        additionalData: {
          engaged_sessions: url.engaged_sessions,
          engagement_duration: url.engagement_duration,
          engagement_rate: url.engagement_rate,
          page_views: url.page_views,
          inlinksCount: url.inlinksCount,
          new_status_code: url.new_status_code,
        },
      },
    })

    if (url.old.length > 1) {
      const id = `sub-parent-${url.new_address}`
      elements.push({
        data: {
          id: id,
          label: '',
          parent: 'parent-2',
          bgColor: colours.grey,
        },
      })
      url?.old?.forEach((oldUrl: any) => {
        const { height, width } = handleFilter(filter, oldUrl)
        const color = colorCode(
          calculateCosineSimilarity(url.content, oldUrl.content),
          url.new_status_code,
        )
        const { height: refHeight, width: refWidth } = inlinkSizeup(oldUrl)

        elements.push({
          data: {
            id: oldUrl.old,
            label: getUrlPath(oldUrl.old),
            parent: id,
            bgColor: color,
            height: height,
            width: width,
            additionalData: {
              engaged_sessions: oldUrl.engaged_sessions,
              engagement_duration: oldUrl.engagement_duration,
              engagement_rate: oldUrl.engagement_rate,
              page_views: oldUrl.page_views,
              inlinksCount: oldUrl.inlinksCount,
              old_status_code: oldUrl.old_status_code,
              new_status_code: oldUrl.new_status_code,
            },
          },
        })

        elements.push({
          data: {
            id: `${oldUrl.old}-${url.new_address}`,
            label: url.new_status_code.toString(),
            source: oldUrl.old,
            target: url.new_address,
            bgColor: color,
          },
        })

        elements.push({
          data: {
            id: `ref|${oldUrl.old}`,
            label: '',
            bgColor: colours.grey,
            height: refHeight,
            width: refWidth,
          },
        })

        elements.push({
          data: {
            id: `ref|${oldUrl.old}-${url.new_address}`,
            label: '',
            source: `ref|${oldUrl.old}`,
            target: oldUrl.old,
            bgColor: colours.grey,
          },
        })

        if (color && color.length > 0) {
          nodeCount.allCount++
        }

        switch (color) {
          case colours.dundee_green:
            nodeCount.greenCount++
            break
          case colours.yellow:
            nodeCount.yellowCount++
            break
          case colours.red:
            nodeCount.redCount++
            break
          default:
            break
        }
      })
    } else {
      url?.old?.forEach((oldUrl: any) => {
        const { height, width } = handleFilter(filter, oldUrl)
        const color = colorCode(
          calculateCosineSimilarity(url.content, oldUrl.content),
          url.new_status_code,
        )
        const { height: refHeight, width: refWidth } = inlinkSizeup(oldUrl)
        elements.push({
          data: {
            id: oldUrl.old,
            label: getUrlPath(oldUrl.old),
            parent: 'parent-2',
            bgColor: color,
            height: height,
            width: width,
            additionalData: {
              engaged_sessions: oldUrl.engaged_sessions,
              engagement_duration: oldUrl.engagement_duration,
              engagement_rate: oldUrl.engagement_rate,
              page_views: oldUrl.page_views,
              inlinksCount: oldUrl.inlinksCount,
              old_status_code: oldUrl.old_status_code,
              new_status_code: oldUrl.new_status_code,
            },
          },
        })
        elements.push({
          data: {
            id: `${oldUrl.old}-${url.new_address}`,
            label: url.new_status_code.toString(),
            source: oldUrl.old,
            target: url.new_address,
            bgColor: color,
          },
        })

        elements.push({
          data: {
            id: `ref|${oldUrl.old}`,
            label: '',
            bgColor: colours.grey,
            height: refHeight,
            width: refWidth,
          },
        })

        elements.push({
          data: {
            id: `ref|${oldUrl.old}-${url.new_address}`,
            label: '',
            source: `ref|${oldUrl.old}`,
            target: oldUrl.old,
            bgColor: colours.grey,
          },
        })

        if (color && color.length > 0) {
          nodeCount.allCount++
        }

        switch (color) {
          case colours.dundee_green:
            nodeCount.greenCount++
            break
          case colours.yellow:
            nodeCount.yellowCount++
            break
          case colours.red:
            nodeCount.redCount++
            break
          default:
            break
        }
      })
    }
  })
  return elements
}

export default function RedirectsProfileGraph(
  props: RedirectsProfileGraphProps,
) {
  let m: any = []
  const [modalKey, setModalKey] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState<RedirUrls | null>(null)
  const handleCloseModal = () => {
    setModalData(null)
    setIsModalOpen(false)
  }
  const handleRowClick = (row: RedirUrls) => {
    setModalKey(modalKey + 1)
    setModalData(row)
  }

  const [selectedAddress, setSelectedAddress] = useState<string>('yeehaw')
  const {
    data: response,
  } = useQuery(GET_URL_DATA, {
    variables: {
      where: {
        redirected_address: selectedAddress,
      },
      options: {
        limit: 1,
        offset: 0,
      },
    },
  })

  useEffect(() => {
    if (response) {
      
      handleRowClick(response?.redirUrls?.[0])
    }
  }, [response, selectedAddress, isModalOpen])
  const { loading, error, data } = useQuery(GET_DATA, {
    variables: {
      where: {
        new_crawl_num: props.newCrawl,
        new_end_date: props.newEndDate,
        new_start_date: props.newStartDate,
        old_crawl_num: props.oldCrawl,
        old_end_date: props.oldEndDate,
        old_start_date: props.oldStartDate,
      },
    },
  })
  const [anchorEl, setAnchorEl] = useState(false)
  const [popoverContent, setPopoverContent] = useState<PopOverContent | null>(
    null,
  )
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const handleNodeClick = (event: any) => {
    if (event.handled) {
      return
    }
    event.handled = true
    const targetNode = event.target
    if (targetNode.isNode()) {
      const nodeId = event.target.data('id')
      const nodeData = event.target.data('additionalData')
      const containerPosition = event.target.renderedPosition()
      const containerOffset = event.target
        .cy()
        .container()
        .getBoundingClientRect()
      const x = containerPosition.x + containerOffset.left
      const y = containerPosition.y + containerOffset.top

      setPosition({ x, y })
      setPopoverContent({
        url: nodeId,
        engaged_sessions: nodeData.engaged_sessions,
        engagement_duration: nodeData.engagement_duration,
        engagement_rate: nodeData.engagement_rate,
        page_views: nodeData.page_views,
        inlinksCount: nodeData.inlinksCount,
        old_status_code: nodeData.old_status_code
          ? nodeData.old_status_code
          : null,
        new_status_code: nodeData.new_status_code
          ? nodeData.new_status_code
          : nodeData.status_code,
        type: nodeData.type ? nodeData.type : null,
      })
      setAnchorEl(true)

      event.stopPropagation()
    }
  }

  const handleClosePopover = () => {
    setAnchorEl(false)
  }

  const attachEventListeners = (cy: any) => {
    cy.on('tap', 'node', (event: any) => {
      if (event.target.id().includes('ref|')) {
        setSelectedAddress(event.target.id().replace('ref|', ''))
        setIsModalOpen(true)
      }
      if (
        !event.target.id().includes('parent-') &&
        !event.target.id().includes('ref|')
      ) {
        handleNodeClick(event)
      }
    })
  }

  const [sizeUpValue, setSizeUpValue] = useState<string>('traffic')
  const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSizeUpValue((event.target as HTMLInputElement).value)
  }
  const elements = generateGraphElements(data, sizeUpValue)

  const [cytoscapeKey, setCytoscapeKey] = useState(0)

  const [filterValue, setFilterValue] = useState('all')

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    m = []
    setFilterValue((event.target as HTMLInputElement).value)
    setCytoscapeKey(cytoscapeKey + 1)
  }

  const filterElements = (filter: string) => {
    const filteredElements: any = []
    if (filter === 'red') {
      parents.forEach((parent) => {
        if (parent.data.id === 'parent-2') {
          filteredElements.push(parent)
        }
      })
      elements.forEach((element: any) => {
        if (
          element.data.bgColor === colours.red &&
          element.data.parent === 'parent-2'
        ) {
          filteredElements.push(element)
        }
      })
      return filteredElements
    }
    parents.forEach((parent) => filteredElements.push(parent))
    if (filter === 'all') {
      return elements
    }
    if (filter === 'green') {
      elements.forEach((element: any) => {
        if (element.data.bgColor === colours.dundee_green) {
          if (element.data.parent?.includes('sub-parent-')) {
            m.push(element)
          } else {
            filteredElements.push(element)
          }
        }
        if (element.data.source === element.data.id) {
          filteredElements.push(element)
        }
      })
      if (m.length > 1) {
        m.forEach((element1: any) => {
          filteredElements.push(element1)
        })
      } else {
        m.forEach((element1: any) => {
          element1.data.parent = 'parent-2'
          filteredElements.push(element1)
        })
      }

      filteredElements.forEach((element: any) => {
        elements.forEach((targetElement: any) => {
          if (element.data?.target === targetElement.data.id) {
            filteredElements.push(targetElement)
          }
          if (
            targetElement.data.id.includes('ref|') &&
            targetElement.data.id.includes(element.data.id)
          ) {
            filteredElements.push(targetElement)
          }
        })
      })
      return filteredElements
    }
    if (filter === 'yellow') {
      elements.forEach((element: any) => {
        if (element.data.bgColor === colours.yellow) {
          if (element.data.parent?.includes('sub-parent-')) {
            m.push(element)
          }
          filteredElements.push(element)
        }
        if (element.data.source === element.data.id) {
          filteredElements.push(element)
        }
        if (
          element.data.bgColor === colours.grey &&
          element.data.parent === 'parent-2'
        ) {
          filteredElements.push(element)
        }
      })
      if (m.length > 1) {
        m.forEach((element1: any) => {
          filteredElements.push(element1)
        })
      } else {
        m.forEach((element1: any) => {
          element1.data.parent = 'parent-2'
          filteredElements.push(element1)
        })
      }

      filteredElements.forEach((element: any) => {
        elements.forEach((targetElement: any) => {
          if (element.data?.target === targetElement.data.id) {
            filteredElements.push(targetElement)
          }
          if (element.data?.parent === targetElement.data.id) {
            filteredElements.push(targetElement)
          }
          if (
            targetElement.data.id.includes('ref|') &&
            targetElement.data.id.includes(element.data.id)
          ) {
            filteredElements.push(targetElement)
          }
        })
      })
      return filteredElements
    }
  }

  if (error) return <ErrorMessage error={error} />
  if (loading) return <Loading />

  const layout = {
    name: 'cola',
    animate: true,
    animationDuration: 10,
    edgeLength: 300,
    nodeSpacing: 40,
    padding: 2,
}

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'left',
          flexDirection: 'column',
          width: '20%',
        }}
      >
        <FormControl
          sx={{
            pb: 2,
          }}
        >
          <FormLabel id="sizeUp-group">Size Up:</FormLabel>
          <RadioGroup
            aria-labelledby="sizeUp-group"
            value={sizeUpValue}
            onChange={handleSizeChange}
            name="sizeUp-group"
          >
            <FormControlLabel
              value="internalLinks"
              control={<Radio />}
              label="Internal Links"
            />
            <FormControlLabel
              value="traffic"
              control={<Radio />}
              label="Metrics"
            />
            <FormControlLabel
              value="pageViews"
              control={<Radio />}
              label="Traffic"
            />
          </RadioGroup>
        </FormControl>

        <FilterComponent
          allCount={nodeCount.allCount}
          greenCount={nodeCount.greenCount}
          yellowCount={nodeCount.yellowCount}
          redCount={nodeCount.redCount}
          filterValue={filterValue}
          setFilterValue={handleFilterChange}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #ccc',
          width: '80%',
          height: '840',
          justifyContent: 'center',
        }}
      >
        <CytoscapeComponent
          key={cytoscapeKey}
          elements={filterElements(filterValue)}
          style={{ width: '100%', height: '100%' }}
          stylesheet={[
            {
              selector: 'node[label][height][width]',
              style: {
                width: 'data(width)',
                height: 'data(height)',
                label: 'data(label)',
                'text-wrap': 'ellipsis',
                'text-max-width': '8em',
                'background-color': 'data(bgColor)',
              },
            },
            {
              selector: 'node#parent-1',
              style: {
                backgroundColor: colours.green,
                label: 'data(label)',
                'background-opacity': 0.5,
                'font-size': '50rem',
              },
            },
            {
              selector: 'node#parent-2',
              style: {
                backgroundColor: colours.grey,
                label: 'data(label)',
                'background-opacity': 0.5,
                'font-size': '50rem',
              },
            },

            {
              selector: 'node[id^="sub-parent-"]',
              style: {
                backgroundColor: colours.grey,
                label: 'data(label)',
                'background-opacity': 0.5,
                'font-size': '50rem',
              },
            },

            {
              selector: 'edge',
              style: {
                width: 1,
                label: 'data(label)',
                'font-size': '10rem',
                'font-style': 'italic',
                'target-arrow-shape': 'triangle-backcurve',
                'curve-style': 'bezier',
                'text-rotation': 'autorotate',
                'text-margin-y': -2,
                'text-margin-x': 0,
                'background-color': 'data(bgColor)',
                'line-color': colours.black,
              },
            },
          ]}
          layout={layout}
          cy={(cy) => {
            attachEventListeners(cy)
          }}
        />
        <Popover
          open={!!anchorEl}
          anchorReference="anchorPosition"
          anchorPosition={{ top: position.y, left: position.x }}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          sx={{
            opacity: 0.8,
          }}
        >
          {popoverContent && (
            <div style={{ padding: '16px' }}>
              {`URL: ${popoverContent.url}`} <br />
              {popoverContent.type && (
                <span>
                  {`Type: ${popoverContent.type}`} <br />
                </span>
              )}
              {`Engaged Sessions: ${popoverContent.engaged_sessions}`} <br />
              {`Engagement Duration: ${popoverContent.engagement_duration}`}{' '}
              <br />
              {`Engagement Rate: ${popoverContent.engagement_rate}`} <br />
              {`Page Views: ${popoverContent.page_views}`} <br />
              {popoverContent.old_status_code && (
                <span>
                  {`Old Status Code: ${popoverContent.old_status_code}`} <br />
                </span>
              )}
              {`Status Code: ${popoverContent.new_status_code}`}
              <br />
            </div>
          )}
        </Popover>
        {modalData && (
          <URLInfoModal
            key={modalKey}
            open={isModalOpen}
            onClose={handleCloseModal}
            data={modalData}
          />
        )}
      </Box>
    </>
  )
}
