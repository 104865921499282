import * as React from 'react'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector'
import { StepIconProps } from '@mui/material/StepIcon'
import { Button, Link, Typography } from '@mui/material'
import { RedirectChain } from '../../../../common/api-types'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

interface RedirectChainStepperProps {
  chains: RedirectChain[]
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 10,
    border: 0,
    width: 3,
    marginLeft: 10,
    backgroundImage:
      'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    borderRadius: 1,
  },
}))

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean }
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage:
    'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
}))

const RedirectChainStepper: React.FC<RedirectChainStepperProps> = ({
  chains,
}) => {
  const ColorlibStepIcon = (props: StepIconProps) => {
    const { active, completed, className } = props

    const statusCodes = chains.map((chain) => chain.status_code)

    const icons: { [index: string]: React.ReactElement } = statusCodes.reduce(
      (acc, statusCode, index) => {
        acc[String(index + 1)] = <Typography>{statusCode}</Typography>
        return acc
      },
      {} as { [index: string]: React.ReactElement },
    )

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props?.icon)]}
      </ColorlibStepIconRoot>
    )
  }

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper orientation="vertical" connector={<ColorlibConnector />}>
        {chains.map((chain, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <Button
                href={chain?.address || ''}
                target="_blank"
                rel="noreferrer"
                sx={{
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  textTransform: 'none',
                }}
                color="inherit"
                startIcon={<OpenInNewIcon />}
              >
                {chain?.address || ''}
              </Button>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  )
}

export default RedirectChainStepper
