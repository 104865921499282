import React from 'react'
import { useQuery, gql } from '@apollo/client'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  TableSortLabel,
  TextField,
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'

import Title from '@components/ui/Title'

type SortDirection = 'asc' | 'desc'

const theme = createTheme()

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1400,
    marginTop: 10,
    overflowX: 'auto',
    margin: 'auto',
    padding: 10,
  },
  table: {
    minWidth: 700,
  },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    minWidth: 300,
  },
}))

const GET_URL = gql`
  query urlPaginateQuery($options: URLOptions, $where: URLWhere) {
    urls(options: $options, where: $where) {
      address
      status_code
      title_1
      outlinks
      inlinks
      hash
    }
  }
`

type Props = {
  tableTitle: string
  statusQuery: object
}

function CrawlReportTable(props: Props) {
  const [order, setOrder] = React.useState<SortDirection>('desc')
  const [orderBy, setOrderBy] = React.useState('inlinks')
  const [page] = React.useState(0)
  const [rowsPerPage] = React.useState(25)
  const [filterState, setFilterState] = React.useState({ urlFilter: '' })
  const classes = useStyles()

  const getFilter = () => {
    return filterState.urlFilter.length > 0
      ? { address_CONTAINS: filterState.urlFilter.toLowerCase() }
      : {}
  }

  const { loading, data, error } = useQuery(GET_URL, {
    variables: {
      options: {
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        ...(orderBy && {
          sort: { [orderBy]: order ? order.toUpperCase() : 'ASC' },
        }),
      },
      where: {
        ...getFilter(),
        ...props.statusQuery,
      },
    },
  })

  const handleSortRequest = (property: any) => {
    const newOrderBy = property
    let newOrder: SortDirection = 'desc'

    if (orderBy === property && order === 'desc') {
      newOrder = 'asc'
    }

    setOrder(newOrder)
    setOrderBy(newOrderBy)
  }

  const handleFilterChange = (filterName: string) => (event: any) => {
    const val = event.target.value
    setFilterState((oldFilterState) => ({
      ...oldFilterState,
      [filterName]: val,
    }))
  }

  return (
    <ThemeProvider theme={theme}>
      <Paper className={classes.root}>
        <Title>{props.tableTitle}</Title>
        <TextField
          id="search"
          label="URL Contains"
          className={classes.textField}
          value={filterState.urlFilter}
          onChange={handleFilterChange('urlFilter')}
          margin="normal"
          variant="outlined"
          type="text"
        />
        {loading && !error && <p>Loading...</p>}
        {error && !loading && <p>Error</p>}
        {data && !loading && !error && (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell
                  key="address"
                  sortDirection={orderBy === 'address' ? order : false}
                >
                  <Tooltip
                    title="Sort"
                    placement="bottom-start"
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === 'address'}
                      direction={order}
                      onClick={() => handleSortRequest('address')}
                    >
                      Address
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell
                  key="status_code"
                  sortDirection={orderBy === 'status_code' ? order : false}
                >
                  <Tooltip title="Sort" placement="bottom-end" enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === 'status_code'}
                      direction={order}
                      onClick={() => handleSortRequest('status_code')}
                    >
                      Status
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell
                  key="title_1"
                  sortDirection={orderBy === 'title_1' ? order : false}
                >
                  <Tooltip
                    title="Sort"
                    placement="bottom-start"
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === 'title_1'}
                      direction={order}
                      onClick={() => handleSortRequest('title_1')}
                    >
                      Title
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell
                  key="outlinks"
                  sortDirection={orderBy === 'outlinks' ? order : false}
                >
                  <Tooltip
                    title="Sort"
                    placement="bottom-start"
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === 'outlinks'}
                      direction={order}
                      onClick={() => handleSortRequest('outlinks')}
                    >
                      Outlinks
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell
                  key="inlinks"
                  sortDirection={orderBy === 'inlinks' ? order : false}
                >
                  <Tooltip
                    title="Sort"
                    placement="bottom-start"
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === 'inlinks'}
                      direction={order}
                      onClick={() => handleSortRequest('inlinks')}
                    >
                      Inlinks
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.urls.map((n: any) => {
                return (
                  <TableRow key={n.hash || n.address}>
                    <TableCell component="th" scope="row">
                      <Link
                        to={n.address}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {n.address}
                      </Link>
                    </TableCell>
                    <TableCell>{n.status_code ? n.status_code : '-'}</TableCell>
                    <TableCell>{n.title_1}</TableCell>
                    <TableCell>{n.outlinks}</TableCell>
                    <TableCell>{n.inlinks}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )}
      </Paper>
    </ThemeProvider>
  )
}

export default CrawlReportTable
