import { gql, useQuery } from '@apollo/client'
import { MRT_ColumnDef } from 'material-react-table'
import React, { useMemo, useState } from 'react'
import MultiCrawlTable from '@components/common/Tables/MultiCrawlTable'

const style = {
  width: '100%',
  maxWidth: 4000,
  bgcolor: 'background.paper',
}

const GET_DATA = gql`
  query GetData {
    crawlcomparison {
      changeType
      changedCount
      changed {
        address
        p_status_code
        c_status_code
        date
        ref_count
        inboundLinks {
          address
          anchor
        }
      }
    }
  }
`

export default function ListDividers() {
  const { loading, error, data } = useQuery(GET_DATA)

  const [selectedItem, setSelectedItem] = useState<{
    changeType: string
  } | null>(null)

  const subDataColumns = useMemo(
    () =>
      [
        {
          accessorKey: 'address',
          header: 'Source Address',
          size: 50,
        },
        {
          accessorKey: 'anchor',
          header: 'Anchor',
        },
      ] as MRT_ColumnDef[],
    [],
  )

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'address',
          header: 'Address',
          size: 50,
        },
        {
          accessorKey: 'p_status_code',
          header: 'Previous Status Code',
        },
        {
          accessorKey: 'c_status_code',
          header: 'Latest Status Code',
        },
        {
          accessorKey: 'date',
          header: 'Crawl Date',
        },
        {
          accessorKey: 'ref_count',
          header: 'Reference',
        },
      ] as MRT_ColumnDef[],
    [],
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  return (
    <React.Fragment>
      <MultiCrawlTable
        data={data?.crawlcomparison}
        columns={columns}
        subDataColumns={subDataColumns}
      />
    </React.Fragment>
  )
}
