import { gql, useQuery } from '@apollo/client'
import ErrorMessage from '@components/ui/ErrorMessage'
import { getUrlPath } from '@lib/utils/url'
import { OpenInNew } from '@mui/icons-material'
import Cytoscape, { ElementDefinition } from 'cytoscape'
import klay from 'cytoscape-klay'
import React, { useEffect } from 'react'
import CytoscapeComponent from 'react-cytoscapejs'
import { CheiLinks, LinkedUrl } from '../../../../../common/api-types'

Cytoscape.use(klay)

const layoutOptions: Cytoscape.LayoutOptions = {
  name: 'klay',
}

export interface LeakyPagesProfileGraphProps {
  address: string
  data: CheiLinks[]
  cheiScore: number
  outboundCount: number
}

const GET_INBOUND = gql`
  query InboundLinks($address: String!) {
    inboundLinks(address: $address) {
      address
      anchor
      linkPosition
      title
    }
  }
`
let inboundToSelf = 0
let outboundToSelf = 0
const getGraphElements = (
  rootAddress: string,
  outbound: CheiLinks[],
  inboundLinks: LinkedUrl[],
) => {
  const hubNode: ElementDefinition = {
    data: {
      id: 'root_' + rootAddress,
      label: getUrlPath(rootAddress),
      bgColor: '#22aa44',
    },
  }
  inboundToSelf = 0
  outboundToSelf = 0
  const DEFAULT_BG_COLOR = { bgColor: '#999999' }

  const getNodeId = (nodeAddress: string) =>
    `root-${rootAddress}_node-${nodeAddress}`

  const inboundNodes: ElementDefinition[] = inboundLinks.flatMap(
    (linkedURL): ElementDefinition[] => {
      if (linkedURL.address === rootAddress) {
        inboundToSelf++
        return []
      }
      return [
        {
          data: {
            id: `inboundd-${getNodeId(linkedURL.address)}`,
            label: getUrlPath(linkedURL.address),
            ...DEFAULT_BG_COLOR,
          },
        },
        {
          data: {
            source: `inboundd-${getNodeId(linkedURL.address)}`,
            target: hubNode.data.id,
            label: linkedURL.anchor,
            ...DEFAULT_BG_COLOR,
          },
        },
      ]
    },
  )

  const outboundNodes: ElementDefinition[] = outbound.flatMap(
    (linkedURL): ElementDefinition[] => {
      if (linkedURL.address === rootAddress) {
        outboundToSelf++
        return []
      }
      return [
        {
          data: {
            id: `outbound-${getNodeId(linkedURL.address)}`,
            label: getUrlPath(linkedURL.address),
            ...DEFAULT_BG_COLOR,
          },
        },
        {
          data: {
            source: hubNode.data.id,
            target: `outbound-${getNodeId(linkedURL.address)}`,
            label: linkedURL.anchor,
            ...DEFAULT_BG_COLOR,
          },
        },
      ]
    },
  )
  return [hubNode, ...inboundNodes, ...outboundNodes]
}

const LinkProfileGraph = (props: LeakyPagesProfileGraphProps) => {
  const { loading, error, data } = useQuery(GET_INBOUND, {
    variables: {
      address: props.address,
    },
  })
  const [cytoKey, setCytoKey] = React.useState(0)
  useEffect(() => {
    setCytoKey(cytoKey + 1)
  }, [props.address])
  if (error) return <ErrorMessage error={error} />
  if (loading) return <p>Loading</p>
  const { inboundLinks } = data
  const { address, data: outbound, cheiScore, outboundCount } = props

  const elements = getGraphElements(address, outbound, inboundLinks)

  return (
    <div className="flex flex-col h-full">
      <div className="py-3 px-4 flex-none bg-gray-300 text-right">
        <div className="m-0">
          <a
            href={address}
            target="_blank"
            rel="noreferrer"
            title="Open URL in new window"
          >
            <OpenInNew className="pr-2" />
            <span className="font-bold">{address}</span>
          </a>
        </div>
        <div>{`${inboundLinks.length - inboundToSelf} inlinks, ${outboundCount - outboundToSelf} outlinks`}</div>
        <div>{`score: ${cheiScore.toFixed(2)}`}</div>
      </div>
      <div className="flex-grow">
        <CytoscapeComponent
          key={cytoKey}
          elements={elements}
          style={{
            minWidth: '200px',
            minHeight: '200px',
            width: '100%',
            height: '100%',
            padding: '10px',
            border: '1px solid #e3e3e3',
          }}
          stylesheet={[
            {
              selector: 'node',
              style: {
                width: 20,
                height: 20,
                label: 'data(label)',
                'font-size': '5rem',
                'background-color': 'data(bgColor)',
              },
            },
            {
              selector: 'edge',
              style: {
                width: 1,
                label: 'data(label)',
                color: '#aaa',
                'font-size': '3rem',
                'font-style': 'italic',
                'target-arrow-shape': 'triangle-backcurve',
                'target-arrow-color': '#666',
                'curve-style': 'bezier',
              },
            },
          ]}
          pan={{ x: 100, y: 200 }}
          layout={layoutOptions}
        />
      </div>
    </div>
  )
}

export default LinkProfileGraph
