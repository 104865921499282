import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import React from 'react'
import { colours } from './Colour'

interface FilterComponentProps {
  allCount: number
  greenCount: number
  yellowCount: number
  redCount: number
  filterValue: string
  setFilterValue: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const FilterComponent = (props: FilterComponentProps) => {
  const colorOptions = [
    { value: 'all', label: 'All', count: props.allCount },
    { value: 'green', color: colours.dundee_green, count: props.greenCount },
    { value: 'yellow', color: colours.yellow, count: props.yellowCount },
    { value: 'red', color: colours.red, count: props.redCount },
  ]

  return (
    <FormControl>
      <FormLabel id="filter-group">Filter</FormLabel>
      <RadioGroup
        aria-labelledby="filter-group"
        value={props.filterValue}
        onChange={(event) => {
          props.setFilterValue(event)
        }}
        name="filter-group"
      >
        {colorOptions.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {option.color && (
                  <div
                    style={{
                      width: '60px',
                      height: '15px',
                      backgroundColor: option.color,
                      marginRight: '8px',
                    }}
                  />
                )}
                {option.label && `${option.label} (${option.count})`}
                {!option.label && `(${option.count})`}
              </div>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default FilterComponent
