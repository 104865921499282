import { withAuthenticationRequired } from '@auth0/auth0-react'
import React from 'react'

const PageLoader = () => {
  const loadingImg = 'https://cdn.auth0.com/blog/hello-auth0/loader.svg'

  return (
    <div className="loader">
      <img src={loadingImg} alt="Loading..." />
    </div>
  )
}

export const AuthenticationGuard = ({ component }: any) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    ),
  })

  return <Component />
}
