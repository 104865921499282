import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material'
import React from 'react'
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  ConversationHeader,
} from '@chatscope/chat-ui-kit-react'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.css'
import { useAuth0 } from '@auth0/auth0-react'
import GraphbotVisualizationModal from '@components/chatbot-widget/GraphbotVisualizationModal'
import { decodeHtml } from '@lib/utils/html'
import { fetchChatbotAnswer } from '@lib/chatbot/chatbot'
import GraphbotNotes, {
  ResponseRate,
} from '@components/chatbot-widget/GraphbotNotes'
import { gql, useMutation, useQuery } from '@apollo/client'
import { GraphbotMessage } from '../../../../common/api-types'
import { mkConfig, generateCsv, download } from 'export-to-csv'
import { ulid } from 'ulid'
import ErrorMessage from '@components/ui/ErrorMessage'

type MessageDirection = 'incoming' | 'outgoing'
type MessageText = string | undefined | null

const ADD_GRAPHBOT_MESSAGE = gql`
  mutation AddGraphbotMessage(
    $messageId: String!
    $createdAt: String!
    $direction: String!
    $ratingAndNote: RatingAndNoteInput
    $message: MessageInput
  ) {
    addGraphbotMessage(
      messageId: $messageId
      createdAt: $createdAt
      direction: $direction
      ratingAndNote: $ratingAndNote
      message: $message
    ) {
      error
      success
    }
  }
`

const LIST_GRAPHBOT_MESSAGES = gql`
  query ListGraphbotMessages {
    listGraphbotMessages {
      messageId
      userEmail
      tenantId
      createdAt
      direction
      ratingAndNote {
        rating
        note
      }
      message {
        text
        additionalText
        paths
        cypherQuery
      }
    }
  }
`

const UPDATE_GRAPHBOT_MESSAGE = gql`
  mutation AddRatingAndNote(
    $messageId: String!
    $ratingAndNote: RatingAndNoteInput
  ) {
    addRatingAndNote(messageId: $messageId, ratingAndNote: $ratingAndNote) {
      error
      success
    }
  }
`

function GraphBot() {
  const [addGraphbotMessage] = useMutation(ADD_GRAPHBOT_MESSAGE)
  const [updateGraphbotMessage] = useMutation(UPDATE_GRAPHBOT_MESSAGE)
  const { data, refetch, loading } = useQuery(LIST_GRAPHBOT_MESSAGES)

  const { getIdTokenClaims, user, isLoading, error: authError } = useAuth0()

  const tenantId = user ? user['https://contentrecommender.com/tenant'] : ''
  const userEmail = user ? user['https://contentrecommender.com/email'] : ''

  const [loadOlderMessages, setLoadOlderMessages] =
    React.useState<boolean>(false)
  const [userMessage, setUserMessage] = React.useState<string>('')
  const [isFetchingAnswer, setIsFetchingAnswer] = React.useState<boolean>(false)
  const [messages, setMessages] = React.useState<GraphbotMessage[]>([])

  React.useEffect(() => {
    if (!loading && loadOlderMessages) {
      setMessages((prevMessages) => [...data.listGraphbotMessages])
    } else if (!loadOlderMessages) {
      setMessages((prevMessages) => [...prevMessages])
    }
  }, [data, loading, loadOlderMessages])

  // function for exporting messages to csv
  const exportMessages = async () => {
    await refetch()

    const config = mkConfig({
      useKeysAsHeaders: true,
      // a filename in format of 'graphbot-messages-<tenantId>-<timestamp>.csv'
      filename: `graphbot-messages-${tenantId}-${new Date().getTime()}.csv`,
    })

    const remappedMessages = data.listGraphbotMessages
      // filter incoming messages only
      .filter((message: GraphbotMessage) => {
        return message.direction === 'incoming'
      })
      .sort((a: any, b: any) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      })
      .map((message: GraphbotMessage) => {
        return {
          messageId: message.messageId,
          botResponse: message.message?.text,
          userQuery: message.message?.additionalText,
          cypherQuery: message.message?.cypherQuery || 'n/a',
          contextData: message.message?.paths,
          createdAt: message.createdAt,
          rating: message.ratingAndNote?.rating,
          note: message.ratingAndNote?.note,
          userEmail: message.userEmail,
          tenantId: message.tenantId,
        }
      })

    const csv = generateCsv(config)(remappedMessages)
    download(config)(csv)
  }

  // function for adding message
  const addMessage = (
    direction: MessageDirection,
    text: MessageText,
    additionalText: MessageText = '',
    paths: any[] = [],
    cypherQuery: string = '',
  ) => {
    const messageId = `${tenantId}-${ulid(new Date().getTime())}`
    const createdAt = new Date().toISOString()
    if (!loadOlderMessages) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          messageId,
          userEmail,
          tenantId,
          createdAt,
          direction,
          message: {
            text: text || '',
            additionalText,
            paths: JSON.stringify(paths),
            cypherQuery,
          },
        },
      ])
    }

    addGraphbotMessage({
      variables: {
        messageId,
        createdAt,
        direction,
        message: {
          text: text || '',
          additionalText,
          paths: JSON.stringify(paths),
          cypherQuery,
        },
      },
    }).then(async () => {
      refetch()
    })
  }

  // function for handling sending message
  const handleSendMessage = async (message: string) => {
    try {
      setIsFetchingAnswer(true)
      const idToken: any = await getIdTokenClaims()
      const cleanedMessage = decodeHtml(message)
      addMessage('outgoing', cleanedMessage)
      const response = await fetchChatbotAnswer(
        cleanedMessage,
        tenantId,
        idToken.__raw,
      )
      setIsFetchingAnswer(false)
      if (response) {
        addMessage(
          'incoming',
          response.answer,
          message,
          response?.paths || [],
          response?.cypherQuery || '',
        )
      } else {
        addMessage(
          'incoming',
          "I am sorry, I don't understand that question.",
          message,
        )
      }
    } catch (error) {
      console.error(error)
      setIsFetchingAnswer(false)
      addMessage(
        'incoming',
        'Oops! Something went wrong. Please try again.',
        message,
      )
    }
  }

  if (authError) return <ErrorMessage error={authError} />

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Box
        sx={{
          flex: 1,
          width: '100%',
        }}
      >
        <MainContainer>
          <ChatContainer>
            <ConversationHeader>
              <ConversationHeader.Content
                userName="GraphBot"
                info="Ask me about your website."
              />
              <ConversationHeader.Actions>
                <Button onClick={() => setLoadOlderMessages(true)}>
                  LOAD OLD CHATS
                </Button>
                <Button onClick={() => exportMessages()}>EXPORT CHATS</Button>
              </ConversationHeader.Actions>
            </ConversationHeader>
            <MessageList
              style={{
                height: '70vh',
                overflow: 'hidden',
              }}
              scrollBehavior="auto"
              typingIndicator={
                isFetchingAnswer && (
                  <TypingIndicator content="GraphBot is typing..." />
                )
              }
            >
              {messages
                // sort messages by createdAt
                .sort((a: any, b: any) => {
                  return (
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                  )
                })
                .map((message: GraphbotMessage, index: number) => (
                  <Message
                    key={index}
                    model={{
                      direction: message.direction as MessageDirection,
                      type: 'custom',
                      position: 'single',
                    }}
                  >
                    {message.message?.additionalText && (
                      <Message.Header>
                        <Box
                          sx={{
                            border: '1px solid #e0e0e0',
                            borderRadius: 1,
                            p: 1,
                          }}
                        >
                          <Typography variant="body2">
                            {message.message?.additionalText}
                          </Typography>
                        </Box>
                      </Message.Header>
                    )}
                    <Message.CustomContent>
                      <Card
                        sx={{
                          margin: 0,
                          backgroundColor: 'inherit',
                          boxShadow: 'none',
                          width: 'inherit',
                        }}
                      >
                        {message.direction === 'incoming' && index !== 0 && (
                          <CardHeader
                            action={
                              <GraphbotNotes
                                messageId={message.messageId}
                                noteText={message.ratingAndNote?.note || ''}
                                rating={
                                  (message.ratingAndNote?.rating ||
                                    'none') as ResponseRate
                                }
                                onNoteSave={(note, rating) => {
                                  updateGraphbotMessage({
                                    variables: {
                                      messageId: message.messageId,
                                      ratingAndNote: {
                                        rating:
                                          rating ||
                                          message.ratingAndNote?.rating ||
                                          'none',
                                        note,
                                      },
                                    },
                                  }).then(async () => {
                                    refetch()
                                  })
                                }}
                              />
                            }
                          />
                        )}
                        <CardContent sx={{ padding: 0 }}>
                          <Typography variant="body1">
                            {message.message?.text}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          {message.message?.paths &&
                            JSON.parse(message.message.paths).length > 0 && (
                              <GraphbotVisualizationModal
                                data={JSON.parse(message.message.paths)}
                              />
                            )}
                        </CardActions>
                      </Card>
                    </Message.CustomContent>
                  </Message>
                ))}
            </MessageList>

            <MessageInput
              attachButton={false}
              placeholder="Ask me a question..."
              value={userMessage}
              onChange={(userInput) => setUserMessage(userInput)}
              onSend={() => {
                if (userMessage) {
                  handleSendMessage(userMessage)
                  setUserMessage('')
                }
              }}
            />
          </ChatContainer>
        </MainContainer>
      </Box>
    </Box>
  )
}

export default GraphBot
