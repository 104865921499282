import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Title from '@components/ui/Title'
import { useQuery, gql } from '@apollo/client'
import ErrorMessage from '@components/ui/ErrorMessage'

const GET_COUNT_QUERY = gql`
  {
    urlCount
  }
`

export default function UrlCount() {
  const { loading, error, data } = useQuery(GET_COUNT_QUERY)
  if (error) return <ErrorMessage error={error} />
  return (
    <div className="p-4">
      <Title>Total URLs</Title>
      <Typography component="p" variant="h4">
        {loading ? 'Loading...' : data.urlCount}
      </Typography>
      <Typography color="textSecondary">URLs found</Typography>
      <div>
        <Link
          to="/urls"
          className="inline-block bg-indigo-200 p-4 my-4 rounded-md"
        >
          View URLs
        </Link>
      </div>
    </div>
  )
}
