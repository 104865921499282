import { gql, useQuery } from '@apollo/client'
import ErrorMessage from '@components/ui/ErrorMessage'
import Loading from '@components/ui/Loading'
import { getUrlPath } from '@lib/utils/url'
import { Box } from '@mui/material'
import { colours } from '@pages/redirected-url-health/Colour'
import { calculateCosineSimilarity } from '@pages/redirected-url-health/Redirects-functions'
import cytoscape, { ElementDefinition } from 'cytoscape'
import cola from 'cytoscape-cola'
import { useEffect, useState } from 'react'
import CytoscapeComponent from 'react-cytoscapejs'
import { GscInfo, GscurlConnections } from '../../../../../common/api-types'
import {
  getMaxRowByField,
  getNodeSize,
} from '../report-functions/GraphFunctions'
import InfoModal from './InfoModal'

type RowData = {
  address: string
  content: string
  status_code: number
  old_total_clicks: number
  new_total_clicks: number
  old_total_impressions: number
  new_total_impressions: number
  clicks_difference: number
  impressions_difference: number
  old_queries: GscInfo[]
  new_queries: GscInfo[]
}

type ToggleValue = {
  redir: boolean
  reco: boolean
}

type Data = {
  old_url: string
  new_url: string
}

interface GSCByPageGraphProps {
  sub_topic: string[]
  urlData: RowData[]
  filterValue: string
  toggleValue: ToggleValue
  old_start_date: string
  old_end_date: string
  new_start_date: string
  new_end_date: string
}

const GET_DATA = gql`
  query GscURLConnections($where: GSCURLs) {
    gscURLConnections(where: $where) {
      destination
      source
      source_status_code
    }
  }
`

cytoscape.use(cola)

const generateGraphElements = (
  urlData: RowData[],
  con: GscurlConnections[],
  addresses: string[],
  filterValue: string,
  toggleValue: ToggleValue,
) => {
  const Color = (difference: number) => {
    if (difference > 0) {
      return colours.dundee_green
    } else if (difference < 0) {
      return colours.red
    } else {
      return colours.grey
    }
  }
  const Outline = (status_code: number) => {
    if (status_code >= 200 && status_code <= 299) {
      return { borderWith: '0px', borderColor: colours.green }
    }
    if (status_code >= 300 && status_code <= 399) {
      return {
        borderWidth: '2px',
        borderColor: colours.charcoal_saturated,
      }
    }
    if (status_code >= 400 && status_code <= 499) {
      return {
        borderWidth: '2px',
        borderColor: colours.red,
      }
    }
    return {
      borderWidth: '0px',
      borderColor: colours.grey,
    }
  }

  const isRedirect = (statusCode: number) =>
    statusCode >= 300 && statusCode <= 399

  const redirected = (statusCode: number) => {
    if (isRedirect(statusCode)) {
      return {
        lineStyle: 'dashed',
        lineColor: colours.charcoal_saturated,
        targetArrowColor: colours.charcoal_saturated,
        recommended: true,
      }
    }
    return {}
  }

  const shouldAddEdge = (url: any, url2: any) => {
    if (toggleValue.reco && url.address !== url2.address) {
      const isNegativeClicks = url.clicks_difference < 0
      const isStatusCodeValid = !isRedirect(url.status_code)

      if (isNegativeClicks && isStatusCodeValid) {
        const contentSimilarity = calculateCosineSimilarity(
          url.content || ' ',
          url2.content || ' ',
        )

        if (contentSimilarity > 0.75) {
          edges.push({
            data: {
              id: `recommended|${url.address}${url2.address}`,
              source: url.address,
              target: url2.address,
              lineStyle: 'dashed',
              lineColor: colours.yellow,
              targetArrowColor: colours.yellow,
            },
          })
        }
      }
    }
  }

  const getRedirBorderStyle = (
    url: any,
    sourceCountL: Record<string, number>,
  ) => {
    const stronkeCount = sourceCountL[url.address] || 1

    return {
      borderColor: colours.charcoal_saturated,
      borderWidth: `${stronkeCount}px`,
    }
  }

  const metric =
    filterValue === 'Clicks' ? 'clicks_difference' : 'impressions_difference'

  const maxRow = getMaxRowByField(urlData, metric)

  const elements: ElementDefinition[] = urlData.map((url) => {
    return {
      data: {
        id: url.address,
        label: getUrlPath(url.address),
        additionalData: {
          address: url.address,
          content: url.content,
          status_code: url.status_code,
          old_total_clicks: url.old_total_clicks,
          new_total_clicks: url.new_total_clicks,
          clicks_difference: url.clicks_difference,
          impressions_difference: url.impressions_difference,
        },
        bgColor: Color(
          filterValue === 'Clicks'
            ? url.clicks_difference
            : url.impressions_difference,
        ),

        position: { x: 0, y: 0 },
        ...getNodeSize(
          filterValue === 'Clicks'
            ? url.clicks_difference
            : url.impressions_difference,
          maxRow || 0,
        ),
        ...Outline(url.status_code),
      },
    }
  })

  const edges: ElementDefinition[] = con.flatMap((edge) =>
    addresses.flatMap((address) =>
      edge.destination.flatMap((dest) => {
        if (address === dest && edge.source !== dest) {
          return [
            {
              data: {
                id: isRedirect(edge.source_status_code)
                  ? `redirected|${edge.source}${dest}`
                  : `${edge.source}${dest}`,
                source: edge.source,
                target: dest,
                ...redirected(
                  urlData.find((url) => url.address === edge.source)
                    ?.status_code || 0,
                ),
              },
            },
          ]
        }
        // }
        return []
      }),
    ),
  )

  let sourceCount = edges.reduce((acc, edge) => {
    if (edge.data.id && edge.data.id.startsWith('redirected|')) {
      if (acc[edge.data.target]) {
        acc[edge.data.target]++
      } else {
        acc[edge.data.target] = 1
      }
    }
    return acc
  }, {} as Record<string, number>)

  urlData.map((url) => {
    urlData.map((url2) => {
      if (!toggleValue.redir) {
        if (!isRedirect(url.status_code) || !isRedirect(url2.status_code)) {
          shouldAddEdge(url, url2)
        }
      } else {
        shouldAddEdge(url, url2)
      }
    })
  })

  const filteredEdges = edges.filter((edge) => {
    return !(
      !toggleValue.redir &&
      edge.data.id &&
      edge.data.id.includes('redirected|')
    )
  })

  const filteredElements = elements.flatMap((element) => {
    return edges
      .map((edge) => {
        if (!toggleValue.redir && element.data.id === edge.data.target) {
          const redirData = {
            address: element.data.additionalData.address,
            redirects: [],
          }
          const target = elements.find((el) => el.data.id === edge.data.source)
          if (isRedirect(target?.data.additionalData.status_code || 0)) {
            element.data = {
              ...element.data,
              borderColor: colours.charcoal_saturated,
              borderWidth: '3px',
              redirected: true,
            }
          }
        }

        return element
      })
      .filter((element) => {
        return !(
          !toggleValue.redir &&
          element.data.additionalData.status_code >= 300 &&
          element.data.additionalData.status_code <= 399
        )
      })
  })

  return [...filteredElements, ...filteredEdges]
}

const GSCByPageGraph = ({
  sub_topic,
  urlData,
  filterValue,
  toggleValue,
  old_start_date,
  old_end_date,
  new_start_date,
  new_end_date,
}: GSCByPageGraphProps) => {
  const addresses = urlData.map((url) => {
    return url.address
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState<RowData | null>(null)
  const handleCloseModal = () => {
    setModalData(null)
    setIsModalOpen(false)
  }
  const { loading, data, error, refetch } = useQuery(GET_DATA, {
    variables: {
      where: {
        url_data: addresses,
      },
    },
  })
  const [cytoKey, setCytoKey] = useState(0)
  useEffect(() => {
    setCytoKey(cytoKey + 1)
  }, [urlData])
  const layout = {
    name: 'cola',
    animate: true,
    animationDuration: 10,
    edgeLength: 'auto',
    nodeSpacing: 20,
    padding: 2,
  }

  if (loading) {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Loading />
        </Box>
      </>
    )
  }
  if (error) {
    return <ErrorMessage error={error.message} />
  }

  const elements = generateGraphElements(
    urlData,
    data?.gscURLConnections,
    addresses || [],
    filterValue,
    toggleValue,
  )

  const attachEventListeners = (cy: any) => {
    cy.on('tap', 'node[redirected]', (event: any) => {
      const node = event.target
      const data = urlData.find((url) => url.address === node.id())
      // const source = edge.source().id()
      // const target = edge.target().id()
      setIsModalOpen(true)
      setModalData(data || null)
    })
  }

  return (
    <>
      <CytoscapeComponent
        key={cytoKey}
        elements={elements}
        style={{ width: '100%', height: '100%' }}
        layout={layout}
        stylesheet={[
          {
            selector: 'node[label]',
            style: {
              label: 'data(label)',
              'background-color': 'data(bgColor)',
              'text-overflow-wrap': 'anywhere',
              'text-wrap': 'wrap',
              'text-max-width': '80%',
              'font-size': '5px',
              'text-valign': 'center',
              'text-halign': 'center',
              height: 'data(height)',
              width: 'data(width)',
            },
          },
          {
            selector: 'node[borderColor][borderWidth]',
            style: {
              'border-color': 'data(borderColor)',
              'border-width': 'data(borderWidth)',
              'border-style': 'dashed',
              'line-dash-pattern': [6, 3],
            },
          },
          {
            selector: 'edge',
            style: {
              width: 1,
              color: '#aaa',
              'font-size': '3rem',
              'font-style': 'italic',
              'target-arrow-shape': 'triangle-backcurve',
              'target-arrow-color': '#666',
              'curve-style': 'bezier',
              'border-color': 'black',
            },
          },
          {
            selector: 'edge[lineStyle]',
            style: {
              width: 1,
              color: colours.yellow,
              'font-size': '3rem',
              'font-style': 'italic',
              'target-arrow-shape': 'triangle-backcurve',
              'target-arrow-color': 'data(targetArrowColor)',
              'curve-style': 'bezier',
              'line-style': 'dashed',
              'line-color': 'data(lineColor)',
              'border-width': 1, // set the border width for outline effect
              'border-color': 'black',
            },
          },
        ]}
        cy={(cy) => {
          attachEventListeners(cy)
        }}
      />
      {isModalOpen && modalData && (
        <InfoModal
          sub_topic={sub_topic}
          open={isModalOpen}
          onClose={handleCloseModal}
          modalData={modalData}
          filterValue={filterValue}
          old_end_date={old_end_date}
          old_start_date={old_start_date}
          new_end_date={new_end_date}
          new_start_date={new_start_date}
        />
      )}
    </>
  )
}
export default GSCByPageGraph
