import { getUrlPath } from '@lib/utils/url'
import { Box, Popover, Typography } from '@mui/material'
import Cytoscape from 'cytoscape'
import cola from 'cytoscape-cola'
import { useState } from 'react'
import CytoscapeComponent from 'react-cytoscapejs'
Cytoscape.use(cola)

interface ChatbotGraphProps {
  data: any[]
}

function generateGraphElements(data: any[]) {
  const elements: any[] = []

  data.forEach((item) => {
    const from = item[0] || null
    const edge = item[1] || null
    const to = item[2] || null

    if (from) {
      if (
        elements.length === 0 ||
        !elements.some((e: any) => e?.data?.id === from.node_id + '-node')
      ) {
        const defaultProp = from.properties.find(
          (obj: any) => obj.is_default === true,
        )
        elements.push({
          data: {
            id: from.node_id + '-node',
            label: defaultProp?.value || from.properties[0].value,
            bgColor: '#00ff00',
            nodeData: from,
          },
        })
      }
    }
    if (to) {
      if (
        elements.length === 0 ||
        !elements.some((e: any) => e.data.id === to.node_id + '-node')
      ) {
        const defaultProp = to.properties.find(
          (obj: any) => obj.is_default === true,
        )
        elements.push({
          data: {
            id: to.node_id + '-node',
            label: defaultProp?.value || to.properties[0].value,
            bgColor: '#00ff00',
            nodeData: to,
          },
        })
      }
    }
    if (edge) {
      if (
        elements.length === 0 ||
        !elements.some((e: any) => e.data.id === edge.edge_id + '-edge')
      ) {
        elements.push({
          data: {
            id: edge.edge_id + '-edge',
            source: from.node_id + '-node',
            target: to.node_id + '-node',
            label: edge?.type || '',
          },
        })
      }
    }
  })
  return elements
}

export default function ChatbotGraph(props: ChatbotGraphProps) {
  console.log('Rendering ChatbotGraph')
  const [anchorEl, setAnchorEl] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [popoverContent, setPopoverContent] = useState<any>([])

  const elements = generateGraphElements(props.data)

  const handleNodeClick = (event: any) => {
    // event.handled = false
    if (event.handled) {
      console.log('Event already handled')
      return
    }
    event.handled = true
    const targetNode = event.target
    console.log('targetNode', targetNode)
    if (targetNode.isNode()) {
      console.log('Node click 2', event.target.id())
      const nodeData = event.target.data('nodeData')
      const containerPosition = event.target.renderedPosition()
      const containerOffset = event.target
        .cy()
        .container()
        .getBoundingClientRect()
      const x = containerPosition.x + containerOffset.left
      const y = containerPosition.y + containerOffset.top

      setPosition({ x, y })
      setPopoverContent(nodeData)
      setAnchorEl(true)

      event.stopPropagation()
    }
  }

  const attachEventListeners = (cy: any) => {
    cy.on('tap', 'node', (event: any) => {
      if (event.target.id().includes('node')) {
        handleNodeClick(event)
        console.log('popoverContent', popoverContent)
      }
    })
  }

  const layout = {
    name: 'cola',
    animationDuration: 10,
    edgeLength: 300,
    nodeSpacing: 40,
    padding: 2,
  }

  const handleClosePopover = () => {
    setAnchorEl(false)
  }

  return (
    <>
      <CytoscapeComponent
        elements={elements}
        style={{
          minWidth: '60vw',
          minHeight: '60vh',
          width: '100%',
          height: '100%',
          padding: '10px',
          border: '2px solid #e3e3e3',
        }}
        stylesheet={[
          {
            selector: 'node',
            style: {
              width: 20,
              height: 20,
              label: 'data(label)',
              'font-size': '5rem',
              'background-color': 'data(bgColor)',
            },
          },
          {
            selector: 'edge',
            style: {
              width: 1,
              label: 'data(label)',
              'font-size': '3rem',
              'font-style': 'italic',
              'target-arrow-shape': 'triangle-backcurve',
              'target-arrow-color': '#666',
              'curve-style': 'bezier',
              'text-rotation': 'autorotate',
              'text-margin-y': -2,
              'text-margin-x': 0,
            },
          },
        ]}
        layout={layout}
        userZoomingEnabled={true}
        cy={(cy) => attachEventListeners(cy)}
      />

      <Popover
        open={anchorEl}
        anchorReference="anchorPosition"
        anchorPosition={{ top: position.y, left: position.x }}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          opacity: 0.8,
          zIndex: 9999999,
        }}
      >
        {popoverContent && (
          <Box sx={{ 
            p: 1,
            border: '1px solid #e3e3e3',
          }}>
            <Typography variant="h6" component="div">
              {popoverContent.label}
            </Typography>
            {popoverContent && popoverContent.properties?.map((item: any, index: number) => (
              <Box sx={{ mb: 1 }} key={index}>
                <Box sx={{ fontWeight: 'bold' }}>{item.property_name}</Box>
                <Box sx={{ fontSize: '0.8rem' }}>
                  {item.value}
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Popover>
    </>
  )
}
