export type ChatbotAnswer = {
  answer?: string | null
  paths?: any[] | null
  cypherQuery?: string | null
}

const chatbotUrl = process.env.REACT_APP_CHATBOT_URL

export const fetchChatbotAnswer = async (
  question: string,
  tenantId: string,
  token: string
): Promise<ChatbotAnswer> => {
  if (!chatbotUrl) {
    throw new Error('Chatbot URL not defined')
  }

  const response = await fetch(chatbotUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      question,
      tenantId,
    }),
  })
  const data = await response.json()
  return {
    answer: data?.answer,
    paths: data?.paths,
    cypherQuery: data?.cypher_query || '',
  }
}
