import { getUrlPath } from '@lib/utils/url'
import { colours } from '@pages/redirected-url-health/Colour'
import Cytoscape, { ElementDefinition } from 'cytoscape'
import cola from 'cytoscape-cola'
import CytoscapeComponent from 'react-cytoscapejs'
import { GscInfo } from '../../../../../common/api-types'
import {
  getColor,
  getMaxRowByField,
  getNodeSize,
} from '../report-functions/GraphFunctions'

Cytoscape.use(cola)

interface ChainGraphProps {
  data: any
  modalData: RowData | null
  filterValue: string
  sub_topic: string[]
}

type RowData = {
  address: string
  content: string
  status_code: number
  old_total_clicks: number
  new_total_clicks: number
  old_total_impressions: number
  new_total_impressions: number
  clicks_difference: number
  impressions_difference: number
  old_queries: GscInfo[]
  new_queries: GscInfo[]
}

function generateGraphElements(
  data: RowData[],
  modalData: RowData | null,
  filterValue: string,
) {
  let metrics_total = {
    total_clicks: 0,
    total_impressions: 0,
  }
  const metrics_data: any = []

  data.map((clicks) => {
    metrics_total.total_clicks += clicks.old_total_clicks
    metrics_total.total_impressions += clicks.old_total_impressions
    metrics_data.push({
      clicks_difference: clicks.old_total_clicks,
      impressions_difference: clicks.old_total_impressions,
    })
  })

  metrics_data.push({
    clicks_difference: modalData?.new_total_clicks,
    impressions_difference: modalData?.new_total_impressions,
  })

  const metric =
    filterValue === 'Clicks' ? 'clicks_difference' : 'impressions_difference'

  const maxRow = getMaxRowByField(metrics_data, metric)

  const hubNode: ElementDefinition = {
    data: {
      id: modalData?.address,
      label: getUrlPath(modalData?.address || ''),
      bgColor: getColor(
        filterValue === 'Clicks'
          ? (modalData?.new_total_clicks || 0) - metrics_total.total_clicks
          : (modalData?.new_total_impressions || 0) -
              metrics_total.total_impressions,
      ),
      ...getNodeSize(
        filterValue === 'Clicks'
          ? modalData?.new_total_clicks || 0 - metrics_total.total_clicks
          : modalData?.new_total_impressions ||
              0 - metrics_total.total_impressions,
        maxRow || 0,
      ),
    },
  }
  const elements: ElementDefinition[] = data.map((urlData) => {
    return {
      data: {
        id: urlData.address,
        label: getUrlPath(urlData.address),
        bgColor: getColor(
          filterValue === 'Clicks'
            ? urlData.old_total_clicks
            : urlData.old_total_impressions,
        ),
        ...getNodeSize(
          filterValue === 'Clicks'
            ? urlData.old_total_clicks
            : urlData.old_total_impressions,
          maxRow || 0,
        ),
      },
    }
  })

  const edge: ElementDefinition[] = data.map((urlData) => {
    return {
      data: {
        id: `${urlData.address}-edge`,
        source: urlData.address,
        target: hubNode.data.id,
        label: `(${urlData.status_code.toString()})`,
      },
    }
  })
  return [...edge, hubNode, ...elements]
}
export default function ModalGraph({
  data,
  modalData,
  filterValue,
  sub_topic,
}: ChainGraphProps) {
  const elements = generateGraphElements(data, modalData, filterValue)
  const layout = {
    name: 'cola',
    animate: true,
    animationDuration: 10,
    edgeLength: 'auto',
    nodeSpacing: 20,
    padding: 2,
  }
  return (
    <CytoscapeComponent
      elements={elements}
      style={{ width: '100%', height: '100%' }}
      layout={layout}
      stylesheet={[
        {
          selector: 'node',
          style: {
            label: 'data(label)',
            'background-color': 'data(bgColor)',
            'text-overflow-wrap': 'anywhere',
            'text-wrap': 'wrap',
            'text-max-width': '80%',
            'font-size': '5px',
            'text-valign': 'center',
            'text-halign': 'center',
            height: 'data(height)',
            width: 'data(width)',
          },
        },
        {
          selector: 'edge',
          style: {
            width: 1,
            color: '#aaa',
            'font-size': '3rem',
            'font-style': 'italic',
            'target-arrow-shape': 'triangle-backcurve',
            'target-arrow-color': '#666',
            'curve-style': 'bezier',
            'border-color': 'black',
          },
        },
      ]}
      // cy={(cy) => {
      //   attachEventListeners(cy)
      // }}
    />
  )
}
