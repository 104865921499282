import { ThemeProvider } from '@emotion/react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  createTheme,
} from '@mui/material'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const theme = createTheme()

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  ></Box>
)

export default function LinkAnalysisPage() {
  const root = '/link-analysis'
  const [hrefs, setHrefs] = useState([
    {
      href: `${root}/redirects-analysis`,
      label: 'Redirects Analysis',
    },
    {
      href: `${root}/similarly-linked-pages`,
      label: 'Similarly Linked Pages',
    },
    {
      href: `${root}/leaky-pages`,
      label: 'Leaky Pages',
    },
    {
      href: `${root}/influential-pages`,
      label: 'Influential Pages',
    },
  ])
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 1,
            width: 128,
            height: 128,
          },
        }}
      >
        {hrefs.map((href, index) => (
          <Card sx={{ minWidth: 275 }} key={index}>
            <CardContent>
              <Typography variant="h5" component="div">
                {href.label}
              </Typography>
            </CardContent>
            <CardActions>
              <Link to={href.href}>
                <Button size="small">View Report</Button>
              </Link>
            </CardActions>
          </Card>
        ))}
      </Box>
    </ThemeProvider>
  )
}
