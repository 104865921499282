import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  TableSortLabel,
  TextField,
  Button,
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { Typography, Box } from '@mui/material'

import Title from '@components/ui/Title'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

type SortDirection = 'asc' | 'desc'

const theme = createTheme()

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1400,
    marginTop: 10,
    overflowX: 'auto',
    margin: 'auto',
    padding: 10,
  },
  table: {
    minWidth: 900,
  },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    minWidth: 300,
  },
}))

const GET_DATA = gql`
  query GetData {
    gscapicomparison {
      address
      prev_clicks
      latest_clicks
      gcsapi_date1
      gcsapi_date2
      clicks_difference
      what_happened
      prev_range
      latest_range
    }
  }
`

const GscapiCompare: React.FC = () => {
  const [order, setOrder] = useState<SortDirection>('desc')
  const [orderBy, setOrderBy] = useState('brokenURLsCount')
  const [page, setPage] = useState(0) // Updated state variable for page
  const [rowsPerPage] = useState(20) // Updated state variable for rows per page
  const [filterState, setFilterState] = useState({ urlFilter: '' })
  const [expandedRow, setExpandedRow] = useState<number | null>(null)
  const classes = useStyles()

  const getFilter = () => {
    return filterState.urlFilter.length > 0
      ? { address_CONTAINS: filterState.urlFilter.toLowerCase() }
      : {}
  }

  const { loading, data, error } = useQuery(GET_DATA, {
    variables: {
      options: {
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        ...(orderBy && {
          sort: { [orderBy]: order ? order.toUpperCase() : 'ASC' },
        }),
      },
      where: getFilter(),
    },
  })

  if (loading) {
    // Loading state...
  } else if (error) {
    console.log(error) // Log the error to the console for inspection
    // Error state...
  } else if (data) {
    // Data rendering...
  }

  const handleSortRequest = (property: any) => {
    const isAsc = orderBy === property && order === 'asc'
    const newOrder = isAsc ? 'desc' : 'asc'

    setOrder(newOrder)
    setOrderBy(property)
  }

  const handleFilterChange = (filterName: string) => (event: any) => {
    const val = event.target.value
    setFilterState((oldFilterState) => ({
      ...oldFilterState,
      [filterName]: val,
    }))
  }

  const handleRowClick = (index: number) => {
    if (expandedRow === index) {
      setExpandedRow(null)
    } else {
      setExpandedRow(index)
    }
  }

  const pageCount = Math.ceil(data?.gscapicomparison.length / rowsPerPage)

  return (
    <ThemeProvider theme={theme}>
      <Paper className={classes.root}>
        <Title>Clicks difference of the last two crawls.</Title>
        <TextField
          id="search"
          label="URL Contains"
          className={classes.textField}
          value={filterState.urlFilter}
          onChange={handleFilterChange('urlFilter')}
          margin="normal"
          variant="outlined"
          type="text"
        />
        {loading && !error && <p>Loading...</p>}
        {error && !loading && <p>Error</p>}
        {data && !loading && !error && (
          <React.Fragment>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell
                    key="address"
                    sortDirection={orderBy === 'address' ? order : false}
                  >
                    <Tooltip
                      title="Sort"
                      placement="bottom-start"
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={orderBy === 'address'}
                        direction={order}
                        onClick={() => handleSortRequest('address')}
                      >
                        Address
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>

                  <TableCell
                    key="prev_clicks"
                    sortDirection={orderBy === 'prev_clicks' ? order : false}
                  >
                    <Tooltip
                      title="Sort"
                      placement="bottom-start"
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={orderBy === 'prev_clicks'}
                        direction={order}
                        onClick={() => handleSortRequest('prev_clicks')}
                      >
                        Prev Clicks({data.gscapicomparison[0].prev_range})
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    key="latest_clicks"
                    sortDirection={orderBy === 'latest_clicks' ? order : false}
                  >
                    <Tooltip
                      title="Sort"
                      placement="bottom-start"
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={orderBy === 'latest_clicks'}
                        direction={order}
                        onClick={() => handleSortRequest('latest_clicks')}
                      >
                        Latest Clicks({data.gscapicomparison[0].latest_range})
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    key="clicks_difference"
                    sortDirection={
                      orderBy === 'clicks_difference' ? order : false
                    }
                  >
                    <Tooltip
                      title="Sort"
                      placement="bottom-start"
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={orderBy === 'clicks_difference'}
                        direction={order}
                        onClick={() => handleSortRequest('clicks_difference')}
                      >
                        Clicks Difference
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    key="what_happened"
                    sortDirection={orderBy === 'what_happened' ? order : false}
                  >
                    <Tooltip
                      title="Sort"
                      placement="bottom-start"
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={orderBy === 'what_happened'}
                        direction={order}
                        onClick={() => handleSortRequest('what_happened')}
                      >
                        Note
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.gscapicomparison
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((n: any, index: number) => (
                    <React.Fragment key={n.hash || n.address}>
                      <TableRow onClick={() => handleRowClick(index)}>
                        <TableCell component="th" scope="row">
                          {n.address}
                          <a
                            href={n.address}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              marginLeft: '0.5em',
                              marginRight: '0.5em',
                            }}
                          >
                            <OpenInNewIcon fontSize="small" />
                          </a>
                        </TableCell>
                        <TableCell>{n.prev_clicks}</TableCell>
                        <TableCell>{n.latest_clicks}</TableCell>
                        <TableCell>{n.clicks_difference}</TableCell>
                        <TableCell>{n.what_happened}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={2}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => setPage(page - 1)}
                disabled={page === 0}
                sx={{ marginRight: '10px' }}
              >
                Previous Page
              </Button>
              <Typography variant="body1">
                Page {page + 1} of {pageCount}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setPage(page + 1)}
                disabled={page === pageCount - 1}
                sx={{ marginLeft: '10px' }}
              >
                Next Page
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Paper>
    </ThemeProvider>
  )
}

export default GscapiCompare
